import React, {useState} from 'react';
import './UserInfo.scss';
import Q_InputFeedback from '../../../components/Q_Elements/Q_InputFeedback/Q_InputFeedback';
import TableUserInfo from './TableUserInfo';
import RelationshipUserInfo from './RelationshipUserInfo';
import {getUserByEmailQuery} from "../../../graphql/queries";
import {useLazyQuery} from "react-apollo";
import gql from "graphql-tag";

function UserInfo() {
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [emailVal, setEmailVal] = useState('');

  const [fetchUser, {loading, error, data = {}}] = useLazyQuery(gql(getUserByEmailQuery));

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password === 'puchibubu') {
      setIsAuthenticated(true);
    } else {
      alert('Incorrect password!');
    }
  };

  const onSubmit = () => {
    fetchUser({variables: {email: emailVal.trim()}});
  }

  return (
    <>
      {isAuthenticated ? (
        <div className="userinfo-container">
          <div className="userinfo-top">
            <div className="userinfo-container__email">
              <div className="userinfo-container__field">
                <Q_InputFeedback label={'Email'} onInputChange={setEmailVal} initialValue={emailVal}
                                 isTextArea={false} onEnterKey={onSubmit}/>
              </div>
              <div className="userinfo_button noselect"
                   onClick={onSubmit}>
                ↳
              </div>
            </div>
            <div className="userinfo-container__tableUserInfo">
              <TableUserInfo data={data}/>
            </div>
          </div>
          <div className="userinfo-container__RelationshipUserInfo">
            <RelationshipUserInfo relationshipsData={data.userByEmail?.relationships}/>
          </div>
        </div>
      ) : (
        <form onSubmit={handlePasswordSubmit}>
          <label>
            Please enter the password:
            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
          </label>
          <button type="submit">Submit</button>
        </form>
      )}
    </>
  );
}

export default UserInfo;
