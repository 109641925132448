import React , {useState} from 'react';
import { useTranslation } from 'react-i18next';
import setPropertyVh from '../../utils/setPropertyVh';
import StepRequirement from 'components/StepRequirement/StepRequirement';
import printFunc from '../../utils/printFunc';
import CourseTheatreReicpe from "../../flows/CourseTheatreRecipe";

const Onboarding_Print = ({
    // onExit,
    activityPagePDF,
    activityPageThumb,
    onBack,
    onContinue,            
}) => {
    const {t} = useTranslation();
    setPropertyVh();

    const handleOnPrint = () => {
        printFunc(activityPagePDF)
    }

    return (
        <>
            <div className={"Onboarding-container"}>
                <div className={"Onboarding-content hideScrollbar"}>
                        <div className={"Onboarding-subtitle"}>{t("onboarding.whatNeedForLesson")}</div>
                        <div className={"Onboarding-title"}>{t("onboarding.Onboarding_Print.title")}</div>
                        <div className={"Onboarding-description"}>{t("onboarding.Onboarding_Print.subtitle")}</div>
                        <div className="content-ab content-column ">
                            <button onClick={handleOnPrint} className={"buttonCatbears buttonCatbears_CTA req-container_miniButton"}>
                                <img src="/assets/icons/icon-print.svg" />
                                {t("general.printActivityPages")}
                            </button> 
                            <img src={activityPageThumb} alt="activity page" />
                        </div>
                        <div className='Room'></div>
                </div>
                <div className={"Onboarding-footer"}>
                    <button onClick={onContinue} className={"button Onboarding-button Onboarding-button-primary catbearsCTA-secondary"}>
                    {t("onboarding.Onboarding_Print.cta2")}
                    </button>
                    <button onClick={onContinue} className={"button Onboarding-button Onboarding-button-secondary  catbearsCTA-primary Onboarding-button"}>
                        {t("onboarding.Onboarding_Print.cta")}
                    </button>          
                </div>
            </div>
        </>
        )

}

export default Onboarding_Print