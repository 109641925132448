

import React , {useState} from 'react';
import { useTranslation } from 'react-i18next';
import setPropertyVh from '../../utils/setPropertyVh';
import StepRequirement from 'components/StepRequirement/StepRequirement';


const Onboarding_Materials = ({
    // onExit,
    stepRequirements,
    onContinue,            
}) => {
    const {t} = useTranslation();
    setPropertyVh();

    console.log("stepRequirements", stepRequirements)
    return (
        <>
        <div className={"Onboarding-container"}>
            <div className={"Onboarding-content hideScrollbar"}>
                    <div className={"Onboarding-subtitle"}>{t("onboarding.whatNeedForLesson")}</div>
                    <div className={"Onboarding-title"}>{t("onboarding.Onboarding_Materials.title")}</div>
                    <div className={"Onboarding-description"}>{t("onboarding.Onboarding_Materials.subtitle")}</div>
                    <div className="content-ab max-width-900">
                        
                    {stepRequirements.map((Requirement, index) => {
                        if (Requirement.name !== "activityPage") {
                            return (
                            <div className="req-container" key={index}>
                                <StepRequirement
                                key={index}
                                type={Requirement.name}
                                sizeM
                                showDescription
                                whiteText
                                // hideBackground
                                />
                            </div>
                            );
                        }
                        return null;
                        })}

                            {/* <StepRequirement
                                 key={1}
                                 type={"craftTable"}
                                 whiteText
                                 
                                 showDescription
                             /> 
                            <StepRequirement
                                 key={2}
                                 type={"iPad"}
                                 whiteText
                                 
                                 showDescription
                             />  */}
                        {/* <div className="content-a"> */}
                            {/* <img className={"onboarding-img"} src={"/assets/onboarding/catbears at home.jpg"} alt="The Catbears - at home" /> */}
                        {/* </div> */}
                        {/* <div className="content-b">
                            <ul>
                                <li>גלגו את השטיח</li>
                                <li>הכינו שולחן יצירה וכיסא לכל משתתף</li>
                                <li>מגבות נייר בהישג יד</li>
                                <li>בגדים שאפשר ללכלך</li>
                            </ul>
                            <p>המשתתפים צריכים לשבת ליד מסך שיראה להם איך ליצור. מקמו מחשב נייד / שדרו לטלויזיה חכמה / השתמו בטאבלט. ואם אין מסך יותר גדול השתמשו בפלאפון הנייד שלכם.</p>
                        </div> */}
                    </div>
                    <div className={"Onboarding--card"}>
                        <img className={"Onboarding--card__img "} src={"/assets/onboarding/materials_1st-lesson.png"} alt={"table and device"}/>
                    </div>
                    <div className='Room'></div>
            </div>
            <div className={"Onboarding-footer"}>
                <button onClick={onContinue} className={"button Onboarding-button Onboarding-button-primary catbearsCTA-secondary"}>
                {t("onboarding.Onboarding_Materials.cta2")}
                </button>
                <button onClick={onContinue} className={"button Onboarding-button Onboarding-button-secondary  catbearsCTA-primary Onboarding-button"}>
                    {t("onboarding.Onboarding_Materials.cta")}
                </button>          
            </div>
            </div>
        </>
        )

}

export default Onboarding_Materials