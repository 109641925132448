import React, {useState, useEffect} from 'react';
import FeelCard from '../FeelCard/FeelCard';
import HappenedCard from '../HappenedCard/HappenedCard';
import NeedCard from '../NeedCard/NeedCard';
import "./Card.scss"
import {CardType} from "../../graphql/types";
import UserTypeCard from "../UserTypeCard/UserTypeCard";

function Card(props) {

  const {cardType, classes} = props;
  let cardComponent;
  switch (cardType) {
    case CardType.Feel:
      cardComponent = <FeelCard {...props} />;
      break;
    case CardType.Happened:
      cardComponent = <HappenedCard {...props} />;
      break;
    case CardType.Need:
      cardComponent = <NeedCard {...props} />;
      break;
    case CardType.UserType:
      cardComponent = <UserTypeCard {...props} />
      break;
    default:
      throw new Error(`Unsupported cardType: ${cardType}`);
  }

  return (
    <div className={`noselect card-container ${cardType.toLowerCase()}-card `} onClick={props.onClick}>
      {cardComponent}
    </div>
  );
}

export default Card;
