import React from 'react';
import { useTranslation } from 'react-i18next';
import "./GenderPicker.scss";
import { RadioGroup, RadioButton } from 'react-radio-buttons';

function GenderPicker({ onChange, value }) {
  const { t } = useTranslation();
  return (
    <RadioGroup className="gender-picker radio-3-horizontal noselect" onChange={onChange} value={value} horizontal>
      <RadioButton value={`male`}>{t(`genderpicker.male`)}</RadioButton>
      <RadioButton value={`female`}>{t(`genderpicker.female`)}</RadioButton>
      <RadioButton value={`other`}>{t(`genderpicker.other`)}</RadioButton>
    </RadioGroup>
  )
}
export default GenderPicker;