import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button/Button';
import AnimalsCarousel from '../../components/AnimalsCarousel/AnimalsCarousel';
import GenderPicker from '../../components/GenderPicker/GenderPicker';
import TopNavbar from '../../components/TopNavbar/TopNavbar';


function Step1({
  changeAnimal,
  changeSex,
  changeStep,
  changeName,
  name,
  gender,
  animalId,
  user
}) {
  const { t } = useTranslation();
  return (
    <>
      
      <div className="step-container texture">
      <TopNavbar 
        user={user}
        BackOnClick={()=> window.history.back()}
        >
      </TopNavbar>
        <div className="title noselect">
          <h1>{t(`create_new_relationship.step1.title`)}</h1>
          <h2>{t(`create_new_relationship.step1.subtitle`)}</h2>
        </div>
        <div className="animals-carousel-container animals-carousel-container__step1 noselect">
          <AnimalsCarousel
            onChange={animalId => changeAnimal(animalId)}
            value={animalId}
          />
        </div>
        <div className="details">
          <div className="text-field">
            {/* <label htmlFor={`name`}>{t(`create_new_relationship.step1.input_name`)}</label> */}
            <input
              autoComplete={'off'}
              required
              placeholder={t(
                `create_new_relationship.step1.input_name_placeholder`
              )}
              name={`name`}
              type="text"
              value={name}
              onChange={event => changeName(event.target.value)}
            />
          </div>
          <GenderPicker onChange={gender => changeSex(gender)} value={gender} />
          <Button
            color={`#489de5`}
            backgroundColor={`white`}
            background
            text={t(`create_new_relationship.step1.next_button`)}
            onClick={e => changeStep(`STEP2`, e)}
          />
        </div>
      </div>
    </>
  );
}

export default Step1;
