import { useMachine } from '@xstate/react';
import { Mirror as Journey } from 'graphql/types';
import { QuestEvent } from 'machines/QuestMachineNew';
import QuestMachine from 'machines/QuestMachine';
import { useCallback, useMemo } from 'react';
import getStepsStatus from 'utils/getStepsStatus';
import { QuestFlowType } from './types';

function useJourneyStepMachine(journey: Journey, questId: number) {
  // TODO: add useMemo here
  const stepsForQuestMachine = useMemo(() => getStepsStatus(journey, questId), [journey, questId]);
  // @ts-ignore
  const [state, send] = useMachine(QuestMachine, {
    context: {
      selectedStepNumberContext: 0,
      CTAtextContext: 'Start',
      isBackContext: false,
      isCTAContext: false,
      isExitContext: false,
      stepsContext: stepsForQuestMachine,
      journey,
      questId
    }
  });

  const sendToQuestMachine = useCallback(
    (event: QuestEvent) => {
      return send(event);
    },
    [send]
  );

  return [state, sendToQuestMachine];
}

export default useJourneyStepMachine;
