import React ,{useState, useEffect} from 'react';
import './Meter.scss';
import MeterDot from './MeterDot';

function Meter({
    meterItems,
    classes,
    meterType
}) {

    const [meterItemsState, setMeterItemsState] = useState(meterItems);
    const [selectedItemNumberState, setSelectedItemNumberState] = useState()

    const updatedSelectedDot = (indx) => {
        setSelectedItemNumberState(indx)
        let holder = meterItemsState;
        holder[indx].isSelected = true
        setMeterItemsState(holder)
        // TODO: Send to state machine
        // TODO: Save this in DB
    }
    const checkIfSelected = () => {
        let holder = 0
        meterItemsState.forEach((dot, indx) => {
            console.log("-- dot.isSelected",  dot.isSelected)
            if(dot.isSelected){
                console.log("-- dot" , indx, " isSelected " ,dot.isSelected)
                holder++}
        })
        console.log("-- holder",  holder)
        return holder > 0
    }

    useEffect(() => {
        console.log("checkIfSelected" , checkIfSelected())
        console.log("selectedItemNumberState", selectedItemNumberState)
    }, [meterItemsState,setMeterItemsState, selectedItemNumberState]);
    
  return (
    <div className={'Meter noselect ' + (classes ? classes : "")}>
        {meterItemsState.map((dot, indx) => {
          return (
            <MeterDot
                indx={indx}
                title={dot.title}
                isSelected={selectedItemNumberState == indx}
                isFaded={checkIfSelected() && selectedItemNumberState != indx}
                emoji={dot.emoji}
                value={dot.value}
                setSelectedItemNumberState={setSelectedItemNumberState}
                updatedSelectedDot={updatedSelectedDot}
            />
          );
        })}
    </div>
  );
}

export default Meter;

