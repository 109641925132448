import React, {useRef, useState, useEffect} from 'react';
import './CircleProgress.scss';
import {Link} from "react-router-dom";
import EmojiToImage from "../../utils/EmojiToImage"

function CircleProgress({
                          color,
                          circleProgressScale,
                          isLocked,
                          questNumber,
                          StepsLength,
                          insideCircle,
                          textBelowProgressCircle,
                          classes,
                          pathname,
                          state,
                          isSelected,
                          onClick
                        }) {
  const ring = useRef()
  const progressState = questNumber / StepsLength * 100
  const [circleProgressColorState, setCircleProgressColorState] = useState(color);


  useEffect(() => {
    // var ring = document.getElementsByTagName('path')[0];
    // var range = document.getElementsByTagName('input')[0];
    // var CircleProgressText = document.getElementsByClassName('CircleProgress__text')[0];
    var toRadians = Math.PI / 180;
    var r = 100;
    var degrees = progressState * 3.5999;
    var rad = degrees * toRadians;
    var x = (Math.sin(rad) * r).toFixed(2);
    var y = -(Math.cos(rad) * r).toFixed(2);
    var lenghty = Number(degrees > 180);
    var descriptions = ['M', 0, 0, 'v', -r, 'A', r, r, 1, lenghty, 1, x, y, 'z'];
    ring.current.setAttribute('d', descriptions.join(' '));
    ring.current.setAttribute('transform', 'translate(' + r + ', ' + r + ')');

    // if(progressState >= 100){progressState = 100}

  }, [circleProgressColorState]);

  return (
    <div onClick={onClick}
         className={"circleProgress-container " + (isLocked ? "circleProgressDisabled" : "") + (isSelected ? "circleProgressSelected" : "")}>
      <Link
        to={onClick ? '#' : {
          pathname: pathname,
          state: state,
        }}
      >
        <div className={"circleProgress "}>
          <div
            className={"CircleProgress__counter noselect " + (progressState === 100 ? "CircleProgress__counter__complete" : "CircleProgress__counter__" + circleProgressColorState)}>
            {progressState === 100 ?
              <div className="v-icon">{EmojiToImage("v", false)}</div> : questNumber + "/" + StepsLength}
          </div>
          <div
            className={"CircleProgress__insideCircle noselect " + " CircleProgress__insideCircle__" + circleProgressColorState}>{insideCircle}</div>
          {/* <div className={"CircleProgress__insideCircle noselect " + " CircleProgress__insideCircle__" + color}>{insideCircle}</div> */}
          <div className={"svg-container " + (isSelected ? "svg-container__selected" : "")}>
            <svg>
              <circle className="CircleProgressBarBG" cx="100" cy="100" r="73" fill="none" strokeWidth="54"
                      opacity=".5"/>
              <path ref={ring}
                    className={"CircleProgressBar " + ("CircleProgressBar__" + circleProgressColorState) + ((progressState == 100) ? " progress100" : "")}
                    id="ring"/>
              <circle className="inner-Circle  " cx="100" cy="100" r="89"/>
            </svg>
          </div>
        </div>
        <div className="circleProgress__textBelowProgressCircle noselect">{textBelowProgressCircle}</div>
      </Link>
    </div>
  )
}

export default CircleProgress;