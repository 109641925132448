import React from 'react';
import "./ContentTwoColumns.scss"
import { useTranslation } from 'react-i18next';

function ContentTwoColumns ({
    leftContentMaxWidth,
    rightContentMaxWidth,
    leftContent,
    rightContnet
}){
    const { t } = useTranslation();
    // document.documentElement.style.setProperty('--leftContentMaxWidth', leftContentMaxWidth);
	return (
        <>
            <div className="ContentTwoColumns-container">
                <div className={"ContentTwoColumns-left " + (leftContentMaxWidth ? "leftContentMaxWidth_" + leftContentMaxWidth : "")}>
                    {leftContent}
                </div>
                <div className={"ContentTwoColumns-right " + (rightContentMaxWidth ? "rightContentMaxWidth_" + rightContentMaxWidth : "")}>
                    {rightContnet}
                </div>
            </div>

            </>
	)
}

export default ContentTwoColumns;
