

import React , {useState} from 'react';
import { useTranslation } from 'react-i18next';
import setPropertyVh from '../../utils/setPropertyVh';
import StepRequirement from 'components/StepRequirement/StepRequirement';

const Onboarding_Environment = ({
            // onExit,
            onBack,
            onContinue,            
    }) => {
    const {t} = useTranslation();
    setPropertyVh();

    return (
        <>
            <div className={"Onboarding-container"}>
                <div className={"Onboarding-content hideScrollbar"}>
                        <div className={"Onboarding-subtitle"}>{t("onboarding.whatNeedForLesson")}</div>
                        <div className={"Onboarding-title"}>{t("onboarding.Onboarding_Environment.title")}</div>
                        <div className={"Onboarding-description"}>{t("onboarding.Onboarding_Environment.subtitle")}</div>
                        <div className={"Onboarding--card"}>
                            <img className={"Onboarding--card__img Onboarding--card__img--maxWidth600"} src={"/assets/onboarding/table-and-ipad.png"} alt={"table and device"}/>
                        </div>
                        <div className='Room'></div>
                </div>
                <div className={"Onboarding-footer"}>
                    <button onClick={onContinue} className={"button Onboarding-button Onboarding-button-primary catbearsCTA-secondary"}>
                    {t("onboarding.Onboarding_Environment.cta2")}
                    </button>
                    <button onClick={onContinue} className={"button Onboarding-button Onboarding-button-secondary  catbearsCTA-primary Onboarding-button"}>
                        {t("onboarding.Onboarding_Environment.cta")}
                    </button>          
                </div>
            </div>
        </>
        )

}

export default Onboarding_Environment