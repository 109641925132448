import React from 'react';
import "./ButtonsFooter.scss";
import Button from "../../components/Button/Button";


function ButtonsFooter({btn1_action, btn1_type, btn1_classes, btn1_text, btn1_isDisabled,btn2_isDisabled, btn2_action, btn2_type, btn2_classes, btn2_text, btn2_isVisible, keyboardState,belowSubmitContent,isTranspBackground,footerBackgroundColor, shouldFooterBackgroundTransp, btn1Design, btn2Design, isButtonsOnTop, footerStyle_BRIGHT, footerStyle_XL}) {
  
  if(footerBackgroundColor){
    document.documentElement.style.setProperty('--footer_backgroundColor', footerBackgroundColor)
  }else{
    document.documentElement.style.setProperty('--footer_backgroundColor', "white")
  }

  let btn1DesignClass;
  if(btn1Design==="link"){
    btn1DesignClass=(footerStyle_BRIGHT ? "link_no-button link_BRIGHT" : "link_no-button  ")
  }else
  if(btn1Design==="secondery"){
    btn1DesignClass="ButtonsFooter__button"
    if (footerStyle_XL){ btn1DesignClass += " style_XL"}
  }else
  if(btn1Design==="primary"){
    btn1DesignClass=(footerStyle_BRIGHT ? "button-primary-BRIGHT button3D " : "button-primary button3D ")
    if (footerStyle_XL){ btn1DesignClass += " style_XL"}
  }else {
    btn1DesignClass="button-primary button3D"
  }



  let btn2DesignClass;
  if(btn2Design==="link"){
    btn2DesignClass=(footerStyle_BRIGHT ? "link_no-button link_BRIGHT " : "link_no-button ")
  }else
  if(btn2Design==="secondery"){
    btn2DesignClass="ButtonsFooter__button"
    if (footerStyle_XL){ btn2DesignClass += " style_XL"}
  }else
  if(btn2Design==="primary"){
    btn2DesignClass=(footerStyle_BRIGHT ? "button-primary-BRIGHT button3D " : "button-primary button3D ")
    if (footerStyle_XL){ btn2DesignClass += " style_XL"}
  }else {
    btn2DesignClass="ButtonsFooter__button"
  }


  return (
    <div className={"ButtonsFooter-container " + (shouldFooterBackgroundTransp ? "transpBackground" :"")}>
      <div className={"ButtonsFooter " + (btn2_action ? "ButtonsFooter-twoBtns " : "ButtonsFooter-oneBtn ") + " " + (isButtonsOnTop ? "buttonsOnTop" : "" )}>        
      {/* + (keyboardState ? "ButtonsFooterExpended " : "ButtonsFooterCollapsed ") */}
          { btn1_action ? 
              <Button
              text={btn1_text}
              onClick={(!btn1_isDisabled) ?btn1_action : null}
              classes={btn1DesignClass + " " + (btn1_isDisabled ? "buttonDisabled " : " ") + btn1_classes }
              />
              : ""}
          {btn2_action ? 
              <Button
              text={btn2_text}
              onClick={(!btn2_isDisabled) ?btn2_action : null}
              classes={btn2DesignClass + " " + btn2_classes + (btn2_isVisible ? " " : "displayNone ") }
              />
              : ""
              }
    </div>
    {belowSubmitContent}
  </div>
      
  )
}

export default ButtonsFooter;