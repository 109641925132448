import React, { useContext, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import FullScreenStepper from 'components/FullScreenStepper/FullScreenStepper';
import Q_RatingNumeric from 'components/Q_Elements/Q_RatingNumeric/Q_RatingNumeric';
import "./lessonFeedback.scss";
import { useMutation } from "react-apollo";
import gql from "graphql-tag";
import { updateUserFeedback } from '../../graphql/mutations';
import UserContext from "../../pages/Login/UserContext";
import Onboarding_Register from 'components/Onboarding/Onboarding_Register';
import getHourAndDate from '../../utils/getHourAndDate';
import Q_InputFeedback from 'components/Q_Elements/Q_InputFeedback/Q_InputFeedback';

const LessonFeedback = ({ onFinish }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { user, handleUserUpdate } = useContext(UserContext);
  const [ratingValue, setRatingValue] = useState(null);
  const [FeedbackInputValue, setFeedbackInputValue] = useState('');
  const textAreaRef = useRef(null);

  const createFeedbackEntry = (rating, text) => ({
    name: '1stFeedback',
    rating,
    text,
    date: getHourAndDate()
  });

  const [updateUser, { loading, error }] = useMutation(gql(updateUserFeedback), {
    update(cache, { data: { setUserFeedback } }) {
      if (setUserFeedback) {
        handleUserUpdate(setUserFeedback);
      } else {
        console.error('setUserFeedback is undefined or null');
      }
    }
  });

  const UpdateUserFeedbacksFunc = async (rating, text) => {
    try {
      const currentFeedbacks = user.userFeedback || {};
      const newFeedbackKey = `feedback_${Object.keys(currentFeedbacks).length + 1}`;
      
      const updatedFeedbacks = {
        ...currentFeedbacks,
        [newFeedbackKey]: createFeedbackEntry(rating, text)
      };

      await updateUser({ variables: { userFeedback: updatedFeedbacks } });
      console.log('User feedback updated successfully:', updatedFeedbacks);
    } catch (error) {
      console.error('Error updating user feedback:', error);
    }
  };

  const saveAndContinue = async (onContinue) => {
    if (ratingValue) {
      await UpdateUserFeedbacksFunc(ratingValue, textAreaRef.current.value);
      onContinue();
    }
  };

  const LessonLikelihood = ({ onContinue }) => (
<>
    <form onSubmit={()=>saveAndContinue(onContinue)}>
      <div className="">
        <Q_RatingNumeric
          maxNum={5}
          title="איך היה השיעור?"
          textBelowMin="לא משהו"
          textBelowMax="מעולה!"
          onSelect={setRatingValue}
        />
      </div>
      <div className="">
        {/* <Q_InputFeedback
          label="ספרו לנו במילים"
          onInputChange={setFeedbackInputValue}
        /> */}
        <p className="lessonFeedback_subtitle">תספרו לנו במילים</p>
        <textarea 
          ref={textAreaRef}
          className="form-textarea" 
          rows="" 
          id="why-mySide-hopes" 
          name="why-mySide-hopes"
          // onChange={(event) => {
          //   setFeedbackInputValue(event.target.value);
          // }}
          // value={inputValue}
          // value={inputValue}
          >{FeedbackInputValue}
        </textarea>

      </div>
      <div className="lessonFeedback-footer noselect">
        <button className={`button Onboarding-button Onboarding-button-secondary catbearsCTA-primary Onboarding-button ${!ratingValue && 'buttonDisabled'}`} type="submit" value="Submit"  >{t("general.continue")}
        </button>
        {/* <button
          onClick={() => saveAndContinue(onContinue)}
          className={`button Onboarding-button Onboarding-button-secondary catbearsCTA-primary Onboarding-button ${!ratingValue && 'buttonDisabled'}`}
          disabled={!ratingValue}
          >
          {t("general.continue")}
        </button> */}
      </div>
      </form>
    </>
  );

  const getSteps = () => {
    const steps = [<LessonLikelihood />];
    if (user?.isGuest) {
      steps.push(<Onboarding_Register />);
    }
    return steps;
  };

  return (
    <FullScreenStepper
      steps={getSteps()}
      onFinish={onFinish}
      onExit={() => console.log('Exit!')}
    />
  );
};

export default LessonFeedback;
