import React from 'react';
import { useTranslation } from "react-i18next";
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import "./LogoCatbears.scss"

function LogoCatbears({isSoundSwitch, hideLogoOnMobile, isCentered, isTapToLandingPage}) {
  function onClick (){
    if(isTapToLandingPage) window.location.href = 'https://catbears.com';
  }
  const {i18n } = useTranslation();

  return (
    <>
    <div onClick={onClick} className={"LogoCatbears-container noselect " + (hideLogoOnMobile && "hideLogoOnMobile") + " " + (isCentered && "LogoCatbears-container__centered")}>
      <div className="toggle-switch hovering">
        {isSoundSwitch && <ToggleSwitch /> }
        
      </div>
        <img className="catbears-logo hovering" src={(i18n.translator.language == `en` ? "/assets/icons/logo-catbears__en.png" : "/assets/icons/logo-catbears__he.png")}/>
    </div>
    </>
  );
}

export default LogoCatbears;
