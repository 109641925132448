import React, { useRef } from 'react';
// import React from 'react';
import Card from '../Card/Card';
import CardPlaceholder from '../PlaceholderCard/PlaceholderCard';
import './CardWrap.scss';

function CardWrap({
  selectedCards,
  cards,
  classes,
  onCardChange,
  length = 25,
  openKeyboard,
  name,
  partnerName,
  gender,
  partnerGender,
  openCardEditor,
  cardWrapType,
  // setStateIsChangesToForm,
  setStateChanges,
  cardGalleryRef,
  questFormRef
}) {


  const numOfPlaceholders = 1;
  // const cardGalleryRef = useRef()
  const cardPlaceholderRef = useRef()
  function handleScrollToCardPlaceholder() {
    if (cardPlaceholderRef.current)
      cardPlaceholderRef.current?.scrollIntoView({behavior: 'smooth', block: "end", inline: "nearest"})
  }

  const onCardAdd = (card) => {
    if (selectedCards.length === length) return;
    // setStateIsChangesToForm(true)
    const newCards = [...selectedCards, card];
    onCardChange(newCards);
    selectedCards = newCards;
    // handleScrollToCardPlaceholder();
    setTimeout(function () {
      handleScrollToCardPlaceholder();
    }, 50);
  };

  return (
    <div className={'cardGallery__container noselect ' + classes}>
      {/* <div ref={cardGalleryRef} className={"cardWrap"}> */}
      <div className={'cardWrap'}>
        {selectedCards.map((card, i) => {
          return (
            <Card
              key={i}
              {...card}
              gender={gender}
              switchSides={card.switchSides}
              partnerGender={partnerGender}
              name={name}
              partnerName={partnerName}
              classes={'card_cardWrap add-bounce-animation '}
              onClick={() =>
                openCardEditor({ card: card, index: i, cardWrapType, setStateChanges })
              }
            />
          );
        })}
        {selectedCards.length < length &&
          new Array(numOfPlaceholders).fill(0).map((u, i) => (
            <div
              className="card card-placeholder"
              key={i}
              onClick={() => {
                // alert("opening keyboard!");

                openKeyboard({
                  cards,
                  onSelect: onCardAdd,
                  element: cardGalleryRef.current,
                });
                setTimeout(function () {
                  handleScrollToCardPlaceholder();
                }, 10);
              }}
            >
              <div ref={cardPlaceholderRef}><CardPlaceholder/></div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default CardWrap;

// import React, { useRef } from 'react';
// import Card from '../Card/Card';
// import CardPlaceholder from '../PlaceholderCard/PlaceholderCard';
// import './CardWrap.scss';

// function CardWrap({
//   selectedCards,
//   cards,
//   onCardChange,
//   length = 25,
//   openKeyboard,
//   name,
//   partnerName,
//   gender,
//   partnerGender,
//   openCardEditor,
//   cardWrapType,
//   setStateIsChangesToForm,
//   setStateChanges
// }) {
//   const numOfPlaceholders = 1;
//   const cardGalleryRef = useRef()
//   const onCardAdd = card => {
//     console.log(card);
//     setStateIsChangesToForm(true)
//     const newCards = [...selectedCards, card];
//     onCardChange(newCards);
//     selectedCards = newCards
//     const el = cardGalleryRef.current.querySelector(`.card-placeholder`)
//     // cardGalleryRef.current.scrollTo({ left: el.offsetLeft - cardGalleryRef.current.offsetLeft - el.clientWidth - 12, behavior: `smooth` });
//   };

//   return (
//     <div className="cardGallery__container noselect ">
//       <div ref={cardGalleryRef} className={"cardWrap"}>
//         {selectedCards.map((card, i) => {
//           return <Card key={i} {...card} gender={gender} partnerGender={partnerGender} name={name} partnerName={partnerName} onClick={() => openCardEditor({ card: card, index: i ,cardWrapType, setStateChanges })} />
//         })}
//         {new Array(numOfPlaceholders).fill(0).map((u, i) => (
//           <div className="card card-placeholder"
//             key={i}
//             onClick={() => openKeyboard({ cards, onSelect: onCardAdd, element: cardGalleryRef.current })}
//           >
//             <CardPlaceholder />
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }

// export default CardWrap;
