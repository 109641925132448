import React, {useEffect, useState} from 'react';
import './MeterDot.scss';
import { useTranslation } from 'react-i18next';



function MeterDot({
    indx, 
    title, 
    isSelected, 
    isFaded,
    emoji, 
    value, 
    classes, 
    setSelectedItemNumberState,
    updatedSelectedDot
}) {
    const { t } = useTranslation();

    const[isSelectedState , setIsSelectedState] = useState(isSelected);


    function setDotSelected() {
        if(isSelected){
            setIsSelectedState(true)
        }else {
            setIsSelectedState(false)
        }

        console.log("isSelectedState", isSelectedState)
    }

    useEffect(() => {
        setDotSelected();
    }, [isSelected]);
    
  return (
    <div className={'MeterDot noselect ' + (isSelectedState ? 'MeterDot__selected ' : " ") + (isFaded ? "MeterDot__faded " : "")+ (classes ? classes : "") }    
            onClick={ () =>
                isSelectedState 
                ?
                ""
                : 
                // setSelectedItemNumberState(indx)
                updatedSelectedDot(indx)
            }
    >
        <div className={"MeterDot__emoji"}>{emoji}</div>
        <div className="MeterDot__title">{t(title)}</div>
    </div>
  );
}

export default MeterDot;