import React , {useState} from 'react';
import { useTranslation } from 'react-i18next';
import {Locale} from "../../graphql/types";
import LanguageSelector from 'components/LanguageSelector/LanguageSelector';

function changeLanguage(i18n, langName, langId) {
    // console.log('i18n.translator.language', i18n.translator.language);
      if (!langId) { 
      langId = langName;
      i18n.changeLanguage(langId);
      document.body.setAttribute(`lang`, langId);
      return langId;
    }
}


const Onboarding_Language = ({onContinue, authContext}) => {
    const {t, i18n} = useTranslation();
    const [selectedLocale, setSelectedLocale] = useState()
    // const {changeLanguage} = authContext;

    console.log("authContext ", authContext)

    function localeHandler(i18n, langName){
        const langId = changeLanguage(i18n, langName)
        authContext.changeLanguage(langId, langName);
    }

    const onSelectLangague = (locale) => {
        localeHandler(i18n, locale)
        setSelectedLocale(locale)
    }

    const handleContinue = async () => {
        console.log("lang step on continue");
        await i18n.changeLanguage(selectedLocale === Locale.He ? 'he-IL' : 'en-US');
        const langCode = selectedLocale === Locale.He ? 'he' : 'en';
        document.body.setAttribute("lang", langCode);
        onSelectLangague(selectedLocale ? selectedLocale : authContext.user.locale );

        onContinue()
      };

    return (
        <>
            <div className={"Onboarding-container"}>
                <div className={"Onboarding-content hideScrollbar"}>
                    <div className={"Onboarding-title"}>{t(`general.welcome-to-catbears`)}</div>
                    <div className={"Onboarding-subtitle"}>{t(`language.title`)}</div>
                    <LanguageSelector onSelect={onSelectLangague} defLang={authContext.user.locale}/>
                </div>
                <div className={"Onboarding-footer"}>
                    <button onClick={handleContinue} className={"button Onboarding-button Onboarding-button-secondary  catbearsCTA-primary Onboarding-button"}>
                        {t("general.continue")}
                    </button>          
                </div>
            </div>
        </>
    )

}

export default Onboarding_Language



  // stepDisabled: user.locale !== Locale.He,
      // title: t(`language.title`),
      // element: (<LanguageSelector onSelect={(locale) => setSelectedLocale(locale)}/>),
      // isContinueDisabled: isNil(selectedLocale),
      // onContinue: async () => {
      //   console.log("lang step on continue")
      //   await i18n.changeLanguage(selectedLocale === Locale.He ? 'he-IL' : 'en-US');
      //   changeLanguage(selectedLocale);
      // },
      // btn1Text: "Continue"