import client from "../GraphQLClient";
import gql from "graphql-tag";
import omitDeep from "omit-deep-lodash";

function mutate(query, data, options = {}) {
  let q = Object.assign({
    mutation: gql(query),
    variables: {
      input: omitDeep(data, `__typename`)
    }
  }, options)
  return client.mutate(q);
}

function query(query, data) {
  return client.query({
    query: gql(query),
    variables: data,
    fetchPolicy: `network-only`,
  });
}

export { query, mutate }; 