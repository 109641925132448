import React, { createContext, useState } from 'react';

const GlobalContext = createContext();

const GlobalProvider = ({ children }) => {
  const [VolumeSwitchState, setVolumeSwitchState] = useState(true);
  const [volumeBar, setVolumeBar] = useState(100); // New state for volume control

  return (
    <GlobalContext.Provider value={{ 
      VolumeSwitchState, 
      setVolumeSwitchState, 
      volumeBar, 
      setVolumeBar 
    }}>
      {children}
    </GlobalContext.Provider>
  );
};

export { GlobalContext, GlobalProvider };
