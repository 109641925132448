import React, { useState, useEffect, useRef } from 'react';
import Animal from '../../components/Animal/Animal';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TopNavbar from '../../components/TopNavbar/TopNavbar';
import './MapRelationship.scss';
import CardGallery from '../../components/CardGallery/CardGallery';
import { happenedCards, feelCards, needCards, CARD_TYPE_TO_SURVEY_MAP } from '../../static/cards';
import CardKeyboard from '../../components/CardKeyboard/CardKeyboard';
import SideSelector from '../../components/SideSelector/SideSelector';
import Drawer from '@material-ui/core/Drawer';
import CardEditor from '../../components/CardEditor/CardEditor';
import Dialog from '@material-ui/core/Dialog';
import { mutate } from '../../graphql/helpers';
import { updateRelationship } from '../../graphql/mutations';
import * as ANIMALS from '../../components/Animal/AnimalAssets';

document.addEventListener(
  'touchmove',
  function(e) {
    e.preventDefault();
  },
  false
);

const toggleDrawer = (open, setKeyboardState) => event => {
  setKeyboardState({ isOpen: open });
};
const toggleDialog = (open, setCardEditorState) => event => {
  setCardEditorState({ isOpen: open });
};

function MapRelationship({ history, user }) {
  const { relationship } = history.location.state || {
    relationship: { animalId: `dino`, partnerAnimalId: `dino` }
  };
  const { t } = useTranslation();
  const [side, setSide] = useState(`mySide`);
  const isFirstRun = useRef(true);
  const {
    name,
    partnerName,
    gender,
    partnerGender,
    relationshipSurvey,
    id,
    partnerRelationshipSurvey
  } = relationship;
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    async function update() {
      await mutate(updateRelationship, {
        id: id,
        relationshipSurvey: relationshipSurvey,
        partnerRelationshipSurvey: partnerRelationshipSurvey
      });
    }
    update();
  }, [relationship]);
  function updateHistory(relationship, relationshipSurvey, partnerRelationshipSurvey) {
    history.replace(history.location.pathname, {
      relationship: {
        ...relationship,
        relationshipSurvey: relationshipSurvey,
        partnerRelationshipSurvey: partnerRelationshipSurvey
      }
    });
  }

  const [keyboardState, setKeyboardState] = useState({ isOpen: false });
  const [cardEditorState, setCardEditorState] = useState({ isOpen: false });
  if (!history.location.state) return <Redirect to={`/`} />;

  let sideData, animalId, setSideState;
  if (side === 'mySide') {
    sideData = relationshipSurvey || {
      surveyHappened: [],
      surveyFeeling: [],
      surveyNeeds: []
    };
    setSideState = data => {
      updateHistory(relationship, data, partnerRelationshipSurvey);
    };
    animalId = relationship.animalId;
  } else {
    sideData = partnerRelationshipSurvey || {
      surveyHappened: [],
      surveyFeeling: [],
      surveyNeeds: []
    };
    setSideState = data => {
      updateHistory(relationship, relationshipSurvey, data);
    };
    animalId = relationship.partnerAnimalId;
  }

  const openKeyboard = ({ cards, filters, onSelect, element }) => {
    setKeyboardState({
      isOpen: true,
      cards,
      name,
      partnerName,
      gender,
      partnerGender,
      filters,
      onSelect
    });
    window.scrollTo({ top: element.parentElement.offsetTop - 60, behavior: `smooth` });
  };
  const openCardEditor = ({ card, index }) => {
    setCardEditorState({
      isOpen: true,
      card,
      name,
      partnerName,
      gender,
      partnerGender,
      onChange: ({ card }) => {
        sideData[CARD_TYPE_TO_SURVEY_MAP[card.cardType]][index] = card;
        setSideState({
          ...sideData,
          [CARD_TYPE_TO_SURVEY_MAP[card.cardType]]: sideData[CARD_TYPE_TO_SURVEY_MAP[card.cardType]]
        });
      },
      onDelete: () => {
        sideData[CARD_TYPE_TO_SURVEY_MAP[card.cardType]].splice(index, 1);
        setSideState({
          ...sideData,
          [CARD_TYPE_TO_SURVEY_MAP[card.cardType]]: sideData[CARD_TYPE_TO_SURVEY_MAP[card.cardType]]
        });
        setCardEditorState({ isOpen: false });
      },
      onClose: () => setCardEditorState({ isOpen: false })
    });
  };

  const pageStyle = {
    background: `${ANIMALS.default[animalId].color}`
  };
  return (
    <>
      <TopNavbar
        user={user}
        border={false}
        title={t(`map_relationship.title`)}
        showBackButton={true}
        history={history}
      ></TopNavbar>
      <div className="pageTopPadding">
        <SideSelector
          mySide={name}
          partnerSide={partnerName}
          selectedSide={side}
          onSideSelect={setSide}
          history={history}
          animalId={relationship.animalId}
          partnerAnimalId={relationship.partnerAnimalId}
        />
        <div className="map-relationship-page texture" style={pageStyle}>
          <div>
            <Animal id={animalId} className={side} />
          </div>
          <div className="map-relation__section">
            <h4>
              {t(`map_relationship.${side}.happened_title`, {
                context: relationship.partnerGender
              })}
            </h4>
            <CardGallery
              openKeyboard={openKeyboard}
              openCardEditor={openCardEditor}
              cards={happenedCards}
              selectedCards={sideData.surveyHappened}
              name={name}
              partnerName={partnerName}
              gender={gender}
              partnerGender={partnerGender}
              onCardChange={newCards => setSideState({ ...sideData, surveyHappened: newCards })}
            />
          </div>
          <div className="map-relation__section">
            <h4>
              {t(`map_relationship.${side}.feel_title`, { context: relationship.partnerGender })}
            </h4>
            <CardGallery
              openKeyboard={openKeyboard}
              openCardEditor={openCardEditor}
              cards={feelCards}
              selectedCards={sideData.surveyFeeling}
              name={name}
              partnerName={partnerName}
              gender={gender}
              partnerGender={partnerGender}
              onCardChange={newCards => setSideState({ ...sideData, surveyFeeling: newCards })}
            />
          </div>
          <div className="map-relation__section">
            <h4>
              {t(`map_relationship.${side}.need_title`, { context: relationship.partnerGender })}
            </h4>
            <CardGallery
              openKeyboard={openKeyboard}
              openCardEditor={openCardEditor}
              cards={needCards}
              selectedCards={sideData.surveyNeeds}
              name={name}
              partnerName={partnerName}
              gender={gender}
              partnerGender={partnerGender}
              onCardChange={newCards => setSideState({ ...sideData, surveyNeeds: newCards })}
            />
          </div>
        </div>
      </div>
      <Drawer
        open={keyboardState.isOpen}
        anchor={`bottom`}
        onClose={toggleDrawer(false, setKeyboardState)}
      >
        {keyboardState.isOpen && (
          <CardKeyboard
            {...keyboardState}
            filters={[
              { title: t(`card_keyboard.all`), filterFn: () => true },
              { title: t(`card_keyboard.positive`), filterFn: card => card.isPositive },
              { title: t(`card_keyboard.negative`), filterFn: card => !card.isPositive }
            ]}
          />
        )}
      </Drawer>
      <Dialog open={cardEditorState.isOpen} onClose={toggleDialog(false, setCardEditorState)}>
        {cardEditorState.isOpen && <CardEditor {...cardEditorState} />}
      </Dialog>
    </>
  );
}

export default MapRelationship;
