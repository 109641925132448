
const LessonHouseSteps = [
  
  { // step 0 - intro
    size : "ROW",
    name: "general.intro", 
    description: "course.theatre.lesson-house.description",
    type: "videoTeamwork",
    AnimalName : "crow",
    // requirements: [{name: "activityPage"} ],
    videoThumbnail: "/assets/course/theatre/lesson-thumbnail_house.jpeg",
    stepItems : [
      { 
        pageState: "video",
        url: "https://media.catbears.com/course_theatre/house_step-00_intro.mp4",
        videoThumbnail: "/assets/course/theatre/intro-thumbnail.jpg",
        isStepGoal: true
      }
    ]
  },
  { // Skill
    buttonShape: "circle", // 
    size : "coinImg",
    name: "teamworkSkills.stop-to-relax.name", 
    description: "teamworkSkills.stop-to-relax.description",
    desciptionTips: ["Tip 1", "Tip 2"],
    type: "videoTeamwork",
    coinName: "stop-to-relax",
    // requirements: [],
    videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/how-to-stop-the-anger.jpg",
    stepItems : [
      { 
        pageState: "video",
        url: "https://media.catbears.com/course_theatre/stop-to-relax.mp4",
        isStepGoal: true
      },
      {
        pageState: "discuss",
        img: "/assets/course/theatre/videos-and-thumbnails/stop-the-anger-poster.jpg",
        discussTitle: "teamworkSkills.stop-to-relax.discussTitle",
      },
    ]
  },
  { // glue on cardboard
    StepNumberToShow: 1,
    name: "course.theatre.lesson-house.steps.glue-on-cardboard.name",
    size : "S", 
    state: "disabled", 
    description: "course.theatre.lesson-house.steps.glue-on-cardboard.description",
    desciptionTips: ["Tip 1", "Tip 2"],
    type: "videoTeamwork",
    requirements: [{name: "activityPage"}, {name: "cardboard"}, {name: "stick-glue"} ],
    videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/house_step-01_glue-on-cardboard.jpg",
    stepItems : [
      { 
        pageState: "video",
        url: "https://media.catbears.com/course_theatre/house_step-01_glue-on-cardboard.mp4",
        isStepGoal: true
      },
      {
        pageState: "teamWork",
        url: "https://media.catbears.com/course_theatre/house_step-01_glue-on-cardboard.mp4",
        string: "teamwork text here",
        bullets: [
          {
            text: "course.theatre.lesson-house.steps.glue-on-cardboard.bullets.bullet1",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_glue-on-cardboard_01.jpg"
          },
          {
            text: "course.generalSteps.cut-cardboard-light",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/general_cut-cardboard-light.jpg"
          },
          {
            text: "course.theatre.lesson-house.steps.glue-on-cardboard.bullets.bullet3",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_glue-on-cardboard_03.jpg"
          },
          {
            text: "course.theatre.lesson-house.steps.glue-on-cardboard.bullets.bullet4",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_glue-on-cardboard_04.jpg"
          }
        ],
        tips: ["course.generalTips.cut-cardboard-light"]
      }
    ]
  },
  { // cut-the-house
    StepNumberToShow: 2,
    name: "course.theatre.lesson-house.steps.cut-the-house.name",
    size : "S", 
    state: "disabled", 
    description: "course.theatre.lesson-house.steps.cut-the-house.description",
    desciptionTips: ["Tip 1", "Tip 2"],
    type: "videoTeamwork",
    requirements: [{name: "scissors"}],
    videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/house_step-02_cut-the-house.jpg",
    stepItems : [
      { 
        pageState: "video",
        url: "https://media.catbears.com/course_theatre/house_step-02_cut-the-house.mp4",
        isStepGoal: true
      },
      {
        pageState: "teamWork",
        url: "https://media.catbears.com/course_theatre/house_step-02_cut-the-house.mp4",
        string: "teamwork text here",
        bullets: [
          {
            text: "course.theatre.lesson-house.steps.cut-the-house.bullets.bullet1",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_cut-the-house_01.jpg"
          },
          {
            text: "course.theatre.lesson-house.steps.cut-the-house.bullets.bullet2",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_cut-the-house_02.jpg"
          },
          {
            text: "course.theatre.lesson-house.steps.cut-the-house.bullets.bullet3",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_cut-the-house_03.jpg"
          }
        ]
      }
    ]
  },
  { // fold-cardboard
    StepNumberToShow: 3,
    name: "course.theatre.lesson-house.steps.fold-cardboard.name",
    size : "S", 
    state: "disabled", 
    description: "course.theatre.lesson-house.steps.fold-cardboard.description",
    desciptionTips: ["Tip 1", "Tip 2"],
    type: "videoTeamwork",
    requirements: [{name: "scissors"}],
    videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/house_step-03_fold-cardboard.jpg",
    stepItems : [
      { 
        pageState: "video",
        // url: "https://media.catbears.com/course_theatre/testvid.mp4",
        url: "https://media.catbears.com/course_theatre/house_step-03_fold-cardboard.mp4",
        isStepGoal: true
      },
      {
        pageState: "teamWork",
        url: "https://media.catbears.com/course_theatre/house_step-03_fold-cardboard.mp4",
        string: "teamwork text here",
        bullets: [
          {
            text: "course.theatre.lesson-house.steps.fold-cardboard.bullets.bullet1",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_fold-cardboard_01.jpg"
          },
          {
            text: "course.theatre.lesson-house.steps.fold-cardboard.bullets.bullet2",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_fold-cardboard_02.jpg"
          },
          {
            text: "course.theatre.lesson-house.steps.fold-cardboard.bullets.bullet3",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_fold-cardboard_03.jpg"
          },
          {
            text: "course.theatre.lesson-house.steps.fold-cardboard.bullets.bullet4",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_fold-cardboard_04.jpg"
          }
        ]
      }
    ]
  },
  { // base
    StepNumberToShow: 4,
    name: "course.theatre.lesson-house.steps.base.name",
    size : "S", 
    state: "disabled", 
    description: "course.theatre.lesson-house.steps.base.description",
    desciptionTips: ["Tip 1", "Tip 2"],
    type: "videoTeamwork",
    requirements: [{name: "cardboard"}, {name: "scissors"}, {name: "bottle-glue"}, {name: "tape"}],
    videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/house_step-04_base.jpg",
    stepItems : [
      { 
        pageState: "video",
        url: "https://media.catbears.com/course_theatre/house_step-04_base.mp4",
        isStepGoal: true
      },
      {
        pageState: "teamWork",
        url: "https://media.catbears.com/course_theatre/house_step-04_base.mp4",
        string: "teamwork text here",
        bullets: [
          {
            text: "course.theatre.lesson-house.steps.base.bullets.bullet1",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_base_01.jpg"
          },
          {
            text: "course.theatre.lesson-house.steps.base.bullets.bullet2",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_base_02.jpg"
          },
          {
            text: "course.theatre.lesson-house.steps.base.bullets.bullet3",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_base_03.jpg"
          },
          {
            text: "course.theatre.lesson-house.steps.base.bullets.bullet4",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_base_04.jpg"
          },
          {
            text: "course.theatre.lesson-house.steps.base.bullets.bullet5",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_base_05.jpg"
          },
          {
            text: "course.theatre.lesson-house.steps.base.bullets.bullet6",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_base_06.jpg"
          }
        ],
        tips: ["course.theatre.lesson-house.steps.base.tips.tip1"]
      }
    ]
  },
  { // windows-and-doors 
    StepNumberToShow: 5,
    name: "course.theatre.lesson-house.steps.windows-and-doors.name",
    size : "S", 
    state: "disabled", 
    description: "course.theatre.lesson-house.steps.windows-and-doors.description",
    desciptionTips: ["Tip 1", "Tip 2"],
    type: "videoTeamwork",
    requirements: [{name: "activityPage"}, {name: "scissors"}, {name: "stick-glue"} ],
    videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/house_step-05_windows-and-doors.jpg",
    stepItems : [
      { 
        pageState: "video",
        // url: "https://media.catbears.com/course_theatre/testvid.mp4",
        url: "https://media.catbears.com/course_theatre/house_step-05_windows-and-doors.mp4",
        isStepGoal: true
      },
      {
        pageState: "teamWork",
        url: "https://media.catbears.com/course_theatre/house_step-05_windows-and-doors.mp4",
        string: "teamwork text here",
        bullets: [
          {
            text: "course.theatre.lesson-house.steps.windows-and-doors.bullets.bullet1",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_windows-and-doors_01.jpg"
          },
          {
            text: "course.theatre.lesson-house.steps.windows-and-doors.bullets.bullet2",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_windows-and-doors_02.jpg"
          },
          {
            text: "course.theatre.lesson-house.steps.windows-and-doors.bullets.bullet3",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_windows-and-doors_03.jpg"
          },
          {
            text: "course.theatre.lesson-house.steps.windows-and-doors.bullets.bullet4",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_windows-and-doors_04.jpg"
          },
          {
            text: "course.theatre.lesson-house.steps.windows-and-doors.bullets.bullet5",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_windows-and-doors_05.jpg"
          }
        ],
        tips: ["course.theatre.lesson-house.steps.windows-and-doors.tips.tip1"]
      }
    ]
  },
  { // pink-tree 
    name: "conflict-simulator.pink-tree.name",
    size : "XL", 
    state: "selected", 
    description: "conflict-simulator.pink-tree.description",
    desciptionTips: ["Tip 1", "Tip 2"],
    type: "conflict-simulator",
    // AnimalName : "bear",
    ImageToShow: "/assets/course/theatre/videos-and-thumbnails/pink-tree_image-to-show.png",
    // requirements: [],
    videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/thumb-pink-tree.jpg",
    stepItems : [
      {
        pageState: "video",
        url: "https://media.catbears.com/course_theatre/pink-tree_1.mp4",
        isStepGoal: true
      },
      {
        pageState: "discuss",
        img: "/assets/course/theatre/videos-and-thumbnails/pink-tree_discuss.jpg",
        discussTitle: "conflict-simulator.pink-tree.discussTitle",
        discussSubtitle: "conflict-simulator.pink-tree.discussSubtitle",
      },
      {
        pageState: "video",
        url: "https://media.catbears.com/course_theatre/pink-tree_2.mp4",
        isStepGoal: true
      },
    ]
  },
  { // color 
    StepNumberToShow: 6,
    name: "course.theatre.lesson-house.steps.color.name",
    size : "S", 
    state: "disabled", 
    description: "course.theatre.lesson-house.steps.color.description",
    desciptionTips: ["Tip 1", "Tip 2"],
    type: "videoTeamwork",
    requirements: [{name: "allColors"} ],
    videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/house_step-06_color.jpg",
    stepItems : [
      { 
        pageState: "video",
        // url: "https://media.catbears.com/course_theatre/testvid.mp4",
        url: "https://media.catbears.com/course_theatre/house_step-06_color.mp4",
        isStepGoal: true
      },
      {
        pageState: "teamWork",
        url: "https://media.catbears.com/course_theatre/house_step-06_color.mp4",
        string: "teamwork text here",
        bullets: [
          {
            text: "course.generalSteps.all-colors",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/general_all-colors.jpg"
          },
          {
            text: "course.theatre.lesson-house.steps.color.bullets.bullet2",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_color_02.jpg"
          },
          {
            text: "course.theatre.lesson-house.steps.color.bullets.bullet3",
            img: "https://media.catbears.com/course_theatre/teamwork-steps/house_color_03.jpg"
          }
        ]
      }
    ]
  },     
  ]
  
  export default LessonHouseSteps;