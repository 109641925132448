import React, {useEffect, useState} from 'react';
import "./EmojiToImage.scss";


function EmojiToImage(emojiOrName, isWhiteEmoji){
     
     const[isHeLangState, setIsHeLangState]  = useState(false)
     const[EmojiShouldFlipWhenHeState, setEmojiShouldFlipWhenHeState]  = useState(false) 
     const[emoji_holderState, setEmoji_holderState]  = useState() 
     
     useEffect(() => {     
          if(document.body.getAttribute(`lang`)== "he"){
             setIsHeLangState(true)
          }else if(document.body.getAttribute(`lang`)== "en") {
             setIsHeLangState(false)
          }else{
             setIsHeLangState(false)
            }
          // var emoji_holder
          switch (emojiOrName){
              case "💭":
              case "bubble":
                   setEmoji_holderState("catbears_emoji_bubble");
              break
              case "mouth-sad":
                   setEmoji_holderState("catbears_emoji_mouth-sad");
              break
              case "minus-to-plus":
                   setEmoji_holderState("catbears_emoji_minus-to-plus");
              break
              case "going-through":
                   setEmoji_holderState("catbears_emoji_going-through");
              break
              case "❤️":
              case "heart":
                   setEmoji_holderState("catbears_emoji_heart");
              break
              case "empathy":
                   setEmoji_holderState("catbears_emoji_empathy");
              break
              case "empathy-seed":
                   setEmoji_holderState("catbears_emoji_empathy-seed");
              break
              case "🙏":
              case "hope":
                   setEmoji_holderState("catbears_emoji_hope");
              break
              case "✉️":
              case "envelope":
                   setEmoji_holderState("catbears_emoji_envelope");
              break
              case "need" :
                   setEmoji_holderState("catbears_emoji_need");
              break
              case "lemon" :
                   setEmoji_holderState("catbears_emoji_lemon");
                   break
              case "crossroad" :
                   setEmoji_holderState("catbears_emoji_crossroad");
                   break
              case "eyes_looking" :
                   setEmoji_holderState("catbears_emoji_eyes_looking");
                   setEmojiShouldFlipWhenHeState(true)
              break
              case "shoe" :
                   setEmoji_holderState("catbears_emoji_shoe");
                   setEmojiShouldFlipWhenHeState(true)
              break
              case "eyes_bubble" :
                   setEmoji_holderState("catbears_emoji_eyes_looking-in-a-thought-bubble");
                   setEmojiShouldFlipWhenHeState(true)
                   break
              case "balloon_pushpin" :
                   setEmoji_holderState("catbears_emoji_balloon_pushpin");
                   setEmojiShouldFlipWhenHeState(true)
              break
              case "v" :
                   setEmoji_holderState("catbears_emoji_v");
                   setEmojiShouldFlipWhenHeState(false)
              break
              case "balloon_pushpin_bubble" :
                   setEmoji_holderState("catbears_emoji_balloon_pushpin_in_bubble");
                   setEmojiShouldFlipWhenHeState(true)
              break
          } 
        
     },[])


    return <img src={"/assets/catbears-emojis/" + emoji_holderState + (isWhiteEmoji ? "_white": "") + ".png"} className={"catbears_emoji_image " + (EmojiShouldFlipWhenHeState && isHeLangState ? "EmojiFlipped" : "")}/>
}

export default EmojiToImage