import React, {useContext, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import setPropertyVh from '../../utils/setPropertyVh';
import Instructor from '../../components/Instructor/Instructor';
import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
import './Onboarding_Register.scss'
import Catbears_Register from 'pages/Login/Catbears_Register';
import UserContext from "../../pages/Login/UserContext";
import Catbears_Login from "../../pages/Login/Catbears_Login";

const Onboarding_Register = ({
            // onExit,
            onBack,
            onContinue,            
    }) => {
    const {t} = useTranslation();
    setPropertyVh();

    const loginFunc = () => {user.isGuest && setIsLogin(true)}
    const {user, handleUserUpdate} = useContext(UserContext);
    const [isLogin, setIsLogin] = useState(false);
    const [shouldDisplayLogin, setshouldDisplayLogin] = useState(false); // no

    const toggleDisplayLogin = () => {
        setshouldDisplayLogin(!shouldDisplayLogin)
    }
    return (
        <>
        {/* <Xbutton fixed={true} onClose={onXbutton ? onXbutton : undefined}/> */}
        <div className={"Onboarding-content Onboarding-container-no-footer"}>
                    {/* <div className={"Onboarding-title"}>{t("loginFlow.createAnAccount")}</div>
                    <div className={"Onboarding-subtitle"}>{t("loginFlow.progress-saved")}</div> */}
                       {shouldDisplayLogin ? 
                        <Catbears_Login 
                            onLogin={"onCloseLogin"} 
                            onClose={"onCloseLogin"} 
                            shouldAllowSwitchToRegister={false}
                            toggleDisplayLogin={toggleDisplayLogin}
                        />
                        :
                        <Catbears_Register
                            shouldDisplayLogin={false}
                            onSignUp={onContinue}
                            isSkippable={true}
                            onSkip={onContinue}
                            dontShowTitle={true}
                            hasAccout={loginFunc}
                            toggleDisplayLogin={toggleDisplayLogin}
                            // isBright={true}
                        />
                        
                        }
                    <div className='Room'></div>
                </div>
            {/* <div className={"Onboarding-footer"}>
                <button onClick={""} className={"button Onboarding-button Onboarding-button-secondary  catbearsCTA-primary Onboarding-button"}>
                    {t("general.watchVideo")}
                </button>          
                <button onClick={onContinue} className={"button Onboarding-button Onboarding-button-primary catbearsCTA-secondary"}>
                    {t("general.watchedAndContinue")}
                </button>
            </div> */}
        </>
        )

}

export default Onboarding_Register