import React, {useState,useEffect, useRef} from 'react';
import './StoryTime.scss'


const StoryTime = () => {

    const [FLIP_PAGE_2, setFLIP_PAGE_2] = useState(false)
    const [FLIP_PAGE_4, setFLIP_PAGE_4] = useState(false)
    const [PAGE_2, setPAGE_2] = useState(true)
    const [PAGE_4, setPAGE_4] = useState(true)
    const flip = (num) => {
        console.log("flip", num)
        eval("setFLIP_PAGE_" + num)(!eval("FLIP_PAGE_" + num))
        setTimeout(()=>{
            eval("setPAGE_" + num)(!eval("PAGE_" + num))
        },300)  
    }

    return(
        <>
        <div className="StoryTime__book">
            <div className="StoryTime__kfula StoryTime__kfula--top">
                <div className="StoryTime__page--1 StoryTime__page">
                    <div className="page__text">
                        <h1>Once upon a time...</h1>
                    </div>
                </div>
                {PAGE_2 ? <div className={"StoryTime__page--2 StoryTime__page " + (FLIP_PAGE_2 ? "flip" : "") } onClick={()=>flip(2)}>
                    <div className={"page__img" } >
                        <img src="/assets/questgame/sticker2.png"/>
                    </div>
                </div> : 
                <div className={"StoryTime__page--3 StoryTime__page " + (FLIP_PAGE_2 ? "flip" : "") } onClick={()=>flip(2)}>
                    <div className="page__text">
                        <h1>In a galaxy far far away</h1>
                    </div>
                </div>}
            </div>
            <div className="StoryTime__kfula StoryTime__kfula--bottom">
                <div className="deadpage StoryTime__page">
                </div>
                {PAGE_4 ? <div className={"StoryTime__page--4 StoryTime__page " + (FLIP_PAGE_4 ? "flip" : "") } onClick={()=>flip(4)}>
                    <div className={"page__img" } >
                        <img src="/assets/questgame/sticker11.png"/>
                    </div>
                </div> : <div className={"StoryTime__page--5 StoryTime__page " + (FLIP_PAGE_4 ? "flip" : "") } onClick={()=>flip(4)}>
                    <div className={"page__img" } >
                        <img src="/assets/questgame/sticker15.png"/>
                    </div>
                </div>}
        </div>
        </div>
        </>
    )
}

export default StoryTime
