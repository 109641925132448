import React, { useState, useRef } from 'react';
import Card from '../Card/Card';
import './CardKeyboard.scss';

function Filters({ filters, onFilterChange, currentFilter }) {
  const onFilterClick = (filter) => {
    onFilterChange(filter);
  };

  return (
    <>
      {filters.map((filter, i) => (
        <div
          className={currentFilter.title === filter.title ? 'selected' : ''}
          key={i}
          onClick={() => onFilterClick(filter)}
        >
          {filter.title}
        </div>
      ))}
    </>
  );
}

function CardKeyboard({
  cards = [],
  onSelect,
  name,
  partnerName,
  gender,
  partnerGender,
  switchSides,
  filters = [{ title: 'All', filterFn: () => true }]
}) {
  const spacerRef = useRef();
  const cardsRef = useRef();
  const [currentFilter, setFilter] = useState(filters[0]);
  const currentCards = cards.filter(currentFilter.filterFn);

  return (
    <div className="card-keyboard">
      <div className="card-keyboard__filters">
        <div className="card-keyboard__filters--tabs">
          <Filters
            items={cards}
            filters={filters}
            currentFilter={currentFilter}
            onFilterChange={(filter) => {
              setFilter(filter);
            }}
          />
        </div>
      </div>
      <div ref={cardsRef} className="card-keyboard__cards">
          {currentCards.map((card, i) => (
            <div key={i} onClick={() => onSelect(card)}>
              <Card
                {...card}
                gender={gender}
                partnerGender={partnerGender}
                name={name}
                partnerName={partnerName}
                switchSides={card.switchSides}
              />
            </div>
          ))}
        <span ref={spacerRef} className="spacer"></span>
      </div>
    </div>
  );
}

export default CardKeyboard;
