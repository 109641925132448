import React, { useState, useEffect , forwardRef, useRef, useImperativeHandle} from 'react';
import "./ProgressBar.scss";

// function ProgressBar({currentStep, maxStep, progressBarColor, isDarkMode, classes, showFirstStepEmpty, showBarFull }) {
const ProgressBar = forwardRef(({currentStep, maxStep, progressBarColor, isDarkMode, classes, showFirstStepEmpty, showBarFull }, ref) => {
  
  const[currentStepState, setCurrentStepState] = useState(currentStep);
  const[maxStepState, setMaxStepState] = useState(maxStep);
  const[progressBarColorState, setProgressBarColorState] = useState(progressBarColor);
  const[progressPrecentageState, setProgressPrecentageState] = useState();
  
  useImperativeHandle(ref, () => ({
    makeProgressFull() {
      console.log("makeProgressFull")
      setProgressPrecentageState(0);
      document.documentElement.style.setProperty("--progressPrecentage", 0 + "%"); 
    }
  }));



  
  useEffect(() => {
    setProgressValues(currentStepState, maxStepState)
    setProgressColor()
  }, [setProgressValues, setProgressColor]);

  

  function setProgressValues() {
    setCurrentStepState(currentStep)
    
    if(currentStepState > maxStepState){
      setCurrentStepState(maxStepState)
    }
    if(currentStepState <=0){
      setCurrentStepState(0)
      setProgressPrecentageState(0);
    }else{
      setProgressPrecentageState((currentStepState + (showFirstStepEmpty ? -1 : ""))/maxStepState*100)
    }
    document.documentElement.style.setProperty("--progressPrecentage", progressPrecentageState + "%"); 
  }
  
  function setProgressColor() {
    if(progressBarColorState == "mySideColor"){
      setProgressBarColorState("progressBar__bar--progress__mySideColor");
      }
    else if (progressBarColorState == "partnerColor"){
      setProgressBarColorState("progressBar__bar--progress__partnerColor");
    } else {
      document.documentElement.style.setProperty("--progressBar--progress-color", progressBarColorState)
    }
    if(isDarkMode){
      document.documentElement.style.setProperty("--progressBar--empty-color", "#212121")
    }
  }

  
  return (
      <div className={"progressBar__container noselect"} >
        <div className={"progressBar  " + classes}>
            <div className="progressBar__bar">
              <div className={"progressBar__bar--progress " + progressBarColorState}></div>
            </div>
            <div className="progressBar__numbers" >{currentStepState + "/" + maxStepState}</div>
        </div>
      </div>
  )
})

export default ProgressBar;