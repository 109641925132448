import React , {useState, useEffect} from "react";
import VideoPlayer from "components/VideoPlayer/VideoPlayer";
import setPropertyVh from '../../utils/setPropertyVh';
import { useTranslation } from 'react-i18next';
import "./Teamwork.scss"
import ReactPlayer from 'react-player';
import Titleblock from "components/PageBlocks/TitleBlock";
import FooterStickyBlock from "components/PageBlocks/FooterStickyBlock";
import PageWrapperBlock from "components/PageBlocks/PageWrapperBlock";
import ContentWrapperBlock from "components/PageBlocks/ContentWrapperBlock";
import Instructor from "components/Instructor/Instructor";
import InfoCard from "components/InfoCard/InfoCard";
import PostitNote from "components/PostitNote/PostitNote";
import BulletsBlock from "components/PageBlocks/BulletsBlock";
import TeamworkSkillCard from "components/TeamworkSkillCard/TeamworkSkillCard";

const Teamwork = ({
    URL, 
    playRefAudio,
    pauseRefAudio,
    teamworkMusicRef,
    addEvent,
    onContinue,
    onBack, 
    CurrentSelectedStepItem,
    bullets,
    tips,
    AnimalType,
    instructorName,
    LessonDetails,
    }) => {

        console.log("LessonDetailsLessonDetails: ", LessonDetails)
    useEffect(() => {
        playRefAudio(teamworkMusicRef, true);
    }, []);
    
    const extractBulletTexts = (bullets) => {
        return bullets.map(bullet => bullet.text);
      };
    setPropertyVh();
    const {t} = useTranslation();
    const [CHEATS, setCHEATS] = useState(0)
    // playRefAudio(teamworkMusicRef, true)

    function handleContinueTeamwork () {
        addEvent("continue", "stepItem", "Teamwork", CurrentSelectedStepItem)
        onContinue()
    }

    function handleOnBack () {
        addEvent && addEvent("back", "stepItem", "Teamwork", CurrentSelectedStepItem);
        onBack()
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
        event.preventDefault();
          switch (event.key) {
            case ' ':
            case 'Enter':
              handleContinueTeamwork();
              break;
            case 'Delete':
            case 'Backspace':
              handleOnBack();
              break;
            default:
              break;
          }
        };
    
        window.addEventListener('keydown', handleKeyDown);
    
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
      }, []);
      return (
          <>
          <div className={"Onboarding-container"}>
            <div className={"Onboarding-content hideScrollbar"}>
                    <div className={"Onboarding-title"}>{t("general.letsCreate")}</div>
                    <div className={"Onboarding-subtitle"}>{t("general.practiceTeamworkSkill")}</div>
                    <div className={"teamwork-container"}>
                        <TeamworkSkillCard 
                            teamworkSkillImgName={("/assets/coins/coin_" + LessonDetails.teamworkSkillImgName + "_on.svg")}
                            teamworkSkillName={t(LessonDetails.teamworkSkillName)}
                            teamworkSkillTodo={t(LessonDetails.teamworkSkillTodo)}
                        />
                    <div className={"Onboarding-subtitle"}>{t("general.createByTheseSteps")}</div>
                        <div className={"info-cards"}>
                            {bullets && bullets.map((bullet, index) => (
                                <InfoCard 
                                    key={index}
                                    num={index+1}
                                    imgUrl={bullet.img}
                                    title={t(bullet.text)}
                                />
                            ))} 
                            {tips && <PostitNote 
                                children={
                                    <>
                                        <h1>{t("general.tips")}</h1>
                                        <BulletsBlock
                                            fontSize={20} 
                                            bullets={tips}
                                        />
                                    </>
                                }
                            />}         
                        </div>
                    </div>
                </div>
                <div className={"Onboarding-footer"}>
                    <button onClick={handleOnBack} className={"button Onboarding-button Onboarding-button-primary catbearsCTA-secondary "}>
                        {t("general.back")}
                    </button>          
                    <button onClick={handleContinueTeamwork} className={"button Onboarding-button Onboarding-button-secondary  catbearsCTA-primary Onboarding-button"}>
                        <img src="/assets/icons/icon_arrow-forward.svg" alt="forward arrow" /> {t("general.continue")} 
                    </button>
                </div>
            </div>
        </>
    )
}

export default Teamwork
