import React from 'react';
import Animal from '../../components/Animal/Animal';
import "./SideSelector.scss";
import * as ANIMALS from "../../components/Animal/AnimalAssets";

function SideSelector({ animalId, partnerAnimalId, mySide, partnerSide, selectedSide, onSideSelect }) {
  const animal = ANIMALS.default[animalId];
  const partnerAnimal = ANIMALS.default[partnerAnimalId];
  let mySideActive = '', partnerSideActive = '';
  if (selectedSide === 'mySide') {
    mySideActive = `selected`
  } else {
    partnerSideActive = `selected`
  }

  return (
    <div className="side-selector">
      <div className={`side-selector__pane ${mySideActive}`} onClick={() => onSideSelect(`mySide`)} >
        <Animal id={animalId} alt={mySide} />
        <span className="texture" style={{ background: animal.color }}></span>
        <div className="name">{mySide}</div>
      </div>

      <div className={`side-selector__pane ${partnerSideActive}`} onClick={() => onSideSelect('partnerSide')} >
        <Animal id={partnerAnimalId} alt={partnerSide} />
        <span className="texture" style={{ background: partnerAnimal.color }}></span>
        <div className="name">{partnerSide}</div>

      </div>
    </div>
  );
}

export default SideSelector;