import React, { useState, useEffect } from 'react';
import omitDeep from 'omit-deep-lodash';
import ReactDOM from 'react-dom';
import { createRelationship, updateRelationship } from '../../graphql/mutations';
import { getRelationship, apiListRelationships } from '../../graphql/queries';
import './NewRelationshipWizard.scss';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TopNavbar from '../../components/TopNavbar/TopNavbar';
import gql from 'graphql-tag';
import { graphql, useQuery } from 'react-apollo';
import Loading from '../../components/Loading/Loading';
import Step1 from './step_1';
import Step2 from './step_2';
import Step3 from './step_3';
import * as ANIMALS from '../../components/Animal/AnimalAssets';

function RelationshipWizard({ history, user, mutate, match }) {
  const { loading, error, data } = useQuery(gql(getRelationship), {
    variables: { id: match.params.id }
  });

  const { t } = useTranslation();
  // const { relationship } = data.getRelationship || { relationship: {} };
  const [state, setState] = useState({
    name: '',
    partnerName: '',
    gender: `male`,
    partnerGender: `male`,
    animalId: `duck`,
    partnerAnimalId: `fox`,
    relation: 'friend'
  });
  const [relationship, setRelationship] = useState({});

  const [currentStep, setCurrentStep] = useState(`STEP1`);

  function nextStepSubmit(e, currentStep) {
    if (e.currentTarget.form.checkValidity()) {
      setCurrentStep(currentStep);
      return e.preventDefault();
    }
  }
  useEffect(() => {
    if (!loading && match.params.id) {
      const { name, partnerName, gender, partnerGender, animalId, partnerAnimalId, relation } =
        data.getRelationship;
      setState({
        id: match.params.id,
        name,
        partnerName,
        gender,
        partnerGender,
        animalId,
        partnerAnimalId,
        relation
      });

      setRelationship({
        name,
        partnerName,
        gender,
        partnerGender,
        animalId,
        partnerAnimalId,
        relation
      });
    }
  }, [loading]);

  useEffect(() => {
    if (currentStep === 'SAVE') {
      let { owner, __typename, ...relationship } = state;
      console.log(relationship);
      mutate({ variables: { input: { ...omitDeep(relationship, `__typename`) } } }).then(
        (result) => {
          const queryKey = Object.keys(result.data)[0];
          const relationship = result.data[queryKey];

          ReactDOM.unstable_batchedUpdates(() => {
            setState({ ...relationship });
            console.log('new relationship ', relationship);
            setCurrentStep('FINISH');
          });
        }
      );
    }
  }, [mutate, currentStep]);

  let content;
  let pageStyle = {};
  switch (currentStep) {
    case 'STEP1':
      pageStyle.background = `${ANIMALS.default[state.animalId].color}`;
      content = (
        <Step1
          user={user}
          name={state.name}
          gender={state.gender}
          animalId={state.animalId}
          changeStep={(step, e) => nextStepSubmit(e, step)}
          changeName={(name) => setState({ ...state, name: name })}
          changeSex={(gender) => setState({ ...state, gender: gender })}
          changeAnimal={(animalId) => {
            setState({ ...state, animalId: animalId });
          }}
        />
      );
      break;
    case 'STEP2':
      pageStyle.background = `${ANIMALS.default[state.partnerAnimalId].color}`;
      content = (
        <Step2
          user={user}
          name={state.partnerName}
          gender={state.partnerGender}
          animalId={state.partnerAnimalId}
          changeStep={(step, e) => nextStepSubmit(e, step)}
          changeName={(name) => setState({ ...state, partnerName: name })}
          changeSex={(gender) => setState({ ...state, partnerGender: gender })}
          changeAnimal={(animalId) => {
            setState({ ...state, partnerAnimalId: animalId });
          }}
        />
      );
      break;
    case 'STEP3':
    case 'SAVE':
      pageStyle.background = `linear-gradient(110deg, ${
        ANIMALS.default[state.animalId].color
      } 50%, ${ANIMALS.default[state.partnerAnimalId].color} 50%)`;
      content = (
        <Step3
          user={user}
          name={state.name}
          partnerName={state.partnerName}
          animalId={state.animalId}
          partnerAnimalId={state.partnerAnimalId}
          relation={state.relation}
          gender={state.gender}
          mySideMirrors={relationship.mySideMirrors}
          partnerGender={state.partnerGender}
          changeStep={(step, e) => nextStepSubmit(e, step)}
          changeRelation={(relation) => setState({ ...state, relation: relation.value })}
        />
      );
      break;
    case 'FINISH':
      // return <Redirect to={`/relationship/${state.id}`} />
      // const history = useHistory();
      // history.push(`/relationship/${state.id}`), [history];

      // Google Analytics event trigger!
      // return <Redirect to={`/relationship/${state.id}`} />
      return (
        <Redirect
          to={{
            pathname: `/relationship/${state.id}`,
            state: { relationship }
          }}
        />
      );

    default:
      throw Error(`Wrong Step`);
  }
  const title = state.id
    ? t(`create_new_relationship.edit_title`)
    : t(`create_new_relationship.new_title`);
  if (loading) return <Loading />;
  return (
    <>
      {/* <div className="page-container"> */}
        {/* <TopNavbar border={false} title={title} user={user}></TopNavbar> */}
        {/* <TopNavbar border={false} title="" user={user}></TopNavbar> */}
        <form className="relationship-wizard-page pageTopPadding" style={pageStyle}>
          {content}
        </form>
      {/* </div> */}
    </>
  );
}

const NewRelationshipWizard = graphql(gql(createRelationship), {
  options: {
    refetchQueries: [{ query: gql(apiListRelationships) }],
    awaitRefetchQueries: true
  }
})(RelationshipWizard);
const EditRelationshipWizard = graphql(gql(updateRelationship), {
  // options: props =>({
  //   variables:{ id: props.match.params.id },
  //   refetchQueries: [{ query: gql(getRelationship) }],
  //   awaitRefetchQueries: true
  // })
})(RelationshipWizard);

export { NewRelationshipWizard, EditRelationshipWizard };
