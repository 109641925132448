import React, {useEffect, useState} from 'react';
import './RelationshipUserInfo.scss';
import {useTranslation} from 'react-i18next';
import Animal from '../../../components/Animal/Animal';
import {formatMillisecondsDate} from "../../../utils/general";
import ConflictSummary from "../../../components/ConflictSummary/ConflictSummary";
import {useLazyQuery} from "react-apollo";
import gql from "graphql-tag";
import {getRelationship} from "../../../graphql/queries";
import '../../../components/ConflictSummary/ConflictSummary.scss'

const RelationshipUserInfo = ({relationshipsData}) => {
  const {t} = useTranslation();

  const [selectedMirrorId, setSelectedMirrorId] = useState();
  const [selectedRelationshipId, setSelectedRelationshipId] = useState();
  const [selectedRelationshipData, setSelectedRelationshipData] = useState();

  const [fetchRelationship, {
    loading: relationshipLoading,
    error: relationshipError,
    data: relationshipData
  }] = useLazyQuery(gql(getRelationship), {
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    setSelectedMirrorId(null);
    setSelectedRelationshipId(null);
    setSelectedRelationshipData(null);
  }, [relationshipsData])

  useEffect(() => {
    if (selectedRelationshipId) {
      fetchRelationship({variables: {id: selectedRelationshipId}});
    }
  }, [selectedRelationshipId, fetchRelationship]);

  useEffect(() => {
    if (!relationshipLoading && !relationshipError && relationshipData) {
      setSelectedRelationshipData(relationshipData.getRelationship);
    }
  }, [relationshipLoading, relationshipError, relationshipData, setSelectedRelationshipData]);

  if (!relationshipsData) return null;

  const onConflictItemClick = async (relationshipId, mirrorId) => {
    if (relationshipId !== selectedRelationshipId) setSelectedRelationshipData(null);
    setSelectedRelationshipId(relationshipId);
    setSelectedMirrorId(mirrorId);
  }

  return (
    <>
      <div className="relationship-user-info-container">
        <div className="relationships-container">
          <h3 className="relationship-user-info__title">Relationships</h3>
          <div className="relationships-list">
            {(() => {
              // let relationshipsNum = relationshipsData.length;
              return relationshipsData.map((relationshipData, index) => (
                <div key={index} className="relationship-item">
                  <div className="relationship-item__title">
                    <div className="relationship-item__title--num">{index + 1}</div>
                    <p>Created at: {formatMillisecondsDate(relationshipData.createdAt)}</p>
                  </div>
                  <div className="relationship-item__title--text">{t(`homepage.meAndPartner`, {
                    name: relationshipData.name,
                    partnerName: relationshipData.partnerName
                  })}</div>
                  <div className="relationship_sides">
                    <div className="relationship_sides__side1">
                      <Animal className="relationship_sides__side1--animal relationship_sides--animal"
                              id={relationshipData.animalId} alt={`my animal`}/>
                      <div
                        className="relationship_sides__side1--name relationship_sides--name">{relationshipData.name}</div>
                    </div>
                    <div className="relationship_sides__side2">
                      <Animal className="relationship_sides__side2--animal relationship_sides--animal"
                              id={relationshipData.partnerAnimalId} alt={`partner animal`}/>
                      <div
                        className="relationship_sides__side2--name relationship_sides--name ">{relationshipData.partnerName}</div>
                    </div>
                  </div>


                  {/* <img src={relationshipData.image} alt="Relationship" className="relationship-image" /> */}
                  <ul className="conflict-list">
                    <p>Conflicts:</p>
                    {(() => {
                      // let conflictsNum = relationshipData.mySideMirrors.length;
                      return relationshipData.mySideMirrors.map((mirror, mirrorIndex) => (
                        <li
                          key={mirrorIndex}
                          className={`conflict-item ${mirror.id === selectedMirrorId ? 'selected' : ''}`}
                          onClick={() => {
                            onConflictItemClick(relationshipData.id, mirror.id);
                          }}
                        >
                          <div className="conflict-item__num">{mirrorIndex + 1}</div>
                          <div>{mirror.whatHappened}</div>
                          <div className="arrow-button">→</div>
                        </li>
                      ));
                    })()}
                  </ul>
                </div>
              ));
            })()}
          </div>
        </div>
        <div className="conflict-container">
          <h3 className="conflict__title">Conflict</h3>
          <ConflictSummary mirrorID={selectedMirrorId} relationship={selectedRelationshipData}/>
        </div>
      </div>
    </>
  );

};

export default RelationshipUserInfo;
