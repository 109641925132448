import React, {useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import './ExitPoll.scss';
import Q_RatingNumeric from '../Q_Elements/Q_RatingNumeric/Q_RatingNumeric';
import Q_ListMultiSelect from '../Q_Elements/Q_ListMultiSelect/Q_ListMultiSelect';
import Q_InputFeedback from '../Q_Elements/Q_InputFeedback/Q_InputFeedback';
import FullScreenStepper from "../FullScreenStepper/FullScreenStepper";
import {useMutation} from "react-apollo";
import gql from "graphql-tag";
import {updateUserExitPoll} from "../../graphql/mutations";
import UserContext from "../../pages/Login/UserContext";
import TextArea from "../../components/TextArea/TextArea";


function ExitPoll({ onFinish }) {

  const {t} = useTranslation();
  const [ratingValue, setRatingValue] = useState();
  const [reasonsValue, setReasonsValue] = useState();
  const [explanationValue, setExplanationValue] = useState();

  const {user, handleUserUpdate} = useContext(UserContext);

  
  const getExitPollObject = ({subscriptionLikelihood, reasons = [''], explanation = ''}) => {
    return {
      subscriptionLikelihood,
      reasons,
      explanation
    }
  }

  const [updateUser, {data, loading, error}] = useMutation(gql(updateUserExitPoll), {
    update(cache, {data: {setUserExitPoll}}) {
      if (!setUserExitPoll) {
        console.error('updateUser is undefined or null');
        return;
      }
      handleUserUpdate(setUserExitPoll);
    },
  });

  const formatReasonsToLabel = (reasonsValue) => {
    if (!reasonsValue || reasonsValue.length === 0) {
      return null;
    }
  
    const label = reasonsValue.length === 1 ? t('exitPoll.your-answer') : t('exitPoll.your-answers');
  
    let msg = (
      <>
        <p className="labelBelow__label">{label}</p>
        {Array.isArray(reasonsValue) && reasonsValue.map((reason, index) => (
          <p className="labelBelow__answer" key={index}>"{reason}"</p>
        ))}
      </>
    );
  
    return msg;
  };
  
  

  const stepsElements = [
    {
      // STEP1
      title: t('exitPoll.likelyhood-subscribe'),
      element: 
      <Q_RatingNumeric
        maxNum={5}
        textBelowMin={t("exitPoll.not-likely")}
        textBelowMax={t("exitPoll.very-likely")}
        onSelect={setRatingValue}
      />,
      onContinue: async () => {
        await updateUser({
          variables: {
            exitPoll: getExitPollObject({
              subscriptionLikelihood: ratingValue,
              reasons: reasonsValue,
              explanation: explanationValue
            })
          }
        })
        // alert("subscriptionLikelihood = " + ratingValue)
      }
      }, {
        // STEP2
        title: t('exitPoll.reasons'),
        subtitle: t('exitPoll.multiselect'),
        element: 
          <Q_ListMultiSelect
            isRandomizeAnswers={true}
            isOther={true}
            isMultiSelect={true}
            listArr={[
              t('exitPoll.reason-01__cant-afford'),
              t('exitPoll.reason-02__not-useful'),
              t('exitPoll.reason-03__still-thinking'),
              t('exitPoll.reason-04__not-try-child'),
              t('exitPoll.reason-05__too-pricy'),
              t('exitPoll.reason-06__has-alternative')
             ]}
            onSelect={setReasonsValue} // אני זוכר שאמרת לי לא לכתוב ככה אבל לא זוכר את הפיתרון שהצעת..
          />,
          onContinue: async () => {
            await updateUser({
              variables: {
                exitPoll: getExitPollObject({
                  subscriptionLikelihood: ratingValue,
                  reasons: reasonsValue,
                  explanation: explanationValue
                })
              }
            })
            // alert("reasons = " + reasonsValue)
          }
      }, {
        // STEP3
        title: t('exitPoll.explanation'),
        element: 
          <Q_InputFeedback labelBelow={formatReasonsToLabel(reasonsValue)} onInputChange={setExplanationValue}/>
        ,
        onContinue: async () => {
          await updateUser({
            variables: {
              exitPoll: getExitPollObject({
                subscriptionLikelihood: ratingValue,
                reasons: reasonsValue,
                explanation: explanationValue
              })
            }
          })
          // alert("explanation = " + explanationValue)
        }
      }, {
        // STEP4
        title: t('exitPoll.thankYou'),
        element: 
          <>
            <div className="thankyou-message">
              <img className="thankyou-message__img" src="/assets/stickers/foxlove.png "/>
              <div className="thankyou-message__text">{t('exitPoll.thankYou-message')}</div>
            </div>
          </>
      }
    ];


  return (

    <FullScreenStepper isLogo={true} stepsElements={stepsElements} onFinish={onFinish}/>
  )


}

export default ExitPoll;