import {useTranslation} from 'react-i18next';
import { useHistory } from 'react-router-dom';
import FullScreenStepper from 'components/FullScreenStepper/FullScreenStepper';
import Onboarding_Lesson_intro from './Onboarding_Lesson_intro';
import Onboarding_Environment from 'components/Onboarding/Onboarding_Environment';
import Onboarding_Materials from 'components/Onboarding/Onboarding_Materials';
import Onboarding_Print from 'components/Onboarding/Onboarding_Print';
// import Onboarding_Register from 'components/Onboarding/Onboarding_Register';

const LessonOnboarding = ({lessonImgURL, name,description,instructorName, AnimalType, stepRequirements, activityPagePDF, activityPageThumb, onFinish}) => {
  const {t, i18n} = useTranslation();
  const history = useHistory();

  return (
    <>
        <FullScreenStepper
            steps={[
              <Onboarding_Lesson_intro 
                lessonImgURL={lessonImgURL}
                name={name}
                description={description}
                instructorName={instructorName}
                AnimalType={AnimalType}
              />,
              <Onboarding_Environment/>,
              <Onboarding_Materials stepRequirements={stepRequirements}/>,
              <Onboarding_Print 
                activityPagePDF={activityPagePDF}
                activityPageThumb={activityPageThumb}
              />,
              // <Onboarding_Register/>,

            ]}
            onFinish={onFinish}
            onExit={() => console.log('Exit!')}
        />
    </>
  )
}


export default LessonOnboarding;



// function StepOne({ onContinue }) {
//   return (
//     <div>
//       <h1>Step 1 22</h1>
//       <button onClick={onContinue}>Continue</button>
//     </div>
//   );
// }