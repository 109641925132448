import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { mailFeedback as mailFeedbackMutation } from '../../graphql/mutations';
import Modal from '../Modal/Modal';

const FeedbackModal = ({ isFeedbackModalState, setIsFeedbackModalState }) => {
  const { t } = useTranslation();
  const [sendFeedback] = useMutation(gql(mailFeedbackMutation));
  const [feedbackInputState, setFeedbackInputState] = useState();
  // useEffect(()=>{
  // },[])

  return (
    <>
      <Modal
        CONTENT_title={t('general.sendFeedback')}
        CONTENT_p={
          <>
            <div className="">
              <div className="textarea-container">
                <label className={'form-label noselect '}></label>
                <textarea
                  className={'form-textarea'}
                  rows={3}
                  type={'text'}
                  name={'feedback'}
                  onChange={(event) => setFeedbackInputState(event.target.value)}
                  value={feedbackInputState}
                ></textarea>
                {/* <Button 
                      text={stateSaving ? "Saving..." : "Save" }
                      onClick={buttonHandleSave}
                      classes={"textAreaButton " + (stateSaving ? "buttonSaving" : "")}
                    /> */}
              </div>
            </div>
          </>
        }
        PRESET_Popup={true}
        imgBgColor={'var(--color-skyblue)'}
        PRESET_ImgFirst={true}
        CONTENT_img={'/assets/catbears-emojis/catbears_emoji_feedback.png'}
        imgWidth={'220px'}
        imgPaddingTop={'20px'}
        imgPaddingBottom={'20px'}
        isModalState={isFeedbackModalState}
        setIsModalState={setIsFeedbackModalState}
        btn1_action={() => sendFeedback({ variables: { feedback: feedbackInputState } })}
        btn1_text={t(`general.send`)}
        isBackground={true}
        isXbutton={true}
      />
    </>
  );
};

export default FeedbackModal;
