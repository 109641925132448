import React from 'react';
import {useTranslation} from 'react-i18next';
import './HappenedCard.scss';
import {getCardSides} from "../../utils/helpers";

function HappenedCard({
                        emoji,
                        modifier,
                        title,
                        name,
                        switchSides = false,
                        partnerName,
                        gender,
                        partnerGender,
                        classes,
                      }) {
  const {t} = useTranslation();
  const {sourceGender, targetName, sourceName} = getCardSides(
    {switchSides, name, partnerName, gender, partnerGender}
  );

  let noSides = false
  if(title == "I don't know" || title == "I don't want to share"){
    noSides = true
    }
  

  return (
    <>
      <div className={"card card--happened " + classes}>
        <div className="card__icon card__icon--happened"></div>
        <div className={"card__source-name" + " " + (noSides ? "displayNone" : "")}>{sourceName}</div>
        <div className="card__emoji">{emoji}</div>
        <div className="card__not--stroke">{modifier === `isReversed` && t(`card.not`)}</div>
        <div className="card__not">{modifier === `isReversed` && t(`card.not`)}</div>
        <div className="card__a-little--stroke">{modifier === `isReduced` && t(`card.alittle`)}</div>
        <div className="card__a-little">{modifier === `isReduced` && t(`card.alittle`)}</div>
        <div className="card__title">
          {t(`cards.HAPPENED.${title}.title`, {context: sourceGender})}
        </div>
        <div className="card__target-and-perfix">
          <div className="card__prefix">{t(`cards.HAPPENED.${title}.prefix`)}</div>
          <div>&nbsp;</div>
          <div className={"card__source-name" + " " + (noSides ? "displayNone" : "")}>{targetName}</div>
        </div>
      </div>
    </>
  );
}

export default HappenedCard;
