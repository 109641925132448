import React from 'react';
import './Room.scss';

function Room({
    classes
}){

   return (
    <div className={"Room " + (classes ? classes : "")}> 
        <div className="room-background"></div>
    </div>
   )
   
    
}

export default Room;