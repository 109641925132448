import React, { useContext, useEffect, useState, useRef } from 'react';
import CourseTheatreRecipe from "../../flows/CourseTheatreRecipe";
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {useMutation} from 'react-apollo';
import gql from 'graphql-tag';
import TopNavbar from 'components/TopNavbar/TopNavbar';
import Loading from '../../components/Loading/Loading';
import LessonListItem from 'components/LessonListItem/LessonListItem';
import LessonVideoItem from 'components/LessonVideoItem/LessonVideoItem';
import LessonStepItem from 'pages/Lesson/LessonStepItem';
import SideBarPanelNav from '../../components/SideBarPanelNav/SideBarPanelNav';
import SubscriptionModal from '../../components/SubscriptionModal/SubscriptionModal';
import UserContext from "../../pages/Login/UserContext";
import {updateUserProgress} from '../../graphql/mutations';
import { GlobalContext } from "context/GlobalContext";
import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
import FullscreenTakeover from 'components/FullscreenTakeover/FullscreenTakeover';
import LessonFeedback from 'pages/Lesson/LessonFeedback';
import {updateUser1stFeedbackComplete} from '../../graphql/mutations';
import ScrollBar from 'components/ScrollBar/ScrollBar';
import getLessonRequirementsArr from 'utils/getLessonRequirementsArr';
import TodoPopup from 'components/TodoPopup/TodoPopup';

import './Lessons.scss';
import {
  getProgressAsArr, 
} from "utils/courseFunctions"

function Lessons({user, authContext}) {
  
  const [CHEATS, setCHEATS] = useState(0)
  const [CHEATSprogress, setCHEATSprogress] = useState(user.userProgress)
  document.body.setAttribute("lang", authContext.user.locale);
  const PageRef = useRef(null);
  const [ShowTodo, setShowTodo] = useState(false)
  const [TodoPopupLessonNumber, setTodoPopupLessonNumber] = useState(1)

  const openLessonTodoPopup = (lessonNumber) => {
    setTodoPopupLessonNumber(lessonNumber)
    console.log("lessonNumber ", lessonNumber)
    setShowTodo(true)
  }

  function closeTodoPopup () {
    setShowTodo(false)
  }

  console.log("authContext ", authContext)
  const userID = user.id;
  const {t} = useTranslation();

  const thisCourseNumber = 0;
  const LessonsRecipe = CourseTheatreRecipe.lessons;

  const [isLoading, setIsLoading] = useState(true);
  const [UserProgress, setUserProgress] = useState([0, 0, 0, 0]);

  useEffect(() => {
    const fetchUserProgress = async () => {
      if (user) {
        // Check if userProgress exists, otherwise use an empty array
        const initialProgress = user.userProgress ? await getProgressAsArr(user.userProgress) : [0, 0, 0, 0];
        setUserProgress(initialProgress);
      }
      setIsLoading(false); // Set loading to false regardless of userProgress availability
    };
  
    fetchUserProgress();
  }, [user]);

  function consoleUser () {
    console.log("user " , user)
    console.log("localStorage.getItem('token') " , localStorage.getItem('token'))
    console.log("localStorage.getItem('userId') " , localStorage.getItem('userId'))
  
  }
  const {VolumeSwitchState, setVolumeSwitchState} = useContext(GlobalContext);

  const [pageBackgroundColor, setPageBackgroundColor] = useState("blue");

  useEffect(() => {
    if (user) {
      const initialProgress = user.userProgress || {};
      setUserProgress(getProgressAsArr(initialProgress));
      [coursesProgress, lessonsProgress, stepsProgress, stepItemsProgress] = (UserProgress || [0, 0, 0, 0]);
      // setTimeout(() => {
      //   console.log("time")
      //   setUserProgress(getProgressAsArr(initialProgress));
      //   [coursesProgress, lessonsProgress, stepsProgress, stepItemsProgress] = (UserProgress || [0, 0, 0, 0]);
      // }, 3500); // Delay of 500 milliseconds

      // Get the new background color and update the state
      const newColor = CourseTheatreRecipe.lessons[lessonsProgress]?.backgroundColor || "blue";
      setPageBackgroundColor(newColor);
    }
  }, [ user, setUserProgress, authContext, user.userProgress]);
  

  // console.log("UserProgress ", UserProgress)

let [coursesProgress, lessonsProgress, stepsProgress, stepItemsProgress] = (UserProgress || [0,0,0,0])

  // Array.isArray(user.userProgress) && user.userProgress.length > 0
  //     ? user.userProgress
  //     : {};
const [ShouldCelebrateLessonCompleted, setShouldCelebrateLessonCompleted] = useState(false);

  const getLessonState = (lessonIndex) => {
    //check if entire course is completed
    if(coursesProgress <= thisCourseNumber){
      //check lesson state
      if (lessonsProgress == lessonIndex) {
        return 'current';
      } else if (lessonsProgress > lessonIndex) {
        return 'completed';
      } else {
        return 'disabled';
      }
    } else {
      return 'completed';
    }    
};

const [is1stFeedbackON, setIs1stFeedbackON ] = useState(false)


useEffect(() => {
  console.log("authContext.user.is1stFeedbackComplete ", authContext.user.is1stFeedbackComplete)
  if(!authContext.user.is1stFeedbackComplete && (lessonsProgress === 2)){
    setIs1stFeedbackON(true)
  }
  // refetch();
}, [authContext.user.id, lessonsProgress])

// lessonFeedback - end

// **** START - HACK REFRESH PAGE TO FIX USERPROGRESS NOT LOADING
useEffect(() => {
  // Check if the page has already been reloaded this session
  const hasReloaded = sessionStorage.getItem('hasReloaded');
  console.log("hasReloaded ", hasReloaded)

  if (!hasReloaded) {
    // If not, set a flag in sessionStorage and reload the page
    sessionStorage.setItem('hasReloaded', 'true');
    setTimeout(() => {
      console.log("refreshed")
      window.location.reload();
    }, 100); // Reloads the page after 10ms
  }
}, []);
// **** END - HACK REFRESH PAGE TO FIX USERPROGRESS NOT LOADING


document.documentElement.style.setProperty("--page-background-color",pageBackgroundColor);

const { handleUserUpdate } = useContext(UserContext);

const [updateUser, { data, loading, error }] = useMutation(gql(updateUserProgress), {
  update(cache, { data: { setUserProgress } }) {
    if (!setUserProgress) {
      console.error('setUserProgress is undefined or null');
      return;
    }
    handleUserUpdate(setUserProgress);
  },
});

const UpdateUserProgressFunc = async (someProgress) => {
  try {
    await updateUser({ variables: { userProgress: someProgress } });
    console.log('User progress updated successfully.');
  } catch (error) {
    console.error('Error updating user progress:', error);
  }
};

const exampleProgress = {
  courses: [
    {
      name: "Theatre",
      completed: false,
      lessons: [
        {
          completed: true,
          steps: [
            {
              completed: true,
              stepItems: [
                { completed: true },
                { completed: false },
              ],
            },
            {
              completed: false,
              stepItems: [],
            },
          ],
        },
        {
          completed: false,
          steps: [
            {
              completed: false,
              stepItems: [],
            },
            {
              completed: false,
              stepItems: [],
            },
          ],
        },
        {
          completed: false,
          steps: [],
        },
        {
          completed: false,
          steps: [],
        },
      ],
    },
  ],
};

// const delay = (ms) => {
//   return new Promise((resolve) => setTimeout(resolve, ms));
// };

  
//   const [isHeLangState, setIsHeLangState] = useState(false);
const [videoPlayer, setVideoPlayer] = useState(false)
const [LessonVideoItemURL, setLessonVideoItemURL] = useState("")

const onExit = () => {
  setVideoPlayer(false)
}
const onOpenVideo = () => {
  setVideoPlayer(true)
}

const [updateUser1stFeedbackCompleteMutation] = useMutation(gql(updateUser1stFeedbackComplete), {
  update(cache, {data: {setUserIs1stFeedbackComplete}}) {
    handleUserUpdate(setUserIs1stFeedbackComplete);
  }
});


const finishFeedback = () => {
  updateUser1stFeedbackCompleteMutation({
    variables: {
      is1stFeedbackComplete: true
    }
  })

  setIs1stFeedbackON(false)
}

  return (
    <>
      {CHEATS ? 
        <>
        <div>
        </div>
        </>
      : ""
      }
      {is1stFeedbackON && 
        <FullscreenTakeover 
          children ={
            <LessonFeedback 
              onFinish={finishFeedback}
            />
          }
        />
      }
      {ShowTodo && 
        <TodoPopup 
          closeTodoPopup={closeTodoPopup}
          pathname={t(LessonsRecipe[TodoPopupLessonNumber].pathname)}
          title={t(LessonsRecipe[TodoPopupLessonNumber].name)}
          lessonRequirements = {getLessonRequirementsArr(LessonsRecipe[TodoPopupLessonNumber].steps)}
          activityPageThumb={LessonsRecipe[TodoPopupLessonNumber].activityPageThumb}
          activityPagePDF={LessonsRecipe[TodoPopupLessonNumber].activityPagePDF}
          userHasEmail={user.email ? user.email : null}
        />
      }
      {videoPlayer && 
          <div className="lessonLobby-container--video">
            <VideoPlayer
              URL={LessonVideoItemURL}
              onExit={onExit}
              onContinue={onExit}
              IsCurrentSelectedStepItemCompleted={true}
              volume={VolumeSwitchState}
              VideoPlayerLoop={false}
            />
        </div>
      }
      {!videoPlayer &&
      <>
      <TopNavbar
        showNavBG={true}
        showLogo={false}
        authContext={authContext}
        user={user}
        title={t(`course.theatre.courseName`)}
        // BackLinkTo={'/'}
        backgroundColor={pageBackgroundColor}
        border={true}
        opacity={'0.93'}
        texture={true}
        showMenuButton
        showLangButton
        VolumeSwitchState = {VolumeSwitchState} 
        setVolumeSwitchState = {setVolumeSwitchState}
      ></TopNavbar>
        {isLoading && <Loading />}
          <ScrollBar scrollContainerRef={PageRef} hideOnMobile/>
          <div ref={PageRef} className="course--container  course--container__theatre noselect texture">
              <div className="Lessons__list ">
                {LessonsRecipe.map((lessonRecipe, index) => (
                  <React.Fragment key={index}>
                    {lessonRecipe.type === "LessonListItem" && (
                      <LessonListItem 
                        key={index}
                        index={index}
                        pathname={lessonRecipe.pathname}
                        progressStatus={"completed"}
                        title={t(lessonRecipe.name)}
                        description={t(lessonRecipe.description)}
                        imgPath={lessonRecipe.imgPath}
                        instructor={lessonRecipe.instructor}
                        AnimalType={lessonRecipe.AnimalType}
                        instructorName={t(lessonRecipe.instructorName)}
                        state={getLessonState(index)}
                        isCurrent={lessonsProgress === index}
                        badge={lessonRecipe.badge}
                        size={lessonRecipe.size}
                        stepsCount={lessonRecipe.steps.length}
                        openLessonTodoPopup={openLessonTodoPopup}
                        lessonRequirements = {getLessonRequirementsArr(lessonRecipe.steps)}
                        activityPageThumb={lessonRecipe.activityPageThumb}
                        activityPagePDF={lessonRecipe.activityPagePDF}
                        // activityPagePath={lessonRecipe.activityPagePath}

                      />
                    )}
                    {lessonRecipe.type === "SingleStepLesson" && (
                      <LessonVideoItem 
                        key={index} 
                        coinName={lessonRecipe.coinName}
                        pathname={lessonRecipe.pathname}
                        setLessonVideoItemURL={setLessonVideoItemURL}
                        name={t(lessonRecipe.name)}
                        state={getLessonState(index)}
                        onClick={onOpenVideo}
                        videoUrl={lessonRecipe.url}
                        CurrentSelectedStep={lessonsProgress === index}
                        isCurrent={lessonsProgress === index}
                      />
                      // <LessonListItem 
                      //   key={index}
                      //   pathname={lessonRecipe.pathname}
                      //   progressStatus={"completed"}
                      //   title={t(lessonRecipe.name)}
                      //   description={t(lessonRecipe.description)}
                      //   activityPagePath={lessonRecipe.activityPagePath}
                      //   imgPath={lessonRecipe.imgPath}
                      //   instructor={lessonRecipe.instructor}
                      //   AnimalType={lessonRecipe.AnimalType}
                      //   instructorName={t(lessonRecipe.instructorName)}
                      //   state={getLessonState(index)}
                      //   isCurrent={lessonsProgress === index}
                      //   badge={lessonRecipe.badge}
                      //   size={lessonRecipe.size}
                      //   stepsCount={lessonRecipe.steps.length}
                      // />
                    )}
                    {/* {lessonRecipe.type === "videoTeamwork" && (
                      <LessonStepItem
                        key={index}
                        buttonShape={lessonRecipe.buttonShape ? lessonRecipe.buttonShape : "rectangle"}
                        name={t(lessonRecipe.name)}
                        size={lessonRecipe.size}
                        type={lessonRecipe.type}
                        stepState={getLessonState(index)}
                        CurrentSelectedStep={lessonsProgress === index}
                        AnimalName={lessonRecipe.AnimalName}
                        coinName={lessonRecipe.coinName}
                        StepNumberToShow={lessonRecipe.StepNumberToShow ? lessonRecipe.StepNumberToShow : null}
                        ImageToShow={lessonRecipe.ImageToShow ? lessonRecipe.ImageToShow : null}
                        stepsCount={lessonRecipe.steps.length}
                      />
                    )}
                    {lessonRecipe.type === "LessonVideoItem" && (
                      <LessonVideoItem 
                        key={index} 
                        setLessonVideoItemURL={setLessonVideoItemURL}
                        name={t(lessonRecipe.name)}
                        ImageToShow={lessonRecipe.ImageToShow ? lessonRecipe.ImageToShow : null}
                        state={getLessonState(index)}
                        onClick={onOpenVideo}
                        videoUrl={lessonRecipe.url}
                        CurrentSelectedStep={lessonsProgress === index}
                      />
                    )} */}
                            {/* <div onClick={consoleUser}>consoleUser</div> */}

                  </React.Fragment>
                ))}


              </div>
          </div>
      </>
      }
    </>
  );
}

export default Lessons;
