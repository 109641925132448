import React from 'react';
import "./Animal.scss";
import * as Animals from "./AnimalAssets";

function getImage(id, pixelRatio = ``) {
  if (pixelRatio) pixelRatio = `${pixelRatio}x`
  return Animals.default[id][`img${pixelRatio}`];
}

function Animal({ alt, id, className }) {
  if (!alt) alt = id
  return <img alt={alt} className={className}
    src={getImage(id)}
    srcSet={`${getImage(id, 2)} 2x, ${getImage(id, 3)} 3x`}
  />
}

export default Animal;