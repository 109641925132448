import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './CardEditor.scss';
import Card from '../../components/Card/Card';
import Button from '../../components/Button/Button';
import { RadioGroup, RadioButton } from 'react-radio-buttons';

function CardEditor({ card, onChange, onDelete, onClose, name, partnerName, gender, partnerGender }) {
  const [cardState, setCardState] = useState({ switchSides: false, ...card });
  const { t } = useTranslation();
  return (
    <>
      <Card {...cardState} gender={gender} partnerGender={partnerGender} name={name} partnerName={partnerName} classes={"cantTouchThis"}/>
      <div className="card-editor modal-buttons">
        <RadioGroup className="card-editor__card-modifier radio-3-horizontal" onChange={(modifier) => {
          const c = { card: { ...cardState, modifier } };
          onChange(c);
          setCardState(c.card);
        }} value={cardState.modifier} horizontal>
          <RadioButton value={`none`}>{t('map_relationship.edit-card.none')}</RadioButton>
          <RadioButton value={`isReversed`}>{t('map_relationship.edit-card.not')}</RadioButton>
          <RadioButton value={`isReduced`}>{t('map_relationship.edit-card.alittle')}</RadioButton>
        </RadioGroup>
        {cardState.cardType === 'HAPPENED' && <div className="button__switch"><Button text={t('map_relationship.edit-card.switch-names')} onClick={() => {
          const c = { card: { ...cardState, name: cardState.partnerName, partnerName: cardState.name, switchSides: !cardState.switchSides } };
          onChange(c)
          setCardState(c.card);
        }
        } /></div>}
        <div className="main-actions">
          {/* <div className="modal-buttons__delete"><Button text={t(`❌`)} onClick={() => onDelete({ card })} /></div> */}
          <div className="modal-button modal-button__delete" onClick={() => onDelete({ card })}></div>
          <div className="modal-button modal-button__success" onClick={() => onClose()}></div>
        </div>
      </div>
    </>
  )
}

export default CardEditor;