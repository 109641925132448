import React, { useState, useEffect } from 'react';
import Animal from '../../components/Animal/Animal';
import * as ANIMALS from '../../components/Animal/AnimalAssets';
import './AnimalsCarousel.scss';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css'


document.ontouchmove = function (event) {
  event.preventDefault();
};

let animalsArray = Object.keys(ANIMALS.default).map((key, i) => [
  key,
  ANIMALS.default[key]
]);

function AnimalsCarousel({ value, onChange }) {
  const [swiper, setSwiper] = useState(0);
  const animalIndex = animalsArray.findIndex(([key, id]) => key === value);
  const params = {
    activeSlideKey: animalIndex.toString(),
    slidesPerView: 7.4,
    grabCursor: true,
    breakpoints: {
      768: {
        slidesPerView: 7.4
      },
      300: {
        slidesPerView: 2
      }
    },
    spaceBetween: 0,
    centeredSlides: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },

    getSwiper: swiper => {
      setSwiper(swiper);
    }
  };

  useEffect(() => {
    if (!swiper) return;

    const onSlideChange = function (e) {
      onChange(animalsArray[this.activeIndex][0]);
    };

    swiper.on('slideChange', onSlideChange);

    return () => {
      swiper.off('slideChange', onSlideChange);
    };
  }, [onChange, swiper]);

  return (
    <Swiper {...params}>
      {animalsArray.map(([name], i) => (
        <Animal id={name} key={i} />
      ))}
    </Swiper>
  );
}

export default AnimalsCarousel;
