import React from 'react';
import "./Hiw.scss"
import SideBarPanelNav from '../../components/SideBarPanelNav/SideBarPanelNav';
import { useTranslation } from 'react-i18next';
import ContentTwoColumns from '../../components/ContentTwoColumns/ContentTwoColumns';

const Hiw = () => {
    const { t } = useTranslation();

	return(
        <>
        <SideBarPanelNav
            left={'backButton'}
            center={'oneLine'}
            centerTitle={'How to use Felt'}
            leftTo={`/`}
            fixed={true}
            />

        <div className="PageTwoColumns-container">
            
            <div className="PageTwoColumns-container__content">
                <ContentTwoColumns 
                    rightContentMaxWidth={"40"}
                    leftContent={
                        <>
                        <h2>The Felter</h2>
                        <p>
                            The Felter is the person that's in a conflict and using Felt to learn how to resolve it. 
                            <br/>
                            <br/>
                            A few things to keep in mind:
                            <ol>
                                <li>When using Felt it's very important that the Felter is in a safe space where he/she can do it in privacy.</li>
                                <li>Felt should not be done in front the person that the Felter is conflicted with.</li>
                                <li>When the Felter has finished reflecting his/her conflict, don't send or share the outcome with the person he/she is conflicted with.</li>
                            </ol>
                        </p>
                        </>
                        }
                    rightContnet={
                        <img src="/assets/hiw/hiw_felter.jpg "/>
                        }
                />
                {/* <ContentTwoColumns 
                    rightContentMaxWidth={"40"}
                    leftContent={
                        <>
                        <h2>The Felter</h2>
                        <p>
                            The person using Felt to resolve his/her own conflict is called the Felter.
                            <br/>
                            <br/>

                        </p>
                        </>
                        }
                    rightContnet={
                        <img src="/assets/hiw/hiw_felter.jpg "/>
                        }
                /> */}
                <ContentTwoColumns 
                    rightContentMaxWidth={"40"}
                    leftContent={
                        <>
                        <h2>The Helper</h2>
                        <p>
                            The Helper’s job is to listen, ask questions and help the Felter unfold their own story. 
                            If the Felter is below 16 it's advised that a Helper will be present with him/her while using Felt.
                            <br/>
                            <br/>
                            A Helper is a person whom the Felter trusts and feel comfortable with such as a parent, a teacher, a good friend, a grandparent, a babysitter...
                            <br/>
                            <br/>
                            Helpers keep in mind:
                            <ol>
                                <li>The Felter might say things you’re not aware of, and this can bring up strong emotions in you. 
                                    If this happens remember - Be careful not to judge about what’s wrong or what’s right. Make the Felter feel comfortable and able to say whatever comes to his/her mind. </li>
                                <li>Your job is to listen, ask questions and help the Felter unfold his/her own story in his/her own words. <strong> It’s not about you, it’s about them.</strong></li>
                                <li>A person that the Felter is conflicted with should not be present when using Felt. Especially if this person is you.</li>
                            </ol>
                        </p>
                        </>
                        }
                    rightContnet=
                        {
                             <img src="/assets/hiw/hiw_helper.jpg "/>
                        }
                />
            </div>
            <img className="sticker_s" src="/assets/stickers/effectionate4.png"/>

		</div>
        </>
	)
}

export default Hiw;