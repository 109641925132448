import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button/Button';
import Animal from '../../components/Animal/Animal';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import TopNavbar from '../../components/TopNavbar/TopNavbar';
 
function Step3({ 
  changeStep, 
  partnerName, 
  partnerGender, 
  animalId, 
  partnerAnimalId, 
  relation, 
  changeRelation, 
  gender, 
  user 
}) {
  const { t } = useTranslation();
  return (
    <>
      <div className="step-container texture">
        {/* <TopNavbar 
          user={user}
          BackOnClick={(e) => changeStep(`STEP2`, e)}
          >
        </TopNavbar> */}
        {/* <div className="upper"> */}
        <Button isBackButton={true} onClick={(e) => changeStep(`STEP2`, e)} />
        <div className="title">
          <h1>{t(`create_new_relationship.step3.title`)}</h1>
        </div>
        
        <div className="details">
          <div className="dropdown-container">
            <span>{t(`create_new_relationship.step3.partner_is`, { partnerName, context: partnerGender })}</span>
            <Dropdown options={t(`create_new_relationship.step3.relationOptions`, { returnObjects: true })} onChange={changeRelation}
              value={relation ? relation : t(`create_new_relationship.step3.relationOptions`, { returnObjects: true })[0]} />
          </div>
        </div>
        <div className="step-3-animals">
          <Animal id={animalId} />
          <Animal id={partnerAnimalId} className={'img-hor-vert'} />            
        </div>
        <Button classes="step-3-animals__button " color={`#489de5`} backgroundColor={`white`} text={t(`create_new_relationship.step3.next_button`, { context: gender })} onClick={(e) => changeStep('SAVE', e)} />
      </div>
    </>
  );
}

export default Step3;