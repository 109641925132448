import React, { useState, useEffect } from 'react';
import "./ProgressDots.scss";
import ProgressDot from "./ProgressDot"
// import StarExplode from "../StarExplode/SterExplode"


function ProgressDots({selectedDot, progressDotsColor, classes, dotsArray }) {
 
  const[maxStepState, setMaxStepState] = useState(dotsArray.length);
  const[progressDotsColorState, setProgressDotsColorState] = useState(progressDotsColor);

  useEffect(() => {
  }, [dotsArray]);

  return (
    <>
      {/* <StarExplode /> */}
      <div className={"progressDots__container noselect"} >
          {
            dotsArray.length > 1 ? 
            dotsArray.map(
              ((step, stepIdx) =>{
              return <ProgressDot 
                key={stepIdx}
                isCompleted={step.isCompleted}
                isSelected={+selectedDot === +stepIdx ? true : false}
              />
            }))
            : ''
          }
      </div>
    </>
  )
}

export default ProgressDots;