import React from 'react';
import './Hills.scss';

function Hills({}){

   return (
       
    <div className="hills"> 
        <div className="ground imgElement"></div>
        <div className="hills_bgs">
            <div className="hills_bg_l imgElement"></div>
            <div className="hills_bg_r imgElement"></div>
        </div>
        <div className="hills_bushs">
            <div className="hills_bush-l imgElement"></div>
            {/* <div className="hills_bush-c"></div> */}
            <div className="hills_bush-r imgElement"></div>
        </div>
        <div className="hills_clouds">
            <div className="hills_c1 hills_c imgElement "></div>
            <div className="hills_c2 hills_c imgElement "></div>
            <div className="hills_c3 hills_c imgElement "></div>
            <div className="hills_c4 hills_c imgElement "></div>
            <div className="hills_c5 hills_c imgElement "></div>
            
        </div>
    </div>
   )
   
    
}

export default Hills;