import React, { useState } from 'react';
import './TeamworkSkillCard.scss';

const TeamworkSkillCard = ({ teamworkSkillImgName, teamworkSkillName, teamworkSkillTodo }) => {
    return (<>
            <div className="teamworkSkillCard">
                    <div className="teamworkSkillCard_start">
                        <div className="teamworkSkillCard_coin">
                            <img src={teamworkSkillImgName} alt={teamworkSkillName} />
                        </div>
                    </div>
                    <div className="teamworkSkillCard_end">
                        <div className="teamworkSkillCard_name">{teamworkSkillName}</div>
                        <div className="teamworkSkillCard_todo">{teamworkSkillTodo}</div>
                    </div>
                </div>
        </>)
}

export default TeamworkSkillCard;