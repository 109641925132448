// import React from 'react';

// this function will help us with mobile devices which 100vh isn't constant due to top and bottom browser bars
window.addEventListener('resize', setPropertyVh);

function setPropertyVh(){
  var vh;
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

export default setPropertyVh