import React, {useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation} from "react-apollo";
import gql from "graphql-tag";
import {login} from "../../graphql/mutations";
import Register from "./Register";
import Modal from "../../components/Modal/Modal";
import UserContext from "./UserContext";
import RestorePassword from "./RestorePassword";
import Catbears_Login from './Catbears_Login';
import Catbears_Register from './Catbears_Register';
import { useHistory } from 'react-router-dom';
import LogoCatbears from 'components/LogoCatbears/LogoCatbears';
import "./LoginPage.scss"

const LoginToCatbears = ({onLogin, onClose, shouldAllowSwitchToRegister, getIsRegisterMode=false}) => {
  const {user, handleUserUpdate, saveUserToLocalStorage, setAuthData} = useContext(UserContext)
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isRegisterMode, setIsRegisterMode] = useState(getIsRegisterMode);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [shouldDisplayLogin, setshouldDisplayLogin] = useState(true);
  const history = useHistory();
  const [isLogin, setIsLogin] = useState(false);

  const {t} = useTranslation();

  const [loginMutation, {loading}] = useMutation(gql(login))
  
  const toggleDisplayLogin = () => {
    setshouldDisplayLogin(!shouldDisplayLogin)
  } 

  const onContinue = () => {
    history.push('/');
  }

  const loginFunc = () => {
    user.isGuest && setIsLogin(true)
    history.push('/');
  }

  function openResetPassword() {
    setIsResetPassword(true)
  }
  return (
    <>
      <LogoCatbears isCentered isTapToLandingPage/>
      <div className={"Onboarding-content Onboarding-container-no-footer login-page-container hideScrollbar"}>
            {isResetPassword &&
              <RestorePassword onSwitchToLogin={() => setIsResetPassword(false)} initialEmail={email}/>
            }
            { shouldDisplayLogin && !isResetPassword && 
              <Catbears_Login 
                onLogin={loginFunc} 
                onClose={"onCloseLogin"} 
                toggleDisplayLogin={toggleDisplayLogin}
                openResetPassword={openResetPassword}
            />
            }
            {!shouldDisplayLogin && !isResetPassword &&
              <Catbears_Register
                onSignUp={onContinue}
                // isSkippable={true}
                onSkip={onContinue}
                dontShowTitle={true}
                hasAccout={loginFunc}
                toggleDisplayLogin={toggleDisplayLogin}
                // isBright={true}
            />
            
            }
        <div className='Room'></div>
      </div>

    
      {/* {isRegisterMode && <Register 
        onSwitchToLogin={() => setIsRegisterMode(false)} 
        shouldAllowSwitchToLogin={true}
      />}
      {isResetPassword && <RestorePassword 
        onSwitchToLogin={() => setIsResetPassword(false)} 
        initialEmail={email}
        />}
      <h2 className="modal__title">{t("loginFlow.logIn")}</h2>
      <form onSubmit={_onLogin} className="modal__form">
        <div className={"modal__form--error-msg " + (error ? "" : " displayNone ")}>
          {error}
        </div>
        <div>
          <label className={"form-label noselect "}>
            {t("loginFlow.email")}
          </label>
          <input type="email"
                 className="input input-authMenu"
                 value={email}
                 onChange={e => setEmail(e.target.value)}/>
        </div>
        <div>
          <label className={"form-label noselect "}>
            {t("loginFlow.password")}
          </label>
          <input type="Password" className="input input-authMenu" value={password}
                 onChange={e => setPassword(e.target.value)}/>
        </div>
        <button className={"modal__form--sumbit-button " + (loading ? "preloaderGif " : " ")}
            type="submit">{loading ? "" : t("loginFlow.logInBtn")}
        </button> 
      </form>
      <div className="modal__below-submit ">
        {shouldAllowSwitchToRegister && <div className="createAccount-container">
          <p>{t("loginFlow.FirstTimeHere") + " "}
            <a className="modal__below-submit__btn modal__below-submit__btn--create-account"
               onClick={() => setIsRegisterMode(true)}>
              {t("loginFlow.createAnAccount")}
            </a>
          </p>
        </div>}
        <a className="noselect link_no-button register_mini-text register_login-link " onClick={() => setIsResetPassword(true)}>
          {t("loginFlow.forgotPassword")}
        </a>
        <div className="noselect modal__below-submit--micro-text ">{t("loginFlow.byLogging") + " "}
          <a className="noselect link_no-button register_mini-text register_login-link ">{t("loginFlow.privacyPolicy")}</a>
          {" " + t("loginFlow.and")}
          <a className="noselect link_no-button register_mini-text register_login-link ">{t("loginFlow.TermsOfService")}</a>
          {" " + t("loginFlow.endOfSentence")}.
        </div>
      </div> */}
    </>
  )
}


export default LoginToCatbears;




// import React, {useContext, useEffect, useState} from 'react';
// import { useTranslation } from 'react-i18next';
// import setPropertyVh from '../../utils/setPropertyVh';
// import Instructor from '../../components/Instructor/Instructor';
// import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
// import './Onboarding_Register.scss'
// import Catbears_Register from 'pages/Login/Catbears_Register';
// import UserContext from "../../pages/Login/UserContext";
// import Catbears_Login from "../../pages/Login/Catbears_Login";

// const Onboarding_Register = ({
//             // onExit,
//             onBack,
//             onContinue,            
//     }) => {
//     const {t} = useTranslation();
//     setPropertyVh();

//     const loginFunc = () => {user.isGuest && setIsLogin(true)}
//     const {user, handleUserUpdate} = useContext(UserContext);
//     const [isLogin, setIsLogin] = useState(false);
//     const [shouldDisplayLogin, setshouldDisplayLogin] = useState(false); // no

//     const toggleDisplayLogin = () => {
//         setshouldDisplayLogin(!shouldDisplayLogin)
//     }
//     return (
//         <>
//         {/* <Xbutton fixed={true} onClose={onXbutton ? onXbutton : undefined}/> */}
//         <div className={"Onboarding-content Onboarding-container-no-footer"}>
//                     {/* <div className={"Onboarding-title"}>{t("loginFlow.createAnAccount")}</div>
//                     <div className={"Onboarding-subtitle"}>{t("loginFlow.progress-saved")}</div> */}
//                        {shouldDisplayLogin ? 
//                         <Catbears_Login 
//                             onLogin={"onCloseLogin"} 
//                             onClose={"onCloseLogin"} 
//                             shouldAllowSwitchToRegister={false}
//                             toggleDisplayLogin={toggleDisplayLogin}
//                         />
//                         :
//                         <Catbears_Register
//                             shouldDisplayLogin={false}
//                             onSignUp={onContinue}
//                             isSkippable={true}
//                             onSkip={onContinue}
//                             dontShowTitle={true}
//                             hasAccout={loginFunc}
//                             toggleDisplayLogin={toggleDisplayLogin}
//                             // isBright={true}
//                         />
                        
//                         }
//                     <div className='Room'></div>
//                 </div>
//             {/* <div className={"Onboarding-footer"}>
//                 <button onClick={""} className={"button Onboarding-button Onboarding-button-secondary  catbearsCTA-primary Onboarding-button"}>
//                     {t("general.watchVideo")}
//                 </button>          
//                 <button onClick={onContinue} className={"button Onboarding-button Onboarding-button-primary catbearsCTA-secondary"}>
//                     {t("general.watchedAndContinue")}
//                 </button>
//             </div> */}
//         </>
//         )

// }

// export default Onboarding_Register