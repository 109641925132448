import React from 'react';
import './FeltLogo.scss';

function FeltLogo({color, size, classes, symbol}){

   return (

      <>
      <div className={"FeltLogo__symbol--container " + classes}>
         {symbol ? <img className="FeltLogo__symbol" src="/assets/logo/felt logo.png" alt="Felt Logo" />: ""}
         <div className={"top-navbar--logo "  + " "  + " " + size + "-logo " + " color-" + color}>FELT</div>
      </div>
      </>
   )


}

export default FeltLogo;