import React, {useState} from 'react';
import "./AuthMenu.scss"
import FeltLogo from "../../components/FeltLogo/FeltLogo";
import * as ANIMALS from "../../components/Animal/AnimalAssets";
import Animal from '../../components/Animal/Animal';
import Backgrounds from '../../components/Backgrounds/Backgrounds';
import {useTranslation} from 'react-i18next';

import AnimalsForground from "../../components/AnimalsForground/AnimalsForground";

const vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

const AuthMenu = ({children}) => {
	const [email, setEmail] = useState("");
	const {t} = useTranslation();

	var side1;
	side1 = ANIMALS.default["fox"];

	return(
		<>
		
		<div className="authScreen-container">
			<Backgrounds
					name={'Room'}
				/>
				{/* <div className="authScreen-main">
					<div className="authScreen_hero">
						<div className="authScreen_hero__txt">
							<FeltLogo 
								color = "white"
								classes = "feltLogo"
								symbol= {true}
							/>
							<h1>{t(`loginFlow.welcomeMessage`)}</h1>
							<h3>{t(`loginFlow.hero-p`)}</h3>
						</div>
						<div className="authScreen_hero__animals">
							<div className="authScreen_hero__animals--side1">
								<div className="authScreen_hero__animals--side1--img">
									<Animal id={"fox"} alt={`my animal`} />
								</div>
								<p>{t(`loginFlow.side1-name`)}</p>
							</div>
							<div className="authScreen_hero__animals--side2">
								<div className="authScreen_hero__animals--side2--img">
									<Animal id={"duck"} alt={`my animal`} />
								</div>
								<p>{t(`loginFlow.side2-name`)}</p>
							</div>
						</div>
					</div>
					<div className="authScreen_form">
					
					{children}
					</div>
				</div> */}
			{/* <div className="authScreen-footer">Copyright © 2023 Felt by the Catbears. Created by Maayan Malka. All rights reserved</div> */}
		</div>
		</>
	)
}

export default AuthMenu;