import React, {useState} from 'react';
// import './Intro.scss';
import EmojiToImage from "../../utils/EmojiToImage"

function Intro({insideCircle, side , title, subtitle, description, link, linkTxt, p1, p2, p3}) {

  // "Intro-empty"
    return (
    <div className="stepItem_intro-container noselect">
        
        {insideCircle ? 
        <>
          <div className={"stepItem_intro-img"}>
            {EmojiToImage(insideCircle, false)}
            {/* <img src={"/assets/catbears-emojis/catbears_emoji_" + el.icon + ".png"}/> */}
            <div className={"img_circle " + (side == "mySide" ? "img_circle_mySideColor" : "img_circle_partnerColor")}></div>
          </div>
        </> : ""}
        <h1 className="form-label_center">{title}</h1>
        {subtitle ? <h2>{subtitle}</h2> : ""}
        {p1 ? <p className="micro-text text-color_black text-align_left">{p1}</p> : ""}
        {p2 ? <p className="micro-text text-color_black text-align_left">{p2}</p> : ""}
        {p3 ? <p className="micro-text text-color_black text-align_left">{p3}</p> : ""}
        {description ? <p>{description}</p> : ""}
        {link ? <a className="link_button" href={link}>{linkTxt}</a> : ""}
    </div>
    )
}

export default Intro;
 