import React from 'react';
import {map} from 'lodash';
import './TableUserInfo.scss';
import {formatMillisecondsDate} from "../../../utils/general";

const TableUserInfo = ({data}) => {

  if (!(data) || data === {}) {
    return null;
  }

  const handleDownload = () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });

    const fileName = `felt_${data.email}_${formattedDate}.csv`;

    // Generate CSV content
    const csvContent = generateCSVContent();

    // Create a temporary anchor element
    const anchor = document.createElement('a');
    anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent);
    anchor.download = fileName;
    anchor.style.display = 'none';

    // Append the anchor element to the document body
    document.body.appendChild(anchor);

    // Trigger the click event on the anchor element
    anchor.click();

    // Remove the anchor element from the document body
    document.body.removeChild(anchor);
  };

  const generateCSVContent = () => {
    // Header row
    const csvData = data.userByEmail;
    const headerRow = Object.keys(csvData).join(',');

    // Data rows
    const dataRow = Object.values(csvData).join(',');

    // Combine header row and data row
    const csvContent = [headerRow, dataRow].join('\n');

    return csvContent;
  };

  return (
    <>
      <div className="TableUserInfo-container">
        <table>
          <thead>
          <tr>
            <th>Email</th>
            <th>ID</th>
            <th>LANG</th>
            <th>User Type</th>
            <th>Saw Paywall</th>
            <th>Passed Fakedoor</th>
            <th>Subscribe Likelihood</th>
            <th>Reasons not subscribed</th>
            <th>Explanation not subscribed</th>
            <th>Sessions Num</th>
            <th>Joined Date</th>
            <th>Last Seen</th>
            <th>Device</th>
            <th>IP</th>
          </tr>
          </thead>
          <tbody>
          {map(data, ((item) => {
            const {exitPoll = {}} = item;
            return (
              <tr key={item.id}>
                <td>{item.email}</td>
                <td>{item.id}</td>
                <td>{item.locale}</td>
                <td>{item.type}</td>
                <td className={"tableBox_bool_" + !!item.exitPoll}>{item.exitPoll ? 'Yes' : 'No'}</td>
                <td className={"tableBox_bool_" + item.isSubscribed}>{item.isSubscribed ? 'Yes' : 'No'}</td>
                <td>{exitPoll?.subscriptionLikelihood}</td>
                <td className="tableBox_M">{exitPoll?.reasons}</td>
                <td className="tableBox_L">{exitPoll?.explanation}</td>
                <td>{item.sessionsNum}</td>
                <td className="tableBox_S">{formatMillisecondsDate(item.createdAt)}</td>
                <td className="tableBox_S">{formatMillisecondsDate(item.updatedAt)}</td>
                <td>{item.device}</td>
                <td>{item.ip}</td>
              </tr>)
          }))}
          </tbody>
        </table>
      </div>
      {/* <div className="TableUserInfo-button userinfo_button userinfo_button__s " onClick={handleDownload}>
        ↓ Download CSV
      </div> */}
    </>
  );
};

export default TableUserInfo;