import React, { useRef, useState, useEffect } from 'react';
import './ScrollBar.scss';

const ScrollBar = ({ scrollContainerRef, hideOnMobile }) => {
  const containerRef = useRef(null);
  const knobRef = useRef(null);
  const [knobPosition, setKnobPosition] = useState(0);
  const [maxScrollWidth, setMaxScrollWidth] = useState(0);
  const [isRTL, setIsRTL] = useState(false);

  useEffect(() => {
    if (scrollContainerRef?.current) {
      setMaxScrollWidth(scrollContainerRef.current.scrollWidth - scrollContainerRef.current.clientWidth);
      setIsRTL(document.body.getAttribute("lang") === "he");

      setKnobPosition(isRTL ? containerRef.current.clientWidth - knobRef.current.offsetWidth : 0);
      scrollContainerRef.current.scrollLeft = isRTL ? maxScrollWidth : 0;
    }
  }, [scrollContainerRef, maxScrollWidth, isRTL]);

  const handleDrag = (clientX) => {
    const container = containerRef.current;
    const knob = knobRef.current;
    const containerRect = container.getBoundingClientRect();
    
    let newKnobPosition = clientX - containerRect.left - knob.offsetWidth / 2;
    newKnobPosition = Math.max(0, Math.min(newKnobPosition, containerRect.width - knob.offsetWidth));
    setKnobPosition(newKnobPosition);

    if (scrollContainerRef?.current) {
      const scrollRatio = newKnobPosition / (containerRect.width - knob.offsetWidth);
      const targetScroll = scrollRatio * maxScrollWidth;

      if (isRTL) {
        const rtlAdjustedScroll = maxScrollWidth - targetScroll;
        scrollContainerRef.current.scrollLeft = rtlAdjustedScroll * -1;
      } else {
        scrollContainerRef.current.scrollLeft = targetScroll;
      }
    }
  };

  const handleMouseDown = (e) => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    handleDrag(e.clientX);
  };

  const handleMouseMove = (e) => {
    handleDrag(e.clientX);
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  const handleTouchStart = (e) => {
    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchEnd);
    handleDrag(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    handleDrag(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    document.removeEventListener('touchmove', handleTouchMove);
    document.removeEventListener('touchend', handleTouchEnd);
  };

  return (
    <div className={"scrollBar-container noselect " + (hideOnMobile && "scrollBar-hide-mobile")} ref={containerRef}>
      <div
        className="scrollBar-knob noselect"
        ref={knobRef}
        style={{ left: `${knobPosition}px` }}
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart} // Add touch event
      >
        <img className="noselect" src="assets/icons/icon-drag.svg" alt="icon drag" draggable="false" />
      </div>
      <div className="scrollBar"></div>
    </div>
  );
};

export default ScrollBar;
