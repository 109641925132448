
import React from 'react';
import './BreathingShape.scss';

function BreathingShape() {
  return (
    <div className="organic"></div>
  )
}

export default BreathingShape;

 




