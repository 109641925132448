import React from 'react';
import './Backgrounds.scss';
import Hills from "./Hills/Hills";
import Room from "./Room/Room";

function Backgrounds({name, classes}){
    if(name== 'Hills'){
        return (
            <Hills /> 
        )
    }
    if(name== 'Room'){
        return (
            <Room 
                classes = {classes}
            />
        )
    }
}

export default Backgrounds;