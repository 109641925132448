import React, { useState } from "react";
import "./PostitNote.scss";

const PostitNote = ({ children }) => {
    // Generate random values for each instance of PostitNote
    const [randomColor] = useState(Math.floor(Math.random() * 7) + 1);
    const [randomAngle] = useState(Math.floor(Math.random() * 7) + 1);

    return (
        <div className={"postit-note-container"}>
            <div className={["postit-note", `postit-note_color_${randomColor}`, `postit-note_angle_${randomAngle}`].join(" ")}>
                {children}
            </div>
        </div>
    );
}

export default PostitNote;
