import React, {useState,useEffect, useRef} from 'react';
import StepGame from './StepGame'
import {characters, objects} from './Characters.js'
import ProgressBar from 'components/ProgressBar/ProgressBar';
import './GameCount01.scss'


const GameCount01 = () => {
    // count game consoleEverything
    const [isKeysOpen, setIsKeysOpen] = useState(false)
    const [stepNumState, setStepNumState] = useState(1)
    const maxSteps = 10;
    const maxTries = 4;
    const minCount = 1;
    const maxCount = 9;
    const scoreUnit = (100 / maxSteps / maxTries)
    const [showBarFull, setShowBarFull] = useState(false)
    const [stepTries, setStepTries] = useState(maxTries)
    const [gameScore, setGameScore] = useState(0)
    const [stars, setStars] = useState(Math.round(gameScore / (100 / 6)))
    const [characterState, setCharacterState] = useState();
    const [objectState, setObjectState] = useState();
    const [randomNumObjectsState, setRandomNumObjectsState] = useState();
    const [endScreen, setEndScreen] = useState(false)
    const [showEndButtons, setShowEndButtons] = useState(false)
    const [star01imgState, setStar01imgState] = useState("/assets/questgame/star_001_01.png")
    const [star02imgState, setStar02imgState] = useState("/assets/questgame/star_002_01.png")
    const [star03imgState, setStar03imgState] = useState("/assets/questgame/star_003_01.png")
    
    const progressBarRef = useRef();
    const stepGameRef = useRef();

    


    const getRandomCharacter =() => {
        let randomChar = characters[Math.floor(Math.random()*characters.length)]
        new Audio(randomChar.sfx).play()
        return randomChar
    }
    const getRandomObject =() => {
        let randomObj = objects[Math.floor(Math.random()*objects.length)]
        return randomObj
    }
    const getRandomNumObjects =(min, max) => {
        let numObjs = Math.floor(Math.random() * (max - min + 1) ) + min;
        return numObjs
    }

    const updateScore = () => {
        let stepScore = scoreUnit * stepTries
        setGameScore(gameScore + stepScore)
        setStars(Math.round(gameScore / (100 / 6)))
    }

    const killOneStepTry = () => {
        if(stepTries > 0){
            setStepTries(stepTries -1) 
        }
        else {
            setStepTries(0)
        }   
    }
    
    const stepComplete = () => {
        updateScore()
        setStars(Math.round(gameScore / (100 / 6)))
        consoleEverything()

        if(stepNumState >= maxSteps){
            //End of questGame
            setShowBarFull(true)
            progressBarRef.current.makeProgressFull()
        }else {
            //Move to next step
            setStepNumState(stepNumState + 1)
            generateNewStep()
        }
    }

    const gameEndScreen = () => {
        setEndScreen(true)
        new Audio("/assets/questgame/tututuall.mp3").play()
        setTimeout(() => {
           starAnimation();
        },800)
        setTimeout(() => {
            setShowEndButtons(true)
        },1200)
        
    }  


    const generateNewStep = () => {
        setCharacterState(getRandomCharacter())
        setObjectState(getRandomObject())
        setRandomNumObjectsState(getRandomNumObjects(minCount,maxCount))
        setStepTries(maxTries)
    }

    const newGame = () => {
        setGameScore(0)
        setStars(0)
        setStepNumState(1)
        setStar01imgState("/assets/questgame/star_001_01.png")
        setStar02imgState("/assets/questgame/star_002_01.png")
        setStar03imgState("/assets/questgame/star_003_01.png")
        setShowBarFull(false)
        setEndScreen(false)
        setShowEndButtons(false)
        generateNewStep()
        stepGameRef.current.newStep()
    }

    const calcStars = () => {
        setStars(Math.round(gameScore / (100 / 6)))
    }
    const starAnimation = () => {
        new Audio('/assets/questgame/papapa.mp3').play()

        setTimeout(() => {
            if(stars >= 2){
                setStar01imgState("/assets/questgame/star_001_03.png")
            }
            else if(stars >= 1){
                setStar01imgState("/assets/questgame/star_001_02.png")
            }
            
            
        },10)

        setTimeout(() => {
            if(stars > 4){
                setStar02imgState("/assets/questgame/star_002_03.png")
            }
            else if(stars >= 3){
                setStar02imgState("/assets/questgame/star_002_02.png")
            }
            
            
        },500)
        
        setTimeout(() => {
            if(stars >= 6){
                setStar03imgState("/assets/questgame/star_003_03.png")
            }
            else if(stars >= 5){
                setStar03imgState("/assets/questgame/star_003_02.png")
            }
            
        },800)
        
    }

    const consoleEverything = () => {
        console.log("1) stepNumState", stepNumState)
        console.log("2) maxSteps", maxSteps)
        console.log("3) maxTries", maxTries)
        console.log("4) scoreUnit", scoreUnit)
        console.log("5) showBarFull", showBarFull)
        console.log("6) stepTries", stepTries)
        console.log("7) gameScore", gameScore)
        console.log("8) stars", stars)
        console.log("9) characterState", characterState)
        console.log("10) objectState", objectState)
        console.log("11) randomNumObjectsState", randomNumObjectsState)
        console.log("12) endScreen", endScreen)
        console.log("13) showEndButtons", showEndButtons)
        console.log("14) star01imgState", star01imgState)
        console.log("15) star02imgState", star02imgState)
        console.log("16) star03imgState", star03imgState)
    }

    useEffect(() => {

        if(stepNumState != maxSteps){
            generateNewStep()
        }
    },[])
    
    useEffect(() => {

        calcStars()
        // console.log("stepNumState", stepNumState, " gameScore", gameScore, " stars",stars )
        if(showBarFull){
            setTimeout(() => {
                gameEndScreen()
            },1000)
        }
    },[stepNumState, showBarFull, stars])



    return(
        <>
        <div className={"gameEndScreen " + (endScreen ? " " : "displayNone") }>
            <h1>כל הכבוד!</h1>
            <div className="stars">
                <div className={"star star_01 " + ((star01imgState != "/assets/questgame/star_001_01.png") ? "bounce " : "")}>
                    <img src={star01imgState}/>
                </div>
                <div className={"star star_02 " + ((star02imgState != "/assets/questgame/star_002_01.png") ? "bounce " : "")}>
                    <img src={star02imgState}/>
                </div>
                <div className={"star star_03 " + ((star03imgState != "/assets/questgame/star_003_01.png") ? "bounce " : "")}>
                    <img src={star03imgState}/>
                </div>
            </div>
            <button className={"endButtons button3D buttonKey noselect " + (showEndButtons ? " " : "displayNone") } onClick={()=> newGame()} >שחקו שוב</button>
        </div>
        <div className="questGame__nav">
            <div className='questGame__nav--section questGame__nav--left'>
                <div className="nav-btn nav-btn__pause"><img src="/assets/questgame/pause.png "/></div>

            </div>
            <div className='questGame__nav--section questGame__nav--center'>
                {/* <div className="gameScore">score: {gameScore}</div>
                <div className="gameScore">----</div>
                <div className="gameScore">tries: {stepTries}</div> */}
            </div>
            <div className='questGame__nav--section questGame__nav--right'>
                <div className="nav-btn nav-btn__keyboard" onClick={()=>{setIsKeysOpen(!isKeysOpen)}}><img src="/assets/questgame/keyboard.png "/></div>
                <ProgressBar 
                    ref={progressBarRef}
                    currentStep={stepNumState} 
                    maxStep={maxSteps} 
                    progressBarColor={"#37dadf"}
                    classes={"questGame__nav--progress"}
                    isDarkMode={true}
                    showFirstStepEmpty={true}
                    // showBarFull={true}
                    />
            </div>
          
        </div>
            <StepGame 
                ref={stepGameRef}
                character = {characterState || getRandomCharacter()}
                object = {objectState || getRandomObject()}                
                objectNames = {"תּוּתִים"}
                objectImg = {"/assets/questgame/spaceship.png "}
                numObjects = {randomNumObjectsState || getRandomNumObjects(minCount,maxCount)}
                isKeysOpen = {isKeysOpen}
                stepComplete= {stepComplete}
                stepTries ={stepTries} 
                setStepTries= {setStepTries}
                killOneStepTry = {killOneStepTry}

            />
        </>
    )
}

export default GameCount01
