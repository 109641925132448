
const LessonPuppetSteps = [
  { // step 0 - intro
    size : "ROW",
    name: "general.intro", 
    description: "course.theatre.lesson-puppet.description",
    type: "videoTeamwork",
    AnimalName : "koala",
    // requirements: [{name: "activityPage"} ],
    videoThumbnail: "/assets/course/theatre/lesson-thumbnail_puppet.jpeg",
    stepItems : [
      { 
        pageState: "video",
        url: "https://media.catbears.com/course_theatre/puppet_step-00_intro_he.mp4",
        videoThumbnail: "/assets/course/theatre/intro-thumbnail.jpg",
        isStepGoal: true
      }
    ]
  },
    { // todays lesson
      buttonShape: "circle", // 
      size : "coinImg",
      name: "teamworkSkills.wheel-of-creation.name", 
      description: "teamworkSkills.wheel-of-creation.description",
      lesson: "teamworkSkills.wheel-of-creation.lesson",
      type: "videoTeamwork",
      coinName: "wheel-of-creation",
      // requirements: [],
      videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/thumb_wheel-of-creation.jpg",
      stepItems : [
        { 
          pageState: "video",
          // url: "https://media.catbears.com/course_theatre/testvid.mp4",
          url: "https://media.catbears.com/course_theatre/wheel-of-creation.mp4",
          videoThumbnail: "https://media.catbears.com/course_theatre/thumb_wheel-of-creation.jpg",
          isStepGoal: true
        },
        {
          pageState: "discuss",
          img: "/assets/course/theatre/videos-and-thumbnails/thumb_wheel-of-creation-poster.jpg",
          discussTitle: "teamworkSkills.wheel-of-creation.discussTitle",
        },
      ]
    },
    
    
    { // choose animal
      StepNumberToShow: 1,
      name: "course.theatre.lesson-puppet.steps.choose-animal.name",
      size : "S", 
      state: "disabled", 
      description: "course.theatre.lesson-puppet.steps.choose-animal.description",
      type: "videoTeamwork",
      requirements: [{name: "activityPage"}, {name: "scissors"} ],
      videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/thumb_puupet_choose-animal.jpg",
      stepItems : [
        { 
          pageState: "video",
          url: "https://media.catbears.com/course_theatre/puppet_step-02_choose-animal_he.mp4",
          isStepGoal: true
        },
        {
          pageState: "teamWork",
          url: "https://media.catbears.com/course_theatre/puppet_step-02_choose-animal_he.mp4",
          string: "teamwork text here",
          bullets: [
            {
              text: "course.theatre.lesson-puppet.steps.choose-animal.bullets.bullet1",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/puppet_choose-animal_01.jpg"
            },
            {
              text: "course.theatre.lesson-puppet.steps.choose-animal.bullets.bullet2",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/puppet_choose-animal_02.jpg"
            },
            {
              text: "course.theatre.lesson-puppet.steps.choose-animal.bullets.bullet3",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/puppet_choose-animal_03.jpg"
            }
          ],
          tips: ["course.theatre.lesson-puppet.steps.choose-animal.tips.tip1"]
        }
      ]
    },
    { // glue on cardboard
      StepNumberToShow: 2,
      name: "course.theatre.lesson-puppet.steps.glue-on-cardboard.name",
      size : "S", 
      state: "disabled", 
      description: "course.theatre.lesson-puppet.steps.glue-on-cardboard.description",
      desciptionTips: ["Tip 1", "Tip 2"],
      type: "videoTeamwork",
      requirements: [{name: "cereal"}, {name: "stick-glue"}, {name: "tape"} ],
      videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/thumb_puupet_glue-on-cardboard.jpg",
      stepItems : [
        { 
          pageState: "video",
          url: "https://media.catbears.com/course_theatre/puppet_step-03_paste-on-cardboard_he.mp4",
          isStepGoal: true
        },
        {
          pageState: "teamWork",
          url: "https://media.catbears.com/course_theatre/puppet_step-03_paste-on-cardboard_he.mp4",
          bullets: [
            {
              text: "course.generalSteps.cut-cardboard-light",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/general_cut-cardboard-light.jpg"
            },
            {
              text: "course.theatre.lesson-puppet.steps.glue-on-cardboard.bullets.bullet2",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/puppet_glue-on-cardboard_02_cash-fix.jpg"
            },
            {
              text: "course.theatre.lesson-puppet.steps.glue-on-cardboard.bullets.bullet3",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/puppet_glue-on-cardboard_03_cash-fix.jpg"
            },
            {
              text: "course.theatre.lesson-puppet.steps.glue-on-cardboard.bullets.bullet4",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/puppet_glue-on-cardboard_04_cash-fix.jpg"
            },
            {
              text: "course.theatre.lesson-puppet.steps.glue-on-cardboard.bullets.bullet5",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/puppet_glue-on-cardboard_05_cash-fix.jpg"
            }
          ],
          tips: ["course.generalTips.cut-cardboard-light", "course.theatre.lesson-puppet.steps.glue-on-cardboard.tips.tip2"]
        }
      ]
    },
    { // glue it on a stick
      StepNumberToShow: 3,
      name: "course.theatre.lesson-puppet.steps.stick.name",
      size : "S", 
      state: "disabled", 
      description: "course.theatre.lesson-puppet.steps.stick.description",
      desciptionTips: ["Tip 1", "Tip 2"],
      type: "videoTeamwork",
      requirements: [{name: "stick"}, {name: "bottle-glue"}, {name: "tape"} ],
      videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/thumb_puupet_glue-on-stick.jpg",
      stepItems : [
        { 
          pageState: "video",
          url: "https://media.catbears.com/course_theatre/puppet_step-04-glue-it-on-a-stick_he.mp4",
          isStepGoal: true
        },
        {
          pageState: "teamWork",
          url: "https://media.catbears.com/course_theatre/puppet_step-04-glue-it-on-a-stick_he.mp4",
          string: "teamwork text here",
          bullets: [
            {
              text: "course.theatre.lesson-puppet.steps.stick.bullets.bullet1",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/puppet_stick_01.jpg"
            },
            {
              text: "course.theatre.lesson-puppet.steps.stick.bullets.bullet2",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/puppet_stick_02.jpg"
            },
            {
              text: "course.theatre.lesson-puppet.steps.stick.bullets.bullet3",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/puppet_stick_03.jpg"
            },
            {
              text: "course.theatre.lesson-puppet.steps.stick.bullets.bullet4",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/puppet_stick_04.jpg"
            },
          ],
          tips: ["course.theatre.lesson-puppet.steps.stick.tips.tip1", "course.theatre.lesson-puppet.steps.stick.tips.tip2", "course.theatre.lesson-puppet.steps.stick.tips.tip3"]
        }
      ]
    },
    { // color
      StepNumberToShow: 4,
      name: "course.theatre.lesson-puppet.steps.color.name",
      size : "S", 
      state: "disabled", 
      description: "course.theatre.lesson-puppet.steps.color.description",
      desciptionTips: ["Tip 1", "Tip 2"],
      type: "videoTeamwork",
      requirements: [{name: "allColors"} ],
      videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/thumb_puupet_color.jpg",
      stepItems : [
        { 
          pageState: "video",
          url: "https://media.catbears.com/course_theatre/puppet_step-05-color_he.mp4",
          isStepGoal: true
        },
        {
          pageState: "teamWork",
          url: "https://media.catbears.com/course_theatre/puppet_step-05-color_he.mp4",
          string: "teamwork text here",
          bullets: [
            {
              text: "course.generalSteps.all-colors",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/general_all-colors.jpg"
            },
            {
              text: "course.theatre.lesson-puppet.steps.color.bullets.bullet2",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/puppet_color_02.jpg"
            },
            {
              text: "course.theatre.lesson-puppet.steps.color.bullets.bullet3",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/puppet_color_03.jpg"
            }
          ],
          tips: ["course.theatre.lesson-puppet.steps.color.tips.tip1", "course.theatre.lesson-puppet.steps.color.tips.tip2"]
        }
      ]
    },
    { // piano fight
      name: "conflict-simulator.piano-fight.name",
      size : "XL", 
      state: "selected", 
      description: "conflict-simulator.piano-fight.description",
      desciptionTips: ["Tip 1", "Tip 2"],
      taskTitle: "conflict-simulator.piano-fight.taskTitle",
      taskSubTitle: "conflict-simulator.piano-fight.taskSubTitle",
      type: "conflict-simulator",
      // AnimalName : "croc",
      ImageToShow: "/assets/course/theatre/videos-and-thumbnails/my-turn_image-to-show.png",
      videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/step2-thumbnail.jpg",
      stepItems : [
        {
          pageState: "video",
          url: "https://media.catbears.com/course_theatre/piano-fight_1.mp4",
          videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/step2-thumbnail.jpg",
          isStepGoal: true
        },
         {
          pageState: "needCards",
          thumbnailUrl: "https://media.catbears.com/course_theatre/piano-fight_what-he-needs.png",
          backgoundColor: "#43af4c",
          // thumbnailUrl: "https://media.catbears.com/mine-croc.jpg",
          cards: ["Love", "friendship","Idea" ]
          // cards: ["rest", "Appriciation", "Love", "friendship", "Get support", "Creativity" ,"freedom", "To be understood", "Patience", "Kindness" ]
        },
        {
          pageState: "video",
          url: "https://media.catbears.com/course_theatre/piano-fight_2.mp4",
          videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/step2-thumbnail.jpg",
          isStepGoal: true
        },
      ]
    }
    // {
    //   name: "course.theatre.lesson-puppet.steps.step6.name",
    //   size : "XL", 
    //   state: "disabled", 
    //   description: "course.theatre.lesson-puppet.steps.step6.description",
    //   desciptionTips: ["Tip 1", "Tip 2"],
    //   type: "videoTeamwork",
    //   AnimalName : "koala",
    //   requirements: [{name: "activityPage"}, {name: "scissors"}, {name: "stick-glue"}, {name: "cardboard"} ],
    //   video2: "https://media.catbears.com/tempVid.mp4",
    //   videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/conflict-video_croc-koala.jpg",
    //   stepItems : [
    //     {
    //       pageState: "video",
    //       url: "https://media.catbears.com/course_theatre/piano-fight_2.mp4",
    //       videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/intro-thumbnail.jpg",
    //       isStepGoal: true
    //     },
    //     {
    //       pageState: "teamWork",
    //       url: "https://media.catbears.com/tempVid.mp4",
    //       string: "teamwork text here",
    //     },
    //     {
    //       pageState: "video",
    //       url: "https://media.catbears.com/course_theatre/piano-fight_3.mp4",
    //       videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/intro-thumbnail.jpg",
    //       isStepGoal: true
    //     },
    //   ]
    //   // stepItems : [
    //   //   // {
    //   //   //   pageState: "video",
    //   //   //   url: "https://media.catbears.com/tempVid.mp4",
    //   //   //   videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/conflict-video_croc-koala.jpg",
    //   //   // },
    //   //   // {
    //   //   //   pageState: "needCards",
    //   //   //   thumbnailUrl: "https://media.catbears.com/mine-croc.jpg",
    //   //   //   cards: ["rest", "Appriciation", "Love", "friendship", "Get support", "Creativity" ,"freedom", "To be understood", "Patience", "Kindness" ]
    //   //   // },
    //   //   {
    //   //     pageState: "video",
    //   //     url: "https://media.catbears.com/tempVid.mp4",
    //   //     videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/conflict-video_croc-koala.jpg",
    //   //     isStepGoal: true
    //   //   }
    //   // ]
    // }
  ]
  
  export default LessonPuppetSteps;