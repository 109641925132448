import React, { useState } from 'react';
import './InfoCard.scss';

const FadeInImage = ({ src, alt }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <img
      src={src}
      alt={alt}
      className={`fade-in ${isLoaded ? 'loaded' : ''}`}
      onLoad={() => setIsLoaded(true)}
    />
  );
};

const InfoCard = ({ imgUrl, title, subtitle, num }) => {
  return (
    <div key={num} className="infoCard">
      <div className="infoCard_details">
        {num && <div className="infoCard__number">{num}</div>}
        <div className="infoCard_text">
          {title && <p className="infoCard_title">{title}</p>}
          {subtitle && <p className="infoCard_subtitle">{subtitle}</p>}
        </div>
      </div>
      {imgUrl && (
        <div className="infoCard_image">
          <FadeInImage src={imgUrl} alt={title || ''} />
        </div>
      )}
    </div>
  );
};

export default InfoCard;
