const getLessonRequirementsArr = (stepsRecipe) => {
    const LessonRequirementsArr = [];
    stepsRecipe.forEach(step => {
      if (Array.isArray(step.requirements)) {
        step.requirements.forEach(requirement => {
          if (!LessonRequirementsArr.some(existingReq => existingReq.name === requirement.name)) {
            LessonRequirementsArr.push(requirement);
          }
        });
      }
    });
  
    return LessonRequirementsArr;
  };

  export default getLessonRequirementsArr