import React , {useState, useEffect} from "react";
import Card from "components/Card/Card";
import { happenedCards, feelCards, needCards} from '../../static/cards';
import "./ConflictSimulatorKeyboard.scss"
import { useTranslation } from 'react-i18next';

function ConflictSimulatorKeyboard ({CardsArr = [], SelectedCards, setSelectedCards, maxSelectedCards, CardsType="NEED" , OnCardClick, shouldOpenKeyboard, setShouldOpenKeyboard, setInputFullToMax}) {
    const {t} = useTranslation();
    const [CHEATS, setCHEATS] = useState(0)
    const [keyboardPositionClass, setKeyboardPositionClass] = useState("ConflictSimulatorKeyboard-defaultPosition")

    function toggleKeyboard () {
        if(keyboardPositionClass == "ANIMATION-slideFromBottom"){
            closeKeyboard()
        }else if (keyboardPositionClass == "ANIMATION-slideToBottom" || keyboardPositionClass == "ConflictSimulatorKeyboard-defaultPosition"){
            openKeyboard()
        }
    }

    function closeKeyboard () {
        setKeyboardPositionClass("ANIMATION-slideToBottom")
        setShouldOpenKeyboard(false)
    }

    function closeKeyboardWithDelay () {
        setTimeout(() => {
            setKeyboardPositionClass("ANIMATION-slideToBottom")
            setShouldOpenKeyboard(false)
        }, 700);
        
    }


    function openKeyboard () {
        setKeyboardPositionClass("ANIMATION-slideFromBottom")
    }

    useEffect(() => {
        setKeyboardCards(generateCards());
        if(shouldOpenKeyboard){
            openKeyboard()
            setShouldOpenKeyboard(true)
        }
        if(SelectedCards.length == maxSelectedCards){
            setInputFullToMax(true)
        }else{
            setInputFullToMax(false)
        }
    }, [SelectedCards, CardsArr, CardsType, shouldOpenKeyboard]);

    function updateSelectedCardsArr(cardName) {
    

        setSelectedCards((prevSelectedCards) => {
            const updatedSelectedCards = prevSelectedCards.filter((card) => {
                if (card.title === cardName) {
                    return false; 
                }
                
                return true;
            });
            
            if (updatedSelectedCards.length === prevSelectedCards.length) {
                // If the card is not selected, add it
                const cardToAdd = needCards.find((card) => card.title === cardName);
                if (maxSelectedCards > SelectedCards.length){
                    if (cardToAdd) {
                        updatedSelectedCards.push(cardToAdd);
                       
                        if(SelectedCards.length == maxSelectedCards -1){
                            closeKeyboardWithDelay()
                        }
                    }
                }
            }

            

            return updatedSelectedCards;
        });
    }
  
  function checkCardSelected(cardName) {
    return SelectedCards.some(selectedCard => selectedCard.title === cardName);
  }
  
  

function generateCards (){
    let Cardsholder = [];
    CardsArr.forEach((cardNameInput) => {
        Cardsholder.push(getCardData(cardNameInput, CardsType));
    });
    return Cardsholder;
}


function getCardData(cardNameInput, CardsType) {
    if (CardsType === "NEED") {
      return needCards.map((card, index) => {
        const lowercaseTitle = card.title.toLowerCase();
        const lowercaseCardNameInput = cardNameInput.toLowerCase();
  
        if (lowercaseTitle === lowercaseCardNameInput) {
          return (
            <Card
              key={index}  // Add a unique key for each element in the array
              cardType="NEED"
              emoji={card.emoji}
              modifier={card.modifier}
              title={card.title}         
              onClick={() => updateSelectedCardsArr(needCards[index].title)}
              classes={"hoverScale " + (checkCardSelected(needCards[index].title) ? " card--selected " : "")}
            />
          );
        }
  
        return null; // Return null for elements that don't match
      });
    } else {
      alert("Invalid card type");
      return null;
    }
  }
    const [KeyboardCards, setKeyboardCards] = useState(() => generateCards())
    
    return (
        <>

        
          <div className={"ConflictSimulatorKeyboard--container noselect "}>
            {CHEATS 
                ? 
                <>
                    <div className="CHEATS CHEATS-left">
                        <div>{"shouldOpenKeyboard = " + shouldOpenKeyboard}</div>
                        <div>{"SelectedCards = " + SelectedCards.map((card) => card.title).join(", ")}</div>
                        <button onClick={() => toggleKeyboard() }>open keyboard</button>
                    </div>
                </>
                : ""
            }
                        
                <div className={"ConflictSimulatorKeyboard " + keyboardPositionClass}>
                    <div className="ConflictSimulatorKeyboard_card-keyboard__nav ">
                        <div className="ConflictSimulatorKeyboard_card-keyboard__icon ">
                            <div className="icon__x" onClick={()=> closeKeyboard()}>+</div>

                        </div>
                        <div className="ConflictSimulatorKeyboard_card-keyboard__nav-center ">{t("general.listOfNeeds")}</div>
                        <div className="ConflictSimulatorKeyboard_card-keyboard__spacer ">
                        </div>
                    </div>
                    <div className="ConflictSimulatorKeyboard_card-keyboard__cards ">
                        {KeyboardCards}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ConflictSimulatorKeyboard