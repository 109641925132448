import React, { useRef } from 'react';
import Card from '../Card/Card';
import CardPlaceholder from '../PlaceholderCard/PlaceholderCard';
import './CardGallery.scss';

function CardGallery({
  selectedCards,
  cards,
  onCardChange,
  length = 25,
  openKeyboard,
  name,
  partnerName,
  gender,
  partnerGender,
  openCardEditor,
}) {
  const numOfPlaceholders = Math.max(length - selectedCards.length, length);
  const cardGalleryRef = useRef()
  const onCardAdd = card => {
    const newCards = [...selectedCards, card];
    onCardChange(newCards);
    selectedCards = newCards
    const el = cardGalleryRef.current.querySelector(`.card-placeholder`)
    cardGalleryRef.current.scrollTo({ left: el.offsetLeft - cardGalleryRef.current.offsetLeft - el.clientWidth - 12, behavior: `smooth` });
  };

  return (
    <div className="cardGallery__container">
      <div className="gallery-button gallery-button__left" onClick={() => cardGalleryRef.current.scrollBy({ left: -120, behavior: `smooth` })}></div>
      <div ref={cardGalleryRef} className={"cardGallery"}>
        {selectedCards.map((card, i) => {
          return <Card key={i} {...card} gender={gender} partnerGender={partnerGender} name={name} partnerName={partnerName} onClick={() => openCardEditor({ card: card, index: i })} />
        })}
        {new Array(numOfPlaceholders).fill(0).map((u, i) => (
          <div className="card card-placeholder"
            key={i}
            onClick={() => openKeyboard({ cards, onSelect: onCardAdd, element: cardGalleryRef.current })}
          >
            <CardPlaceholder />
          </div>
        ))}
      </div>
      <div className="gallery-button gallery-button__right" onClick={() => cardGalleryRef.current.scrollBy({ left: 120, behavior: `smooth` })}></div>
    </div>
  );
}

export default CardGallery;
