import React, { useState, useEffect } from 'react';
import '../Q_Elements.scss';
import './Q_ListSingleSelect.scss';
import { useTranslation } from 'react-i18next';

const Q_ListSingleSelect = ({ listArr, onSelect, isRandomizeAnswers, isOther, defaultSelection, alwaysLTR }) => {
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useState(null);
  const [displayedListArr, setDisplayedListArr] = useState([]);

  useEffect(() => {

    let randomizedListArr = listArr;
    if (isRandomizeAnswers) {
      randomizedListArr = [...listArr].sort(() => Math.random() - 0.5);
    }

    if (isOther) {
      randomizedListArr = [...randomizedListArr, t('exitPoll.reason__other')];
    }

    setDisplayedListArr(randomizedListArr);
  }, []);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    onSelect(item.id);
  };

  return (
    <div className="list-container list-container__icon-v">
      {displayedListArr.map((item) => (
        <div
          key={item.id}
          onClick={() => handleItemClick(item)}
          className={["single-select-button selection-button-style selection-button-style-small antialiased noselect list-item" ,
            (selectedItem?.id === item.id || !selectedItem?.id && item.id === defaultSelection) && 'selected', alwaysLTR && "alwaysLTR"
          ].join(" ")}
        >
          {item.displayString}
          <div className="list-item__icon ">
          </div>
        </div>
      ))}
    </div>
  );
};

export default Q_ListSingleSelect;
