import React, { useState } from 'react';
import './JourneyListItem.scss';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ButtonCircle from '../../components/ButtonCircle/ButtonCircle';

//delete imports
import gql from 'graphql-tag';
import { graphql, useMutation, useQuery } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import { addMirror, deleteMirror as deleteMirrorMutation } from '../../graphql/mutations';
import { getRelationship } from '../../graphql/queries';
import Loading from '../../components/Loading/Loading';
import ButtonIconText from 'components/ButtonIconText/ButtonIconText';

function JourneyListItem({
  classes,
  journeyType,
  journeyName,
  itemRelationshipID,
  stepName,
  currentJourneyItem,
  maxJourneyItem,
  dateCreated,
  pathname,
  state,
  itemJourneyID,
  params,
  match
}) {

  const { t } = useTranslation();

  //delete functionality
  // const { match } = props;
  // const { params } = match;
  const { id, journeyID } = params;
  const relationshipID = itemRelationshipID;
  const historyHook = useHistory();
  const [deleteMirror] = useMutation(gql(deleteMirrorMutation), {
    refetchQueries: [{ query: gql(getRelationship), variables: { id: relationshipID } }]
  });
  const { loading, error, data } = useQuery(gql(getRelationship), {
    variables: { id: match.params.id },
    fetchPolicy: 'network-only'
  });

  if (loading) return <Loading />;
  const { mySideMirrors, name, partnerName, animalId, partnerAnimalId, relation, gender, partnerGender } = data.getRelationship;
  const mySideMirror = mySideMirrors.find((m) => m.id === journeyID);
  // const quests = mySideMirror.progress.flowState;

  // end delete functionality

  return (
    <>
     <div className="JourneyListItem__container--list-item">
      <Link
        to={{
          pathname: pathname,
          state: state
        }}
      >
         
        <div className="JourneyListItem__container ">
          <div className="JourneyListItem__container--left">
            <div className="JourneyListItem__container--left__date">{t("mirroring.mirrirCreatedOn") + " " + dateCreated}</div>
            <div className="JourneyListItem__container--left__journeyName">{journeyName}</div>            
          </div>
          <div className="JourneyListItem__container--right">
            <div className="JourneyListItem__button">{t("general.open")}</div>
          </div>
        </div>
      </Link>
      <div className="JourneyListItem__spacer"></div>
      <div className="JourneyListItem__delete-button ">
        <ButtonIconText
          // text={t(`mirroring.edit.delete`)}
          icon={'/assets/icons/icon_30_bin.png'}
          // classes="ButtonCircle-bin"
          onClick={async () => {
            const confirmDelete = window.prompt(
              t("general.sure-delete-conflict") + `\n"${journeyName ? journeyName : ""}"\n\n`+ (t("general.type-delete-confirm"))
            );
          
            if (confirmDelete === "Delete") {
              await deleteMirror({ variables: { input: itemJourneyID } });
              historyHook.push(`/relationship/${itemRelationshipID}`);
            }
          }}
        />
      </div>
    </div>
    </>
    
  );
}

export default JourneyListItem;
