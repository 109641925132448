import React from 'react';
import { useTranslation } from 'react-i18next';

function NeedCard({ emoji, modifier, title, classes }) {
  const { t } = useTranslation();
  return (
    <>
      <div className={"card card--need " + classes}>
        <div className="card__icon card__icon--need"></div>
        <div className="card__emoji">{emoji}</div>
        <div className="card__not--stroke">{modifier === `isReversed` && t(`card.not`)}</div>
        <div className="card__not">{modifier === `isReversed` && t(`card.not`)}</div>
        <div className="card__a-little--stroke">{modifier === `isReduced` && t(`card.alittle`)}</div>
        <div className="card__a-little">{modifier === `isReduced` && t(`card.alittle`)}</div>
        <div className="card__title">{t(`cards.NEED.${title}.title`)}</div>
      </div>
    </>
  );
}

export default NeedCard;
