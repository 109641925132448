import React from 'react';
import './PlaceholderCard.scss';

function EmptyCard() {
  return <div>
    +
    {/* <img src="/assets/icons/icon_plus.png" alt="plus icon"/> */}
    </div>;
}

export default EmptyCard;
