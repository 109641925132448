export const characters = [
    {
        name: "יָאֲיָא",
        img: "/assets/questgame/yaya.png",
        color:"#37dadf",
        colorDarker:"#129da2",
        sfx:"/assets/questgame/rotem.mp3"
    },
    {
        name: "אוֹלִי",
        img: "/assets/questgame/oly.png",
        color:"#FFBD2E",
        colorDarker:"#e2a115",
        sfx:"/assets/questgame/owl.mp3"
    },
    {
        name: "לַלִיק",
        img: "/assets/questgame/lalik.png",
        color:"#FD4AA2",
        colorDarker:"#e23388",
        sfx:"/assets/questgame/meo.mp3"
    },
    {
        name: "שׁוּעֲלֵי",
        img: "/assets/questgame/fox.png",
        color:"#37dadf",
        colorDarker:"#129da2",
        sfx:"/assets/questgame/shalom.mp3"
    },
    {
        name: "גּוֹרִילָה",
        img: "/assets/questgame/gorilla.png",
        color:"#b25b20",
        colorDarker:"#129da2",
        sfx:"/assets/questgame/huhu.mp3"
    },
    {
        name: "עַכְבָּרוֹן",
        img: "/assets/questgame/mouse.png",
        color:"#0283ab",
        colorDarker:"#129da2",
        sfx:"/assets/questgame/mimi.mp3"
    },
    {
        name: "פִּילוֹנִי",
        img: "/assets/questgame/pil.png",
        color:"#2297ff",
        colorDarker:"#129da2",
        sfx:"/assets/questgame/shalom.mp3"
    },
    {
        name: "אַרְיֵה",
        img: "/assets/questgame/arye.png",
        color:"#ff0200",
        colorDarker:"#129da2",
        sfx:"/assets/questgame/shalom.mp3"
    },
    {
        name: "חֲזִירוֹנֵי",
        img: "/assets/questgame/piggy.png",
        color:"#ffacff",
        colorDarker:"#129da2",
        sfx:"/assets/questgame/shaloom.mp3"
    },
    {
        name: "דְּרַקּוֹ הַדְּרָקוֹן",
        img: "/assets/questgame/draco.png",
        color:"#07f501",
        colorDarker:"#129da2",
        sfx:"/assets/questgame/buru.mp3"
    },
    {
        name: "צ'וּצִ'ין",
        img: "/assets/questgame/chuchin.png",
        color:"#ff8700",
        colorDarker:"#129da2",
        sfx:"/assets/questgame/rotem.mp3"
    },
    {
        name: "סְפׇּיְדִּי",
        img: "/assets/questgame/spidy.png",
        color:"#ff0200",
        colorDarker:"#129da2",
        sfx:"/assets/questgame/noam.mp3"
    },
    {
        name: "גַּעגַּע",
        img: "/assets/questgame/ducky.png",
        color:"#9fe4e2",
        colorDarker:"#129da2",
        sfx:"/assets/questgame/quaa.mp3"
    },
]

export const objects = [
    {
        name: "תּוּתִים",
        img: "/assets/questgame/strawberry.png",
        color:"#37dadf",
    },
    {
        name: "חֲלָלִיּוֹת",
        img: "/assets/questgame/spaceship.png",
        color:"#FFBD2E",
    },
    {
        name: "לְבָבוֹת",
        img: "/assets/questgame/heart.png",
        color: "#ff30a6"
    },
    {
        name: "בּוּעוֹת",
        img: "/assets/questgame/bubble.png",
        color:"#2297ff",
    },
    {
        name: "עַגְבָנִיּוֹת",
        img: "/assets/questgame/tomato.png",
        color:"#ff0200",
    },
    {
        name: "יַתּוּשִׁים",
        img: "/assets/questgame/mosquito.png",
        color:"#9fe4e2",
    },
    {
        name: "בָּנָנוֹת",
        img: "/assets/questgame/banana.png",
        color: "#fff11d"
    },
    {
        name: "עֲנָפִים",
        img: "/assets/questgame/branch.png",
        color : "#a85b3b"
    },
    {
        name: "בְּרָגִים",
        img: "/assets/questgame/screw.png",
        color:"#f45700",
    },
    {
        name: "דָּגִים",
        img: "/assets/questgame/fish.png",
        color: "#f5faef"
    }
]

