import React, { useState } from 'react';
import Modal from '../../components/Modal/Modal'
import { useTranslation } from "react-i18next";
import * as AuthState from './authState';

const NewPassword = ({ setAuthState, authProps, changePasswordHandler, authState }) => {
	const [password, setPassword] = useState("");
	const [repeatPassword, setRepeatPassword] = useState("");
	const [message, setMessage] = useState(null);
	const { t } = useTranslation();

	const handler = (stateNum) => {
		setAuthState(stateNum);
	}

	console.log('authProps', authProps);

	const onResetPassword = (event) => {
		event.preventDefault();
		if(password !== repeatPassword){
			setMessage(t("authentication.passwordsDoNotMatch"));
			return;
		}
		changePasswordHandler(password);
	}

	return (
		<div>
			{/* {authState === AuthState.DONE_CHANGE_PASSWORD && <Modal
				CONTENT_title="Your new password was saved"
				PRESET_TopNotification={true}
				PRESET_Green={true}
				isXbutton={true}
				ModalLeaveDelayState={5000}
			/>} */}
			<div className="modal fade-in">
				<h2 className="modal__title">Reset Password</h2>
				<form onSubmit={onResetPassword} className="modal__form">
					<div className={"modal__form--error-msg " + (authProps.isError ? "" : " displayNone ")}>{authProps.errorMsg}</div>
					<div className={"modal__form--error-msg " + (message ? "" : " displayNone ")}>{message}</div>
					<div>
						<label className={"form-label noselect "}>Password</label>
						<input type="Password" className="input" value={password} onChange={e => setPassword(e.target.value)}></input>
					</div>
					<div>
						<label className={"form-label noselect "}>Repeat Password</label>
						<input type="Password" className="input" value={repeatPassword} onChange={e => setRepeatPassword(e.target.value)}></input>
					</div>

					<button className={"modal__form--sumbit-button " + (authProps.isLoading ? "preloaderGif " : " ")} type="submit">{authProps.isLoading ? "" : "Change Password"}</button>
				</form>
				<div className="modal__below-submit">
					<p>Remember your password? <a className="modal__below-submit__btn" onClick={() => handler(AuthState.LOGIN)}>Log In</a></p>
				</div>
			</div>
		</div>
	)
}

export default NewPassword;