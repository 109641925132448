import React from 'react';
import { useTranslation } from 'react-i18next';
import GenderPicker from '../../components/GenderPicker/GenderPicker';
import Button from '../../components/Button/Button';
import AnimalsCarousel from '../../components/AnimalsCarousel/AnimalsCarousel';
// import TopNavbar from '../../components/TopNavbar/TopNavbar';
 
function Step2({
  changeAnimal,
  changeSex,
  changeStep,
  changeName,
  name,
  gender, 
  animalId,
  user
}) {
  const { t } = useTranslation();
  return (
    <>
      <div className="step-container texture">
      {/* <TopNavbar 
        user={user}
        changeStep={changeStep}
        // BackOnClick={e => changeStep(`STEP1`, e)}
        BackOnClick={(e)=> {
          console.log("e",e); 
          changeStep(`STEP1`, e)
        }}
        >
      </TopNavbar> */}
        <Button isBackButton={true} onClick={e => changeStep(`STEP1`, e)} />
        <div className="title noselect">
          <h1>{t(`create_new_relationship.step2.title`)}</h1>
          <h2>{t(`create_new_relationship.step2.subtitle`)}</h2>
        </div>
        <div className="animals-carousel__side-b animals-carousel-container animals-carousel-container__step2 noselect">
          <AnimalsCarousel
            onChange={animalId => changeAnimal(animalId)}
            value={animalId}
          />
        </div>
        <div className="details">
          <div className="text-field">
            {/* <label htmlFor={`name`}>{t(`create_new_relationship.step2.input_name`)}</label> */}
            <input
              required
              autoComplete={'off'}
              placeholder={t(
                `create_new_relationship.step2.input_name_placeholder`
              )}
              name={`name`}
              type="text"
              value={name}
              onChange={event => changeName(event.target.value)}
            />
          </div>
          <GenderPicker onChange={gender => changeSex(gender)} value={gender} />
          <Button
            color={`#489de5`}
            backgroundColor={`white`}
            background
            text={t(`create_new_relationship.step2.next_button`, {
              context: gender
            })}
            onClick={e => changeStep(`STEP3`, e)}
          />
        </div>
      </div>
    </>
  );
}

export default Step2;
