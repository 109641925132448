import React from 'react';
import "./Faq.scss"
import SideBarPanelNav from '../../components/SideBarPanelNav/SideBarPanelNav';
import ContentTwoColumns from '../../components/ContentTwoColumns/ContentTwoColumns';
import { useTranslation } from 'react-i18next';

const Faq = () => {
    const { t } = useTranslation();

	return(
        <>
        <SideBarPanelNav
            left={'backButton'}
            center={'oneLine'}
            centerTitle={'Frequently Asked Questions'}
            leftTo={`/`}
            fixed={true}
            />

		<div className="PageTwoColumns-container">
            <img className="heroImg" src="/assets/faq/felt-faq.jpg "/>
            <div className="PageTwoColumns-container__content">
                
                <ContentTwoColumns 
                    leftContentMaxWidth={"25"}
                    leftContent={<h3><strong>What is Felt?</strong></h3>}
                    rightContnet={
                        <p>
                            Felt gives parents a chance to have meaningful one-on-one discussions with their children, so they’ll become more involved in their lives.
                            <br/><br/>
                            {/* By equipping children with the right set of tools, they become to understand and resolve their conflicts.
                            <br/><br/> */}
                            By asking the right questions in a fun and friendly environment, Felt gives children a way to open up and share their hardships.
                            <br/><br/>
                            Children learn best by doing. Felt is a practical way to help children practice and develop their social and emotional skills, become self aware of their needs, build empathy towards the other and find the words to express what’s on their minds and hearts.
                            {/* <br/><br/> */}
                            {/* When joining these together: Design, Technology and Children Psychology, we believe it’s possible to equip children from a very young age with the skills they need to interact with the people around them happily, and resolve their conflicts all by themselves. */}
                            {/* <br/><br/> */}
                            {/* Felt was crafted by the help of experts in many fields such as Conflict Resolution, NVC - Nonviolent Communication, Mediation, Children Psychology, SEL - Social Emotional Learning and Parent Coaching. */}
                        </p>
                        }
                />
                <ContentTwoColumns 
                    leftContentMaxWidth={"25"}
                    leftContent={<h3><strong>What age kids is Felt ideal for?</strong></h3>}
                    rightContnet={
                        <p>
                            The app was originally designed for children at the ages of 8-14, but we are hearing many stories from parents who are using Felt with their younger and older children and find it useful. 
                            <br/><br/>
                            This make us believe it can be used with children of all ages as long as there's a <a target="_blank" href="./hiw"><a target="_blank" href="./hiw">Helper</a></a> with them - an adult whose guiding and supporting them.                       
                        </p>
                        }
                />
                {/* <ContentTwoColumns 
                    leftContentMaxWidth={"25"}
                    leftContent={<h3><strong>Is Felt a tool for self reflection?</strong></h3>}
                    rightContnet={
                        <p>
                            It’s much more than that. Self reflection is one of the skills Felt will develop. It also help build empathy towards the other, see the other’s point of view, and help children find ways to resolve conflicts by themselves.
                        </p>
                        }
                /> */}
                <ContentTwoColumns 
                    leftContentMaxWidth={"25"}
                    leftContent={<h3><strong>How do I use Felt?</strong></h3>}
                    rightContnet={
                        <p>
                            We created <a target="_blank" href="./hiw">a guide to 'How to use Felt'</a> that answers this question in detail. Please read it before using Felt.
                        </p>
                        }
                />
                <ContentTwoColumns 
                    leftContentMaxWidth={"25"}
                    leftContent={<h3><strong>Where should I use Felt?</strong></h3>}
                    rightContnet={
                        <p>
                            Find the right time and place where you can sit alone with your child without being interrupted, in a quiet and friendly environment where your child feels safe and comfortable to talk.
                            <br/><br/>
                            Using a computer or a tablet will work better with younger children who have shorter attention spans.
                        </p>
                        }
                />
                <ContentTwoColumns 
                    leftContentMaxWidth={"25"}
                    leftContent={<h3><strong>What happens with the information I share on Felt?</strong></h3>}
                    rightContnet={
                        <p>
                            We are very aware that the information shared on Felt is very private, therefore it will never be shared or sold to any vendor or third party, and is highly secured and password protected.
                            <br/><br/>
                            We monitor general metrics and statistics of usage to help us learn how to build and improve Felt.
                            <br/><br/>
                            From time to time we may wish to contact you for feedback or in order to provide you with details on updates and upcoming features. You may opt out of any or all of these if you choose.
                        </p>
                        }
                />
                <ContentTwoColumns 
                    leftContentMaxWidth={"25"}
                    leftContent={<h3><strong>How much time does it take to use Felt?</strong></h3>}
                    rightContnet={
                        <p>
                            Using Felt takes very little time. In just 10 minutes you'll already know a lot of information about your child's life from their own point of view.
                            <br/><br/>
                            It might sound difficult finding time in our busy schedules, but it’s really very simple - you’ll need to treat it as any other meeting you have and write it down in your calendar. When you do find the time to be with your child, be present, put your phones aside and nurture your relationship. Your one-on-one time will be something they’ll remember all their lives.
                        </p>
                        }
                />
                <ContentTwoColumns 
                    leftContentMaxWidth={"25"}
                    leftContent={<h3><strong>How often should I use Felt with my child?</strong></h3>}
                    rightContnet={
                        <p>
                            It's up to you. It can happen every day, once a week or once a month. When you sense that your child needs to talk you can offer him/her to do Felt together.
                            <br/><br/>
                            Don't force it - Felt should feel fun and your special time together. It really depends on your child's mood and willingness to share.
                        </p>
                        }
                />
                <ContentTwoColumns 
                    leftContentMaxWidth={"25"}
                    leftContent={<h3><strong>On which devices can I use Felt?</strong></h3>}
                    rightContnet={
                        <p>
                            Felt is an web-app and designed to work on your smart-phones, tablets, computers and on any other device that has a browser. No need to download anything.
                            <br/><br/>
                            To log-in to your account (or to create a new account) type this link into your browser URL field:
                            <a href="https://felt.catbears.com">https://felt.catbears.com</a>
                        </p>
                        }
                />
                <ContentTwoColumns 
                    leftContentMaxWidth={"25"}
                    leftContent={<h3><strong>Who are the people behind Felt?</strong></h3>}
                    rightContnet={
                        <p>
                            'The Catbears' is a social startup. We develop, research, and implement solutions to a real burning issue - Our children's social future.
                            <br/><br/>
                            The team behind 'Felt by The Catbears' are people who believe in the cause and are willing to invest their time and expertise to make it happen.
                            <br/><br/>
                            The team is made of developers, child psychologists, educators, parenting coaches, and experts in the fields of Conflict Resolution, NVC - Nonviolent Communication and SEL - Social Emotional Learning. 
                            <br/><br/>
                            The creator and founder of Felt and 'The Catbears' is <a target="_blank" href="https://www.linkedin.com/in/maayan-malka-59a4b910/?originalSubdomain=il">Maayan Malka</a>, an Israeli entrepreneur with over 15 years of experience in product design and development of interactive applications.
                            {/* Design and Product Development, and is an expert in the field of visual communication.  */}
                            <br/><br/>
                            At the same time as working on Felt, Maayan works as one of lead product designers at <a target="_blank" href="https://www.calcalistech.com/ctech/articles/0,7340,L-3910571,00.html">JoyTunes</a>, a unicorn EdTech startup which brings music to the world with fun and engaging musical learning applications such as <strong>Simply Piano</strong> and <strong>Simply Guitar</strong>.
                        </p>
                    }
                />
                <ContentTwoColumns 
                    leftContentMaxWidth={"25"}
                    leftContent={<h3><strong>Does Felt cost money?</strong></h3>}
                    rightContnet={
                        <p>
                          Felt is free. As it grows we're looking for ways to keep it this way so anyone can use it. We don’t measure our success in terms of profit, instead we try to help people live better lives and bring change.
                        </p>
                        }
                />
            </div>
            <img className="sticker_s" src="/assets/stickers/effectionate4.png"/>
        </div>
        </>
	)
}

export default Faq;