import React, { useState, forwardRef, useEffect, useImperativeHandle} from 'react';
import "./StepGame.scss"
import SideBarPanelNav from '../../components/SideBarPanelNav/SideBarPanelNav';
import { useTranslation } from 'react-i18next';
import KeyboardNum1to10 from '../../components/KeyboardNum1to10/KeyboardNum1to10';

const StepGame = forwardRef(({character, object, numObjects, isKeysOpen, stepComplete, stepTries, setStepTries, killOneStepTry}, ref) => {
    const { t } = useTranslation();
    
    const randomObjMinWidth = () => {
      return Math.floor(Math.random() * (210 - 100 + 1) ) + 100;   
    }
    const randomObjJustifyContent = () => {
      let random = Math.floor(Math.random() * (3 - 1 + 1) ) + 1;
      if(random==1){
        return "flex-start"
      }
      if(random==2){
        return "flex-end"
      }
      if(random==3){
        return "flex-end"
      }
    }
    const [answerValue, setAnswerValue] = useState("_")
    const [isFreeze, setIsFreeze] = useState(false)
    const [isAnswerCorrect, setIsAnswerCorrect] = useState()
    const [sticker, setSticker] = useState()
    const [isAnswerBlink, setIsAnswerBlink] = useState(false)
    const [isAnswerTrebble, setIsAnswerTrebble] = useState(false)
    const [objMinWidth, setObjMinWidth] = useState(randomObjMinWidth())
    const [objJustifyContent, setObjJustifyContent] = useState(randomObjJustifyContent())
    document.documentElement.style.setProperty('--questGame__color--character', character.color);
    document.documentElement.style.setProperty('--questGame__color--object', object.color);
    document.documentElement.style.setProperty('--objWidth', "calc(100% / " + numObjects + ")");

    document.documentElement.style.setProperty('--obj-min-width', objMinWidth + "px");
    document.documentElement.style.setProperty('--objJustifyContent', objJustifyContent);


    useImperativeHandle(ref, () => ({
      newStep() {
        console.log("new step")
        setAnswerValue("_")
        setIsFreeze(false)
        setIsAnswerCorrect()
        setIsAnswerBlink(false)
        setIsAnswerTrebble(false)
      }
    }));

    useEffect (()=>{
      setObjMinWidth(randomObjMinWidth())
      setObjJustifyContent(randomObjJustifyContent())
    },[character])

    const createObjectsArr = ()=>{
      const objs = []
      for(let i = 0; i < numObjects; i++){
        objs.push(<img src={object.img}/>)       
      }
      return objs;
      }
    
    const objectsArr = createObjectsArr()
    
    const randomAnswerCorrectSfx = () => {
      let randomNum = Math.floor(Math.random()*4 +1)
      new Audio('/assets/questgame/wee' + randomNum + '.mp3').play()
    }

    const randomAnswerMistakeSfx = () => {
      let randomNum = Math.floor(Math.random()*10 +1)
      new Audio('/assets/questgame/no' + randomNum + '.mp3').play()
    }

    const randomizeSticker = () => {
      let randomNum = Math.floor(Math.random()*13 +1)
      setSticker('/assets/questgame/sticker' + randomNum + '.png')
    }

    const checkAnswer = () => {
      if(!isFreeze){
        if (answerValue == numObjects){
          randomizeSticker()
          setIsAnswerCorrect(true)
          randomAnswerCorrectSfx()
          setIsFreeze(true)
          setTimeout(() => {
            setIsFreeze(false)
            setIsAnswerCorrect();
            setAnswerValue("_")
            stepComplete()
          }, 1000);
        }else{
          setIsAnswerCorrect(false)
          killOneStepTry()
          randomAnswerMistakeSfx()
          setIsAnswerTrebble(true)
          setIsFreeze(true)
          setTimeout(() => {
            setIsFreeze(false)
            setIsAnswerCorrect();
            setAnswerValue("_")
            setIsAnswerTrebble()
          }, 1000);
        }
      }
    }

    const blinkAnswer = (x) => {   
      if(!isFreeze){
      setAnswerValue(x);
      setIsAnswerBlink(true);
      setTimeout(() => {
        setIsAnswerBlink(false);
      }, 200); 
    }
    }

    
	return(
        <>
       
       {isAnswerCorrect ? <div className="overlay">

          <div className="heart bounce sticker"><img src={sticker} /></div>
          </div> : ""}

        {/* {isAnswerCorrect ? <div className="overlay">
          <div className="heart bounce">❤️</div>
          <div className="heart bounce">💖</div>
          <div className="heart bounce">💖</div>
          <div className="heart bounce">💜</div>
          <div className="heart bounce">💖</div>
          <div className="heart bounce">💛</div>
          <div className="heart bounce">💘</div>
          <div className="heart bounce">🧡</div>
          <div className="heart bounce">💙</div>
          <div className="heart bounce">💙</div>
          <div className="heart bounce">💚</div>
          <div className="heart bounce">💜</div>
          <div className="heart bounce">💚</div>
          <div className="heart bounce">💚</div>
          <div className="heart bounce">💓</div>
          <div className="heart bounce">💛</div>
          <div className="heart bounce">🧡</div>
          <div className="heart bounce">💛</div>
          <div className="heart bounce">🧡</div>
          <div className="heart bounce">💝</div>
          <div className="heart bounce">💜</div>
          <div className="heart bounce">💖</div>
          <div className="heart bounce">💚</div> 
          <div className="heart bounce">💖</div>
        </div> : ""} */}
        <div className="questGame__container">
          <div className="questGame__columns ">
            <div className="questGame__column--A">
              <h1>לְ<span className="characterName">{character.name}</span> יש <span className={"questGame__answer " + (isAnswerBlink ? " blink" : "")}>{answerValue}</span> <span className="objectName">{object.name}</span> </h1>
            </div>
            <div className={"questGame__column--B " + (isAnswerTrebble ? "trebble" : "")}>
              <div className="questGame__stage">
                  <div className="questGame__stage--objects">
                      {objectsArr.map((obj)=>{
                        return obj
                      })}
                  </div>
                  <div className="questGame__stage--character">
                      <img src={character.img}/>
                  </div>
              </div>
            </div>
          </div>
            <KeyboardNum1to10 
              isKeysOpen = {isKeysOpen}
              blinkAnswer = {blinkAnswer}
              checkAnswer = {checkAnswer}
            />
          
		    </div>
      </>
	)
}
)

export default StepGame;