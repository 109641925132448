// TODO : Blink ModalLeave when press on "Close" button
import React, {useState, useEffect} from 'react';
import './Modal.scss';
import ButtonsFooter from '../../components/ButtonsFooter/ButtonsFooter';
import {useTranslation} from 'react-i18next';
import FeltLogo from "../../components/FeltLogo/FeltLogo";

function Modal({
                 // PRESET's
                 PRESET_Popup,
                 PRESET_XL_Popup,
                 PRESET_ImgFirst,
                 PRESET_TopNotification,
                 PRESET_BottomNotification,
                 PRESET_Green,

                 // CONTENT
                 CONTENT_title,
                 CONTENT_subtitle,
                 CONTENT_img,
                 CONTENT_p,
                 CONTENT_belowSubmitContent,

                 // ACTIONS
                 btn1_action = () => Promise.resolve(), // "close" to close the modal
                 btn2_action, // "close" to close the modal
                 onXButtonClick,
                 btn1_text,
                 btn2_text,
                 btn1_isDisabled,
                 btn2_isDisabled,
                 btn1_classes,
                 btn2_classes,

                 // All Properties
                 isImgFirst,
                 imgBgColor,
                 imgHeight,
                 imgWidth,
                 imgPaddingTop,
                 imgPaddingBottom,
                 imgMarginTop,
                 imgMarginBottom,
                 ModalPaddingTop,
                 ModalPaddingBottom,
                 ModalMarginTop,
                 ModalMarginBottom,
                 ModalEnterDelay,
                 ModalAnimationEnter,
                 ModalAnimationLeave,
                 ModalLeaveDelay,
                 ModalAnimationSpeed,
                 textAlignment,
                 isXbutton,
                 isBackground,
                 BackgroundColor,
                 ModalBackgroundColor,
                 ModalTextColor,
                 isBackgroundDismiss,
                 ModalWidth,
                 ModalHeight,
                 ModalPositionY,
                 ModalPositionX,
                 isModalState,
                 setIsModalState,
                 classes,
                 isFeltLogo,
                 isPreventBodyScroll,
                 bottomBorderRadius
               }) {
  const {t} = useTranslation();

  const [ModalAnimationClassesState, setModalAnimationClassesState] =
    useState('displayNone opacity0');
  const [modalBackgroundAnimationClassesState, setModalBackgroundAnimationClassesState] =
    useState('displayNone opacity0');
  const [animationSpeedState, setAnimationSpeedState] = useState(ModalAnimationSpeed || '500');
  const [BackgroundColorState, setBackgroundColorState] = useState(
    BackgroundColor || 'rgba(0,0,0,0.65)'
  );
  const [ModalBackgroundColorState, setModalBackgroundColorState] = useState(
    ModalBackgroundColor || 'white'
  );
  const [textAlignmentState, setTextAlignmentState] = useState(textAlignment || 'center');
  const [ModalTextColorState, setModalTextColorState] = useState(ModalTextColor || '#333');
  const [ModalWidthState, setModalWidthState] = useState(ModalWidth || '500px');
  const [ModalHeightState, setModalHeightState] = useState(ModalHeight || 'auto');
  const [ModalPositionYState, setModalPositionYState] = useState(ModalPositionY || 'center');
  const [ModalPositionXState, setModalPositionXState] = useState(ModalPositionX || 'center');
  const [ModalEnterDelayState, setModalEnterDelayState] = useState(ModalEnterDelay || '0');
  const [ModalLeaveDelayState, setModalLeaveDelayState] = useState(ModalLeaveDelay);
  const [ModalAnimationEnterState, setModalAnimationEnterState] = useState(
    ModalAnimationEnter || 'fade-in'
  );
  const [ModalAnimationLeaveState, setModalAnimationLeaveState] = useState(
    ModalAnimationLeave || 'fade-out'
  );
  const [isXbuttonState, setIsXbuttonState] = useState(isXbutton || false);
  const [isImageFirstState, setIsImgFirstState] = useState(isImgFirst || false);
  const [ModalPaddingBottomState, setModalPaddingBottomState] = useState(ModalPaddingBottom || '0');

  const ModalFadeIn = () => {
    setModalBackgroundAnimationClassesState('fade-in ');
    setModalAnimationClassesState('fade-in ');
  };
  const ModalSlideLeftToCenter = () => {
    setModalBackgroundAnimationClassesState('fade-in ');
    setModalAnimationClassesState('slide-left-to-center');
  };
  const ModalSlideRightToCenter = () => {
    setModalBackgroundAnimationClassesState('fade-in ');
    setModalAnimationClassesState('slide-right-to-center');
  };
  const ModalSlideTopToCenter = () => {
    // alert("ModalSlideTopToCenter")
    setModalBackgroundAnimationClassesState('fade-in ');
    setModalAnimationClassesState('slide-top-to-center');
  };
  const ModalSlideBottomToCenter = () => {
    setModalBackgroundAnimationClassesState('fade-in ');
    setModalAnimationClassesState('slide-bottom-to-center');
  };

  const ModalFadeOut = () => {
    setModalBackgroundAnimationClassesState('fade-out nopress ');
    setModalAnimationClassesState('fade-out nopress ');
    setTimeout(function () {
      setModalBackgroundAnimationClassesState('');
      setModalAnimationClassesState('');
      setIsModalState(false);
    }, animationSpeedState);
  };

  const ModalSlideCenterToRight = () => {
    setModalBackgroundAnimationClassesState('fade-out nopress ');
    setModalAnimationClassesState('slide-center-to-right nopress ');
    setTimeout(function () {
      setModalBackgroundAnimationClassesState(' displayNone opacity0 ');
      setModalAnimationClassesState(' displayNone opacity0 ');
    }, animationSpeedState);
  };
  const ModalSlideCenterToTop = () => {
    setModalBackgroundAnimationClassesState('fade-out nopress ');
    setModalAnimationClassesState('slide-center-to-top nopress ');
    setTimeout(function () {
      setModalBackgroundAnimationClassesState(' displayNone opacity0 ');
      setModalAnimationClassesState(' displayNone opacity0 ');
    }, animationSpeedState);
  };
  const ModalSlideCenterToBottom = () => {
    setModalBackgroundAnimationClassesState('fade-out nopress ');
    setModalAnimationClassesState('slide-center-to-bottom nopress ');
    setTimeout(function () {
      setModalBackgroundAnimationClassesState(' displayNone opacity0 ');
      setModalAnimationClassesState(' displayNone opacity0 ');
    }, animationSpeedState);
  };
  const ModalSlideCenterToLeft = () => {
    setModalBackgroundAnimationClassesState('fade-out nopress ');
    setModalAnimationClassesState('slide-center-to-left');
    setTimeout(function () {
      console.log('displayNone opacity0!');
      setModalBackgroundAnimationClassesState(' displayNone opacity0 ');
      setModalAnimationClassesState(' displayNone opacity0 ');
    }, animationSpeedState);
  };

  const ModalEnter = () => {
    switch (ModalAnimationEnterState) {
      case 'fade-in':
        ModalFadeIn();
        break;
      case 'slide-left-to-center':
        ModalSlideLeftToCenter();
        break;
      case 'slide-right-to-center':
        ModalSlideRightToCenter();
        break;
      case 'slide-top-to-center':
        ModalSlideTopToCenter();
        break;
      case 'slide-bottom-to-center':
        ModalSlideBottomToCenter();
        break;
    }
  };
  const ModalLeave = () => {
    switch (ModalAnimationLeaveState) {
      case 'fade-out':
        ModalFadeOut();
        break;
      case 'slide-center-to-left':
        ModalSlideCenterToLeft();
        break;
      case 'slide-center-to-right':
        ModalSlideCenterToRight();
        break;
      case 'slide-center-to-top':
        ModalSlideCenterToTop();
        break;
      case 'slide-center-to-bottom':
        ModalSlideCenterToBottom();
        break;
    }
  };

  const handleImg = (isImageFirstState) => {
    if (isImageFirstState) {
      return (
        <div
          className={
            'img-container ' + (isImageFirstState ? 'img-container_topRoundedCorners' : '')
          }
        >
          {/* {CONTENT_img ? <img className="Modal__form--img noselect " src={CONTENT_img} width={imgMaxWidth ? imgMaxWidth : ""} /> : ""} */}
          {CONTENT_img ? (
            <img className="Modal__form--img noselect " src={CONTENT_img} width="auto"/>
          ) : (
            ''
          )}
        </div>
      );
    }
  };

  document.documentElement.style.setProperty('--Modal_background-color', ModalBackgroundColorState);
  document.documentElement.style.setProperty('--Background-color', BackgroundColorState);
  document.documentElement.style.setProperty('--Modal-AnimationSpeed', animationSpeedState + 'ms');
  document.documentElement.style.setProperty(
    '--Modal_text-color',
    ModalTextColorState || '#3c3c3c'
  );
  document.documentElement.style.setProperty(
    '--Modal-text-alignment',
    textAlignmentState || 'center'
  );
  document.documentElement.style.setProperty('--Modal-width', ModalWidthState || '300px');
  document.documentElement.style.setProperty('--Modal-height', ModalHeightState || 'auto');
  document.documentElement.style.setProperty('--imgContainerBg', imgBgColor || 'rgba(0,0,0,0)');
  document.documentElement.style.setProperty('--imgHeight', imgHeight || 'auto');
  document.documentElement.style.setProperty('--imgWidth', imgWidth || '100%');
  document.documentElement.style.setProperty('--Modal_margin-top', ModalMarginTop || '0');
  document.documentElement.style.setProperty('--Modal_margin-bottom', ModalMarginBottom || '0');
  document.documentElement.style.setProperty('--Modal_padding-top', ModalPaddingTop || '0');
  document.documentElement.style.setProperty('--Modal_padding-bottom', ModalPaddingBottom || '0');
  document.documentElement.style.setProperty('--Modal_padding-bottom',ModalPaddingBottomState || '0');

  document.documentElement.style.setProperty('--img_padding-top', imgPaddingTop || '0');
  document.documentElement.style.setProperty('--img_padding-bottom', imgPaddingBottom || '0');
  document.documentElement.style.setProperty('--img_margin-bottom', imgMarginBottom || '0');
  document.documentElement.style.setProperty('--img_margin-up', imgMarginTop || '0');
  
  document.documentElement.style.setProperty('--Modal_border-radius-bottom', (bottomBorderRadius ? '8px' : "0") || '8px');

  if (ModalWidthState == '100%') {
    document.documentElement.style.setProperty('--Modal_border-radius', '0');
  }

  if (ModalPositionYState == 'center') {
    document.documentElement.style.setProperty('--Modal-translateY', '-50%');
    document.documentElement.style.setProperty('--Modal_top', '45%');
    document.documentElement.style.setProperty('--Modal_bottom', 'auto');
  } else if (ModalPositionYState == 'bottom') {
    document.documentElement.style.setProperty('--Modal-translateY', '0%');
    document.documentElement.style.setProperty('--Modal_top', 'auto');
    document.documentElement.style.setProperty('--Modal_bottom', '0');
  } else if (ModalPositionYState == 'top') {
    document.documentElement.style.setProperty('--Modal-translateY', '0%');
    document.documentElement.style.setProperty('--Modal_top', '0');
    document.documentElement.style.setProperty('--Modal_bottom', 'auto');
  }

  if (ModalPositionXState == 'center') {
    document.documentElement.style.setProperty('--Modal-translateX', '-50%');
    document.documentElement.style.setProperty('--Modal_left', '50%');
    document.documentElement.style.setProperty('--Modal_right', 'auto');
  } else if (ModalPositionXState == 'left') {
    document.documentElement.style.setProperty('--Modal-translateX', '0%');
    document.documentElement.style.setProperty('--Modal_left', '5%');
    document.documentElement.style.setProperty('--Modal_right', 'auto');
  } else if (ModalPositionXState == 'right') {
    document.documentElement.style.setProperty('--Modal-translateX', '0%');
    document.documentElement.style.setProperty('--Modal_right', '5%');
    document.documentElement.style.setProperty('--Modal_left', 'auto');
  }

  useEffect(() => {
    console.log('Modal.jsx - isModalState', isModalState);

    if(isPreventBodyScroll){

    }

    if (PRESET_Popup) {
      setModalPositionYState('center'); // top / center / bottom
      setModalPositionXState('center');
      setModalWidthState('450px');
      setModalPaddingBottomState('20px');
    }
    if (PRESET_XL_Popup) {
      setModalPositionYState('center'); // top / center / bottom
      setModalPositionXState('center');
      setModalWidthState('100%');
      setModalPaddingBottomState('20px');
    }
    if (PRESET_ImgFirst) {
      setIsImgFirstState(true);
    }
    if (PRESET_TopNotification) {
      setModalWidthState('100%');
      setModalPositionYState('top');
      setModalPositionXState('center');
      setModalAnimationEnterState('slide-top-to-center');
      setModalAnimationLeaveState('slide-center-to-top');
      setModalEnterDelayState('1000');
    }
    if (PRESET_BottomNotification) {
      setModalWidthState('100%');
      setModalPositionYState('bottom');
      setModalAnimationEnterState('slide-bottom-to-center');
      setModalAnimationLeaveState('slide-center-to-bottom');
    }
    if (PRESET_Green) {
      setModalTextColorState('#d0fed5');
      setModalBackgroundColorState(`#14a544`);
      setIsXbuttonState(true);
    }
    if (ModalEnterDelayState) {
      setModalAnimationClassesState(' opacity0 ');
      setModalBackgroundAnimationClassesState(' opacity0 ');
      // setModalAnimationEnterState("fade-in")
      setTimeout(function () {
        ModalEnter();
      }, ModalEnterDelayState);
    }
    if (ModalLeaveDelayState) {
      setTimeout(function () {
        ModalLeave();
      }, ModalLeaveDelayState);
    }
  }, [ModalAnimationEnterState, ModalAnimationLeaveState, isModalState]);

  if (!isModalState) {
    //   console.log("showModalSwitch is ", showModalSwitch)
    return <div className={'Modal-container--head '}></div>;
  } else {
    return (
      <div className={'Modal-container '}>
        {isBackground ? (
          <div
            className={'Modal-background ' + modalBackgroundAnimationClassesState}
            onClick={() => (isBackgroundDismiss ? () => ModalLeave() : '')}
          ></div>
        ) : (
          ''
        )}
        <div className={'Modal texture ' + ModalAnimationClassesState + " " + classes}>
          {isXbuttonState ? (
            <div onClick={() => {
              if (onXButtonClick) onXButtonClick();
              else ModalLeave()
            }} className="noselect Modal__x">
              +
            </div>
          ) : (
            ''
          )}
          <div className={'Modal__nav noselect '}>
            {handleImg(isImageFirstState)}
            
          </div>
          <form className="Modal__form"> 
          {CONTENT_title ? <h2 className={'Modal__title antialiased '}>{CONTENT_title}</h2> : ''}
            {CONTENT_subtitle ? (
              <h3 className="Modal__subtitle antialiased ">{CONTENT_subtitle}</h3>
            ) : (
              ''
            )}  
            {isFeltLogo ? (
              <FeltLogo
              color="white"
              classes="logo-top-left_s"
              symbol={true}
            />
            ) : ""}       
            {/* needs prevent default  */}
            {/* {CONTENT_img ? <img className="Modal__form--img noselect " src={CONTENT_img} width={imgMaxWidth ? imgMaxWidth : ""} /> : ""} */}
            {handleImg(!isImageFirstState)}
            {CONTENT_p ? <div className="Modal-p">{CONTENT_p}</div> : ''}
          </form>
          {btn1_action ? (
            <ButtonsFooter
              btn1_text={btn1_text}
              btn2_text={btn2_text}
              btn1_action={async () => {
                await btn1_action();
                ModalLeave();
              }}
              btn2_action={btn2_action == 'close' ? () => ModalLeave() : btn2_action}
              btn1_isDisabled={btn1_isDisabled}
              btn2_isDisabled={btn2_isDisabled}
              btn1_classes={btn1_classes}
              btn2_classes={btn2_classes}
              belowSubmitContent = {CONTENT_belowSubmitContent ? <div className="CONTENT_belowSubmitContent">{CONTENT_belowSubmitContent}</div> : ''}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default Modal;
