import React, { useState } from 'react';

function FullScreenStepper({ steps, onFinish, customOnFinish, onExit , onContinueFunc}) {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  // Navigation functions
  const onContinue = async () => {
    onContinueFunc && onContinueFunc();
    
    if (currentStepIndex < steps.length - 1) {
      setCurrentStepIndex(currentStepIndex + 1);
    } else {
      if (customOnFinish) {
        customOnFinish();
      } else {
        onFinish();
      }
    }
  };

  const onBack = () => {
    if (currentStepIndex > 0) {
      setCurrentStepIndex(currentStepIndex - 1);
    }
  };

  const currentStep = steps[currentStepIndex];

  return (
    <>
      {currentStep && React.cloneElement(currentStep, {
        onContinue,
        onBack,
        onExit, // Pass the onExit prop to the step if provided
        onFinish: customOnFinish || onFinish,
      })}
    </>
  );
}

export default FullScreenStepper;
