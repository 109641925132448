import React ,{useEffect} from 'react';
import './KeyboardNum1to10.scss'
import useKeyPress from '../../utils/hooks/useKeyPress.js'


function KeyboardNum1to10({isKeysOpen, blinkAnswer, checkAnswer}){
    // document.documentElement.style.setProperty('--heightScreen', "calc(100vh - var(--questGame__nav--height) " + (isKeysOpen ? "- var(--questGame__buttons--height) )": ")"));
    document.documentElement.style.setProperty('--heightScreen', "calc(100vh " + (isKeysOpen ? "- var(--questGame__buttons--height) )": ")"));
    document.documentElement.style.setProperty('--questGame__buttons--height', (isKeysOpen ? "300px": "0"));
    
    const keyPress1 = useKeyPress("1");
    const keyPress2 = useKeyPress("2");
    const keyPress3 = useKeyPress("3");
    const keyPress4 = useKeyPress("4");
    const keyPress5 = useKeyPress("5");
    const keyPress6 = useKeyPress("6");
    const keyPress7 = useKeyPress("7");
    const keyPress8 = useKeyPress("8");
    const keyPress9 = useKeyPress("9");
    const keyPress10 = useKeyPress("10");
    const keyPressEnter = useKeyPress("Enter");
    useEffect(() => {
      keyPress1 && blinkAnswer(1);
      keyPress2 && blinkAnswer(2);
      keyPress3 && blinkAnswer(3);
      keyPress4 && blinkAnswer(4);
      keyPress5 && blinkAnswer(5);
      keyPress6 && blinkAnswer(6);
      keyPress7 && blinkAnswer(7);
      keyPress8 && blinkAnswer(8);
      keyPress9 && blinkAnswer(9);
      keyPress10 && blinkAnswer(10); 
      keyPressEnter && checkAnswer()
    })

    return (
        <>
            <div className={"questGame__buttons noselect " + (isKeysOpen ? "" : "displayNone")}>
                <div className="questGame__buttons--numbers">
                    <div className="questGame__buttons--row questGame__buttons--row-A">
                        <button className="button3D noselect buttonKey" onClick={()=>{blinkAnswer(1)}}>1</button>
                        <button className="button3D noselect buttonKey" onClick={()=>{blinkAnswer(2)}}>2</button>
                        <button className="button3D noselect buttonKey" onClick={()=>{blinkAnswer(3)}}>3</button>
                        <button className="button3D noselect buttonKey" onClick={()=>{blinkAnswer(4)}}>4</button>
                        <button className="button3D noselect buttonKey" onClick={()=>{blinkAnswer(5)}}>5</button>
                    </div>
                    <div className="questGame__buttons--row questGame__buttons--row-B">
                        <button className="button3D noselect buttonKey" onClick={()=>{blinkAnswer(6)}}>6</button>
                        <button className="button3D noselect buttonKey" onClick={()=>{blinkAnswer(7)}}>7</button>
                        <button className="button3D noselect buttonKey" onClick={()=>{blinkAnswer(8)}}>8</button>
                        <button className="button3D noselect buttonKey" onClick={()=>{blinkAnswer(9)}}>9</button>
                        <button className="button3D noselect buttonKey" onClick={()=>{blinkAnswer(10)}}>10</button>
                            
                    </div>
                </div>
                <div className="questGame__buttons--actions">
                    <button className="button3D noselect buttonKey buttonKey__XL" onClick={()=>checkAnswer()}>↩</button>
                </div>
            </div>
        </>
        )
    }
export default KeyboardNum1to10;