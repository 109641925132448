import Q_ListSingleSelect from "components/Q_Elements/Q_ListSingleSelect/Q_ListSingleSelect";
import {Locale} from "../../graphql/types";
import "./LanguageSelector.scss"

const LanguageSelector = ({onSelect, defLang}) => {
    return (
      <>
            <div className="lang-img">
                <img
                alt={`catbears language`}
                src={"/assets/language/language-planet.png"}
                />
            </div>
            <Q_ListSingleSelect
                isRandomizeAnswers={false}
                isOther={false}
                defaultSelection={defLang}
                alwaysLTR
                listArr={[
                    {id: Locale.He, displayString: '🇮🇱 עברית'},
                    {id: Locale.En, displayString: '🇺🇸 English'}
                ]}
                onSelect={(locale) => onSelect(locale)}
            />
      </>
    )
  }

  export default LanguageSelector;