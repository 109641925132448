import React , {useState} from 'react';
import { useTranslation } from 'react-i18next';
import setPropertyVh from '../../utils/setPropertyVh';
import Instructor from 'components/Instructor/Instructor';

const Onboarding_Lesson_intro = ({
            onBack,
            onContinue, 
            lessonImgURL,      
            name,
            description,
            instructorName,
            AnimalType
    }) => {
    const {t} = useTranslation();
    setPropertyVh();
    return (
        <>
            <div className={"Onboarding-container"}>
                <div className={"Onboarding-content Onboarding_Lesson_intro__container hideScrollbar"}>
                            <div className={"Onboarding-text-container"}>
                            <div className={"Onboarding-subtitle"}>{t("onboarding.whatNeedForLesson")}</div>
                            <div className={"Onboarding-title"}>{t("onboarding.Onboarding_intro-title")}</div>
                            </div>
                            <div className={"Onboarding--card"}>
                            <img className={"Onboarding--card__img Onboarding--card__img--border"} src={"/" + lessonImgURL} alt={name}/>
                            <Instructor 
                                    AnimalType={AnimalType}
                                    instructorName={instructorName}
                                    isInlineStart={true}
                                />
                            </div>
                            <div className={"Onboarding-subtitle"}>{name}</div>
                            <div className='Room'></div>
                    </div>
                    <div className={"Onboarding-footer"}>
                        <button onClick={onContinue} className={"button Onboarding-button Onboarding-button-secondary  catbearsCTA-primary Onboarding-button"}>
                            {t("general.continue")}
                        </button>          
                    </div>
            </div>
        </>
    )
}

export default Onboarding_Lesson_intro;