import duck from "./assets/duck.png";
import duck2x from "./assets/duck@2x.png";
import duck3x from "./assets/duck@3x.png";
import bunny from "./assets/bunny.png";
import bunny2x from "./assets/bunny@2x.png";
import bunny3x from "./assets/bunny@3x.png";
import fox from "./assets/fox.png";
import fox2x from "./assets/fox@2x.png";
import fox3x from "./assets/fox@3x.png";
import croc from "./assets/croc.png";
import croc2x from "./assets/croc@2x.png";
import croc3x from "./assets/croc@3x.png";
import rat from "./assets/rat.png";
import rat2x from "./assets/rat@2x.png";
import rat3x from "./assets/rat@3x.png";
import flam from "./assets/flam.png";
import flam2x from "./assets/flam@2x.png";
import flam3x from "./assets/flam@3x.png";
import squirrel from "./assets/squirrel.png";
import squirrel2x from "./assets/squirrel@2x.png";
import squirrel3x from "./assets/squirrel@3x.png";
import crow from "./assets/crow.png";
import crow2x from "./assets/crow@2x.png";
import crow3x from "./assets/crow@3x.png";
import butterfly from "./assets/butterfly.png";
import butterfly2x from "./assets/butterfly@2x.png";
import butterfly3x from "./assets/butterfly@3x.png";
import elephant from "./assets/elephant.png";
import elephant2x from "./assets/elephant@2x.png";
import elephant3x from "./assets/elephant@3x.png";
import cat from "./assets/cat.png";
import cat2x from "./assets/cat@2x.png";
import cat3x from "./assets/cat@3x.png";
import lion from "./assets/lion.png";
import lion2x from "./assets/lion@2x.png";
import lion3x from "./assets/lion@3x.png";
import koala from "./assets/koala.png";
import koala2x from "./assets/koala@2x.png";
import koala3x from "./assets/koala@3x.png";
import bear from "./assets/bear.png";
import bear2x from "./assets/bear@2x.png";
import bear3x from "./assets/bear@3x.png";
import dino from "./assets/dino.png";
import dino2x from "./assets/dino@2x.png";
import dino3x from "./assets/dino@3x.png";

export default {
  duck: {
    img: duck,
    img2x: duck2x,
    img3x: duck3x,
    color: "#159FF4"
  },
  bunny: {
    img: bunny,
    img2x: bunny2x,
    img3x: bunny3x,
    // color: "#f9b8d9"
    // color: "#ffafd7"
    color: "#fb85c0"
  },
  cat: {
    img: cat,
    img2x: cat2x,
    img3x: cat3x,
    // color: "#ff9843"
    color: "#fa7d15"
  },
  bear: {
    img: bear,
    img2x: bear2x,
    img3x: bear3x,
    color: "#9C714D"
  },
  croc: {
    img: croc,
    img2x: croc2x,
    img3x: croc3x,
    // color: "#66C76F"
    color: "#41b04b"
  },
  rat: {
    img: rat,
    img2x: rat2x,
    img3x: rat3x,
    // color: "#b0a0aa"
    // color: "#ac89f0" // purple mouse
    color: "#a67890" 
  },
  flam: {
    img: flam,
    img2x: flam2x,
    img3x: flam3x,
    color: "#FA7A9A"
  },
  crow: {
    img: crow,
    img2x: crow2x,
    img3x: crow3x,
    // color: "#fcb718"
    color: "#c53ddb" // purple
  },
  butterfly: {
    img: butterfly,
    img2x: butterfly2x,
    img3x: butterfly3x,
    // color: "#ff8386"
    // color: "#ff9c9f"
    color: "#ea7e81"
  },
  elephant: {
    img: elephant,
    img2x: elephant2x,
    img3x: elephant3x,
    color: "#6a7781"
  },
  lion: {
    img: lion,
    img2x: lion2x,
    img3x: lion3x,
    // color: "#F5B227"
    // color: "#ffb546"
    // color: "#fe154b" // red
    color: "#b56b16" // brown
  },
  squirrel: {
    img: squirrel,
    img2x: squirrel2x,
    img3x: squirrel3x,
    // color: "#9C714D"
    color: "#c3996b"
  },
  koala: {
    img: koala,
    img2x: koala2x,
    img3x: koala3x,
    // color: "#82867F"
    // color: "#9ac976" // greenish
    color: "#818cac"
  },
  dino: {
    img: dino,
    img2x: dino2x,
    img3x: dino3x,
    color: "#fa535c"
  },
  fox: {
    img: fox,
    img2x: fox2x,
    img3x: fox3x,
    // color: "#F1824D"
    color: "#ff8f31"
  },
};