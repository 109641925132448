import React from 'react';
import './JourneyListNewButton.scss';
import { useTranslation } from 'react-i18next';

function JourneyListNewButton({ onClick }) {
  const { t } = useTranslation();

  return (
      <div className="JourneyListNewButton__container" onClick={onClick}>
        <div className="JourneyListNewButton__plusIcon">+</div>
        <div className="JourneyListNewButton__text">{t('mirroring.createJourney')}</div>
      </div>
  );
}

export default JourneyListNewButton;
