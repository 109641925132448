import React, { useState, useEffect } from 'react';
// import './Q_ListMultiSelect.scss';
import '../Q_Elements.scss';
import { useTranslation } from 'react-i18next';

const Q_MultiSelectList = ({ listArr, onSelect, isRandomizeAnswers, isOther }) => {
  const { t } = useTranslation();
  const [selectedItems, setSelectedItems] = useState([]);
  const [displayedListArr, setDisplayedListArr] = useState([]);

  useEffect(() => {
    let randomizedListArr = listArr;
    if (isRandomizeAnswers) {
      randomizedListArr = [...listArr].sort(() => Math.random() - 0.5);
    }

    if (isOther) {
      randomizedListArr = [...randomizedListArr, t('exitPoll.reason__other')];
    }

    setDisplayedListArr(randomizedListArr);
  }, []);

  const handleItemClick = (item) => {
    const updatedSelection = selectedItems.includes(item)
      ? selectedItems.filter((selectedItem) => selectedItem !== item)
      : [...selectedItems, item];
    setSelectedItems(updatedSelection);
    onSelect(updatedSelection);
  };

  return (
    <div className="list-container">
      {displayedListArr.map((item) => (
        <div
          key={item}
          onClick={() => handleItemClick(item)}
          className={`selection-button-style selection-button-style-small antialiased noselect list-item ${
            selectedItems.includes(item) ? 'selected' : ''
          }`}
        >
          {item}
          <div className="list-item__icon" />
        </div>
      ))}
    </div>
  );
};

export default Q_MultiSelectList;