import gql from 'graphql-tag';
import React, {useContext, useEffect, useState} from 'react';
import {useMutation} from 'react-apollo';
import {useTranslation} from 'react-i18next';
import {updateUserIsSubscribed} from '../../graphql/mutations';
import Modal from '../Modal/Modal';
import relationshipWorkImage from '../../assets/relationship-work.jpg';
import preloader from '../../pages/Relationship/preloader.gif';
import './SubscriptionModal.scss';
import UserContext from "../../pages/Login/UserContext";
import ExitPoll from "../ExitPoll/ExitPoll";

const SubscriptionModal = ({isOpen, onExit}) => {
  const {t} = useTranslation(); // TODO: add proper translations

  const {user, handleUserUpdate} = useContext(UserContext);
  const [showPaywall, setShowPaywall] = useState(true);

  const [updateUser, {data, loading, error}] = useMutation(gql(updateUserIsSubscribed), {
    update(cache, {data: {setUserSubscription}}) {
      if (!setUserSubscription) {
        console.error('setUserSubscription is undefined or null');
        return;
      }
      handleUserUpdate(setUserSubscription);
    },
  });
  const [isLoadingSubscription, setIsLoadingSubscription] = useState(false);
  const [shouldDisplayErrorModal, setShouldDisplayErrorModal] = useState(false);

  const [shouldDisplayExitFlow, setShouldDisplayExitFlow] = useState(false);

  useEffect(() => {
    if (isOpen === true)
      setShowPaywall(true) 
  }, [isOpen])

  if (!isOpen) return null;

  const onSubscribeButtonClick = async () => {
    setShowPaywall(false);
    setIsLoadingSubscription(true);
    await updateUser({variables: {isSubscribed: true}})
    await delay(6500); // this is temporary, until we implement actual subscription flow

    setIsLoadingSubscription(false);
    setShouldDisplayErrorModal(true);
  };

  const delay = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const onExitSubscriptionModal = () => {
    const localStorageKey = 'exitedSubscriptionFlow'
    const hasUserExitedOnce = localStorage.getItem(localStorageKey);

    if (hasUserExitedOnce && !user.exitPoll)
      setShouldDisplayExitFlow(true);

    else {
      localStorage.setItem(localStorageKey, 'true');
      onExit();
    }

    setShowPaywall(false);

    // setShouldDisplayExitFlow(true); // JUST FOR GUI WORK
  }


  return (
    <>
      {showPaywall &&
        <Modal
          CONTENT_img={relationshipWorkImage}
          isFeltLogo={true}
          CONTENT_p={
            <>
              <div className="SubscriptionModal_main">
                <div className="SubscriptionModal_main-title">{t(`paywall.main-title`)}</div>
                <div className="SubscriptionModal_container">
                  <div className="deal-container">
                    <p className="deal-title">{t(`paywall.deal-price`)}</p>
                    <p className="deal-description">{t(`paywall.deal-mini-text`)}</p>
                  </div>
                  <ul className="SubscriptionModal_no-bullet">
                    <li><span className="SubscriptionModal_check-bullet"></span>
                      <div><p className="SubscriptionModal_li__p">{t(`paywall.bullet_01`)}</p></div>
                    </li>
                    <li><span className="SubscriptionModal_check-bullet"></span>
                      <div><p className="SubscriptionModal_li__p">{t(`paywall.bullet_02`)}</p></div>
                    </li>
                    <li><span className="SubscriptionModal_check-bullet"></span>
                      <div><p className="SubscriptionModal_li__p">{t(`paywall.bullet_03`)}</p></div>
                    </li>
                    <li><span className="SubscriptionModal_check-bullet"></span>
                      <div><p className="SubscriptionModal_li__p">{t(`paywall.bullet_04`)}</p></div>
                    </li>
                    <li><span className="SubscriptionModal_check-bullet"></span>
                      <div><p className="SubscriptionModal_li__p">{t(`paywall.bullet_05`)}</p></div>
                    </li>
                  </ul>
                </div>
              </div>
            </>
          }
          btn1_text={
            <div>
              <div className="subscribe-button_main-text">{t(`paywall.subscribe-button`)}</div>
              {/* <div className="subscribe-button_price-text">$10 / Month</div> */}
            </div>
          }
          // btn1_action={onSubscribeButtonClick}
          btn1_action={onSubscribeButtonClick}
          CONTENT_belowSubmitContent={
              <div className="subscribe-button_below-text">{t(`paywall.below-subscribe-button`)}</div>
            }
          isBackground={true}
          isXbutton={true}
          isShowModal={true}
          isModalState={showPaywall}
          onXButtonClick={onExitSubscriptionModal}
          setIsModalState={setShowPaywall}
          ModalWidth={'375px'}
          ModalMarginTop = {"20px"}
          ModalMarginBottom = {"40px"}
          ModalHeight={'655px'}
          bottomBorderRadius = {false}
        />
      }
      {isLoadingSubscription &&
        <Modal
          CONTENT_p={
            <>
              <p className="preloader--img"><img src={preloader} alt="loading"/></p>
            </>
          }
          classes={"preloader-modal"}
          isBackgroundDismiss={true}
          isBackground={true}
          isShowModal={true}
          isModalState={true}
          btn1_action={false}
          ModalHeight={'200px'}
          ModalWidth={'200px'}
          bottomBorder = {"100%"}
          bottomBorderRadius = {true}
          ModalPaddingTop = {'20px'}
      />
      }
      {shouldDisplayErrorModal &&
        <Modal
          // CONTENT_title={"Free access granted"}
          CONTENT_p={
            <>
              <p className="modal-payment-problem--title">{t('paywall.payment-problem_title')}</p>
              <h3
                className="modal-payment-problem--notification">{t('paywall.payment-problem_free-access-granted')}</h3>
              <p className="modal-payment-problem--description">{t('paywall.payment-problem_description')}</p>
            </>
          }
          PRESET_Popup={true}
          PRESET_ImgFirst={true}
          btn1_text={t('paywall.payment-problem_cta')}
          btn1_action={() => {
            onExit()
          }}
          isBackground={true}
          isXbutton={false}
          isShowModal={true}
          isModalState={shouldDisplayErrorModal}
          ModalWidth={'450px'}
          ModalHeight={'450px'}
          setIsModalState={() => {
          }}
          classes={"modal-payment-problem"}
        />
      }
      {shouldDisplayExitFlow && <ExitPoll isLogo={true} onFinish={() => {
        setShouldDisplayExitFlow(false);
        onExit();
      }}/>}
    </>
  );
};

export default SubscriptionModal;
