import React from 'react';
import "./ButtonCircle.scss";
import {Link} from 'react-router-dom'

function ButtonCircle({classes, onClick, to}) {
  if (to)
    return (
      <Link to={to} onClick={onClick}>
        <div className={"ButtonCircle noselect " + classes}></div>
      </Link>
    )
  else return (
    <div className={"ButtonCircle noselect " + classes} onClick={onClick}></div>
  )

}

export default ButtonCircle;