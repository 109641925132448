import i18n from 'i18next';
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json';
import translationHE from './locales/he/translation.json';
import { happenedCardsRaw, feelCardsRaw, needCardsRaw } from './static/cards';

const happenedCardsENTranslation = happenedCardsRaw.reduce((memo, card) => {
  return Object.assign(memo, {
    [card.title]:
    {
      title: card.title,
      title_male: card.title_male,
      title_female: card.title_female,
      title_other: card.title_other,
      description: card.description,
      prefix: card.prefix
    }
  });
}, {});
const feelCardsENTranslation = feelCardsRaw.reduce((memo, card) => {
  return Object.assign(memo, {
    [card.title]:
    {
      title: card.title,
      title_male: card.title_male,
      title_female: card.title_female,
      title_other: card.title_other,
      description: card.description,
      prefix: card.prefix
    }
  });
}, {});
const needCardsENTranslation = needCardsRaw.reduce((memo, card) => {
  return Object.assign(memo, {
    [card.title]:
    {
      title: card.title,
      title_male: card.title_male,
      title_female: card.title_female,
      title_other: card.title_other,
      description: card.description,
      prefix: card.prefix
    }
  });
}, {});
const happenedCardsHETranslation = happenedCardsRaw.reduce((memo, card) => {
  return Object.assign(memo, {
    [card.title]:
    {
      title: card[`titleHe`],
      title_male: card[`titleHe_male`],
      title_female: card[`titleHe_female`],
      title_other: card[`titleHe_other`],
      description: card[`descriptionHe`],
      prefix: card[`prefixHe`]
    }
  });
}, {});
const feelCardsHETranslation = feelCardsRaw.reduce((memo, card) => {
  return Object.assign(memo, {
    [card.title]:
    {
      title: card[`titleHe`],
      title_male: card[`titleHe_male`],
      title_female: card[`titleHe_female`],
      title_other: card[`titleHe_other`],
      description: card[`descriptionHe`],
      prefix: card[`prefixHe`]
    }
  });
}, {});
const needCardsHETranslation = needCardsRaw.reduce((memo, card) => {
  return Object.assign(memo, {
    [card.title]:
    {
      title: card[`titleHe`],
      title_male: card[`titleHe_male`],
      title_female: card[`titleHe_female`],
      title_other: card[`titleHe_other`],
      description: card[`descriptionHe`],
      prefix: card[`prefixHe`]
    }
  });
}, {});

const resources = {
  en: {
    translation: {
      ...translationEN,
      cards: {
        HAPPENED: happenedCardsENTranslation,
        FEEL: feelCardsENTranslation,
        NEED: needCardsENTranslation,
      }
    }
  },
  he: {
    translation: {
      ...translationHE,
      cards: {
        HAPPENED: happenedCardsHETranslation,
        FEEL: feelCardsHETranslation,
        NEED: needCardsHETranslation,
      }
    }
  },
}

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: `en`,
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      wait: true,
    },
  });

function changeLanguage(locale = `en`) {
  i18n.changeLanguage(locale);
  document.body.setAttribute(`lang`, locale);
}

export { i18n, changeLanguage };