import React, { useState } from 'react';
import './Q_RatingNumeric.scss';

const Q_RatingNumeric = ({ maxNum, textBelowMin, textBelowMax, onSelect, title }) => {

  const [selectedRange, setSelectedRange] = useState(null);

  const handleRangeSelection = (selected) => {
      setSelectedRange(selected);
      onSelect(selected);
  };

  return (
    <div className="rating-container">
      
      {title && <div className="rating-title noselect ">{title}</div>}

      <div className="rating-container__buttons noselect">
        {Array.from({ length: maxNum }, (_, i) => {
          const rating = i + 1;
          return (
            <div
              key={rating}
              className={`rating-button selection-button-style ` + (selectedRange === rating && "selected") }
              onClick={() => handleRangeSelection(rating)}
            >
              <span>{rating}</span>
            </div>
          );
        })}
      </div>
      <div className="rating-container__texts noselect">
        {textBelowMin && (
          <div className="rating-text ">
            <span>{textBelowMin}</span>
          </div>
        )}
        {textBelowMax && (
          <div className="rating-text">
            <span>{textBelowMax}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Q_RatingNumeric;
