import React, {useState, useEffect} from 'react';
import UserContext from './UserContext';
import NewPassword from './NewPassword';
import AuthMenu from './AuthMenu';
import * as AuthState from './authState';
import {changeLanguage} from '../../i18n';
import {useMutation} from "react-apollo";
import gql from "graphql-tag";
import {CreateGuestUser} from "../../graphql/mutations";

const withAuthentication = (Cmp) => {
  return (props) => {
    const [authProps, setAuthProps] = useState({
      user: null,
      email: '',
      isLoading: false,
      isError: false,
      errorMsg: 'Your email or password are incorrect',
      changeLanguage: null,
      logout: null,
    });
    const [authState, setAuthState] = useState(AuthState.TOKEN_VALIDATION);
    const [createGuestUserMutation] = useMutation(gql(CreateGuestUser));
    const verifyToken = () => {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');

      const verifyResetReq = async () => {
        const hrefArr = window.location.href.split('/');
        const resetToken = hrefArr[hrefArr.length - 1];
        const rawResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/reset/${resetToken}`);
        const resp = await rawResponse.json();
        console.log(resp);
        if (!resp.ok) {
          setAuthState(AuthState.RESET_PASSWORD);
        } else {
          setAuthState(AuthState.CHANGE_PASSWORD);
        }
      };

      if (window.location.href.includes('/reset')) {
        setAuthState(AuthState.TOKEN_VALIDATION);
        verifyResetReq();
        return;
      }

      if (token && userId) {
        const graphqlQuery = {
          query: `
            query ValidateToken {
              validateToken {
                id
                email
                locale
                isSubscribed
                userProgress
                userFeedback
                exitPoll {
                  subscriptionLikelihood
                  reasons
                  explanation
                }
                type
                isGuest
                is1stFeedbackComplete
                isOnboardingComplete
              }
            }
          `,
        };

        fetch(`${process.env.REACT_APP_BACKEND_URL}/graphql`, {
          method: 'POST',
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(graphqlQuery),
        })
          .then((res) => res.json())
          .then(async (resp) => {
            if (resp.data.validateToken !== null) {
              setAuthData(resp.data.validateToken);
            } else {
              await createGuestUser();
            }
          })
          .catch((error) => {
            console.log(error);
            logoutHandler();
          });
      } else {
        createGuestUser();
      }
    };

    const saveUserToLocalStorage = (token, userId, shouldExpire) => {
      localStorage.setItem('token', token);
      localStorage.setItem('userId', userId);
      if (shouldExpire) {
        const remainingMilliseconds = 60 * 60 * 1000;
        const expiryDate = new Date(new Date().getTime() + remainingMilliseconds);
        localStorage.setItem('expiryDate', expiryDate.toISOString());
      }
    };

    const authStateHandler = (state, payload) => {
      console.log(state, payload);
      if (state === AuthState.RESET_PASSWORD) {
        setAuthProps({ ...authProps, email: payload });
      }
      setAuthState(state);
    };

    const createGuestUser = async () => {
      try {
        const { data } = await createGuestUserMutation();
        const { token, user } = data.createGuestUser;
        saveUserToLocalStorage(token, user.id);
        setAuthData(data.createGuestUser);
      } catch (error) {
        console.error('Error creating guest user:', error);
      }
    };

    const logoutHandler = () => {
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      setAuthProps({
        ...authProps,
        user: null,
        isLoading: false,
        isError: false,
        errorMsg: null,
      });
      setAuthState(AuthState.REGISTER);
    };

    const loginHandler = (authData) => {
      const graphqlQuery = {
        query: `
          mutation Login($email: String!, $password: String!) {
            login(email: $email, password: $password) {
              token
              id
              email
              locale
              user {
                isSubscribed
                userProgress
                userFeedback
                exitPoll {
                  subscriptionLikelihood
                  reasons
                  explanation
                }
                type
              }
            }
          }
        `,
        variables: {
          email: authData.email,
          password: authData.password,
        },
      };

      setAuthProps({ ...authProps, isLoading: true });

      fetch(`${process.env.REACT_APP_BACKEND_URL}/graphql`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(graphqlQuery),
      })
        .then((res) => res.json())
        .then((resData) => {
          if (resData.errors && resData.errors[0].status === 422) {
            throw new Error('Login failed. ' + resData.errors[0].data[0].message || '');
          }
          if (resData.errors) {
            throw new Error(resData.errors[0].message);
          }

          localStorage.setItem('token', resData.data.login.token);
          localStorage.setItem('userId', resData.data.login.id);
          const remainingMilliseconds = 60 * 60 * 1000;
          const expiryDate = new Date(new Date().getTime() + remainingMilliseconds);
          localStorage.setItem('expiryDate', expiryDate.toISOString());
          setAuthData(resData.data.login);
        })
        .catch((err) => {
          setAuthProps({
            ...authProps,
            user: null,
            isLoading: false,
            isError: true,
            errorMsg: 'Your email or password are incorrect',
          });
        });
    };

    const signupHandler = (authData) => {
      const graphqlQuery = {
        query: `
          mutation CreateUser($email: String!, $password: String!) {
            createUser(
              userInput: {
                email: $email,
                password: $password,
              }
            ) {
              id
              email
              locale
              token
              user {
                isSubscribed
                userProgress
                userFeedback
                exitPoll {
                  subscriptionLikelihood
                  reasons
                  explanation
                }
                type
              }
            }
          }
        `,
        variables: {
          email: authData.email,
          password: authData.password,
        },
      };

      fetch(`${process.env.REACT_APP_BACKEND_URL}/graphql`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(graphqlQuery),
      })
        .then((res) => res.json())
        .then((resData) => {
          if (resData.errors && resData.errors[0].status === 422) {
            throw new Error('Validation failed. ' + resData.errors[0].data[0].message || '');
          }
          if (resData.errors) {
            throw new Error(resData.errors[0].message);
          }
          localStorage.setItem('token', resData.data.createUser.token);
          localStorage.setItem('userId', resData.data.createUser.id);
          const remainingMilliseconds = 60 * 60 * 1000;
          const expiryDate = new Date(new Date().getTime() + remainingMilliseconds);
          localStorage.setItem('expiryDate', expiryDate.toISOString());
          setAuthData(resData.data.createUser);
        })
        .catch((err) => {
          console.log(err.message);
          setAuthProps({
            user: null,
            isLoading: false,
            isError: true,
            errorMsg: err.message,
          });
        });
    };

    const handleChangeLanguage = (locale) => {
      console.log('Changing language to:', locale);
      const token = localStorage.getItem('token');
      const graphqlQuery = {
        query: `
          mutation ChangeLocale($locale: String!) {
            changeLocale(locale: $locale) {
              id
              email
              locale
            }
          }
        `,
        variables: { locale },
      };

      fetch(`${process.env.REACT_APP_BACKEND_URL}/graphql`, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(graphqlQuery),
      })
        .then((res) => res.json())
        .then((resp) => {
          console.log('Language changed response:', resp);
        })
        .then(()=> {
          setAuthProps((user) => {
            console.log("user!!! ", user)
            return {...user, user:{...user.user, locale}}
          })
          
        })
    };

    const changePasswordHandler = (password) => {
      const hrefArr = window.location.href.split('/');
      const resetToken = hrefArr[hrefArr.length - 1];
      setAuthProps({ ...authProps, isLoading: true });

      const graphqlQuery = {
        query: `
          mutation ChangePassword($password: String!, $resetToken: String!) {
            changePassword(password: $password, resettoken: $resetToken) {
              token
              id
              email
              locale
            }
          }
        `,
        variables: {
          password,
          resetToken,
        },
      };

      fetch(`${process.env.REACT_APP_BACKEND_URL}/graphql`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(graphqlQuery),
      })
        .then((res) => res.json())
        .then((resData) => {
          if (resData.errors && resData.errors[0].status === 422) {
            throw new Error('Change password failed. ' + resData.errors[0].data[0].message || '');
          }
          if (resData.errors) {
            throw new Error(resData.errors[0].message);
          }

          const { token, id } = resData.data.changePassword;
          saveUserToLocalStorage(token, id, true);

          setAuthState(AuthState.DONE_CHANGE_PASSWORD);
          setAuthData(resData.data.changePassword);
          window.location.href = '/';
        });
    };

    useEffect(() => {
      verifyToken();
    }, []);

    const setAuthData = (authData, isLoading = false) => {
      const flattenedUser = {
        ...authData,
        ...authData.user, // Merge nested properties at the top level if necessary
      };
      delete flattenedUser.user; // Remove nested user to prevent redundancy
    
      setAuthProps({
        ...authProps,
        isLoading,
        user: flattenedUser,
      });
    };

    const handleUserUpdate = (updatedUser) => {
      setAuthProps({ ...authProps, user: { ...authProps.user, ...updatedUser } });
    };

    if (authProps.user == null)
      return (
        <AuthMenu>
          {(authState === AuthState.CHANGE_PASSWORD || authState === AuthState.DONE_CHANGE_PASSWORD) && (
            <NewPassword
              authProps={authProps}
              setAuthState={authStateHandler}
              authState={authState}
              changePasswordHandler={changePasswordHandler}
            />
          )}
        </AuthMenu>
      );

    return (
      <UserContext.Provider
        value={{ user: authProps.user, handleUserUpdate, saveUserToLocalStorage, setAuthData }}
      >
        <Cmp
          authContext={{ ...authProps, changeLanguage: handleChangeLanguage, logout: logoutHandler }}
          {...props}
        />
      </UserContext.Provider>
    );
  };
};

export default withAuthentication;
