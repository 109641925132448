import React, { useState, useEffect } from 'react';
import "./ProgressDot.scss"

function ProgressDot({isCompleted, isSelected, dotSize , ProgressDotColor}){

    const[ProgressDotColorState , setProgressDotColorState] = useState(ProgressDotColor);
    const[isSelectedState , setIsSelectedState] = useState(isSelected);

    function setDotColor() {
        // if(ProgressDotColorState == "mySideColor"){
        //     setProgressDotColorState("ProgressDot__mySideColor");
        //     console.log("myside dot color : " , ProgressDotColorState)
        // }
        // else if (ProgressDotColorState == "partnerColor"){
        //     setProgressDotColorState("ProgressDot__partnerColor");
        //     console.log("partner dot color : " , ProgressDotColorState)
        // } else {
        //     document.documentElement.style.setProperty("--Dot-color", ProgressDotColorState)
        //     console.log("general dot color : " , ProgressDotColorState)
        // }

        // GENERAL SUCCESS COLOR
        setProgressDotColorState("ProgressDot_completed")
    }


    function setDotSelected() {
        if(isSelected){
            setIsSelectedState("ProgressDot_selected")
        }else {
            setIsSelectedState("")
        }
    }

      
    
    document.documentElement.style.setProperty("--Dot-width", dotSize || "16px")
    document.documentElement.style.setProperty("--Dot-height", dotSize || "16px")
    
    useEffect(() => {
        setDotColor()
        setDotSelected()
      }, [isSelected, isCompleted]);

    return (
        <div className={"ProgressDot  " + (isCompleted ? ProgressDotColorState : "" ) + " " + isSelectedState}></div>
        // <div className={"Dot " + (isCompletedState ? ProgressDotColorState : "" ) + " " + isSelectedState}></div>
    )
}

export default ProgressDot; 