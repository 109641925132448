import React from 'react';
import { useTranslation } from 'react-i18next';

function UserTypeCard({ emoji, title, classes }) {
  const { t } = useTranslation();
  return (
    <>
      <div className={"card  " + classes}>
        <div className="card__emoji">{emoji}</div>
        <div className="card__not--stroke"></div>
        <div className="card__not"></div>
        <div className="card__a-little--stroke"></div>
        <div className="card__a-little"></div>
        <div className="card__title">{title}</div>
      </div>
    </>
  );
}

export default UserTypeCard;
