function updateArray(array: any[], updatedItem: any, index: number) {
  const updatedArray = array.map((item, currentIndex) => {
    if (currentIndex === index) {
      return updatedItem;
    }
    return item;
  });
  return updatedArray;
}

export default updateArray;
