import React, { useState } from 'react';
import './FullscreenTakeover.scss';

const FullscreenTakeover = ({children}) => {
  return (
    <>
        <div className={"fullscreenTakeover-container"}>
            {children}
        </div>
    </>
  );
};

export default FullscreenTakeover;
