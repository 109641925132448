import React, {useContext, useState} from 'react';
import * as AuthState from './authState';
import {useTranslation} from 'react-i18next';
import {noop} from "lodash";
import UserContext from "./UserContext";
import {useMutation} from "react-apollo";
import {createUser, signUpGuestUser} from "../../graphql/mutations";
import gql from "graphql-tag";
import "./Catbears_Register.scss"

const Catbears_Register = ({
    onSignUp,
    shouldAllowSwitchToLogin = true,
    onSwitchToLogin = (noop),
    isSkippable,
    onSkip,
    dontShowTitle,
    isBright,
    hasAccout,
    toggleDisplayLogin
}) => {

  const userContext = useContext(UserContext) || {};
  const [isLogin, setIsLogin] = useState(false);

  console.log('user context', userContext);
  const {user, saveUserToLocalStorage, setAuthData} = userContext;

  const [signUpGuestUserMutation, {loading: guestUserLoading}] = useMutation(gql(signUpGuestUser))
  const [signUpNewUserMutation, {loading: newUserLoading}] = useMutation(gql(createUser))

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState('');

  const {t} = useTranslation();

  const _onChangeEmail = (event) => {
    const {value} = event.target;
    setEmail(value)
  }

  const _onChangePassword = (event) => {
    const {value} = event.target;
    setPassword(value);
  }

  const _onSignUp = async (event) => {
    event.preventDefault();

    const mutation = user.isGuest ? signUpGuestUserMutation : signUpNewUserMutation
    const resultParameterName = user.isGuest ? 'signUpGuestUser' : 'createUser'

    try {
      const result = await mutation({variables: {userInput: {email, password}}})
      const resultData = result.data[resultParameterName]
      const {token, user} = resultData
      saveUserToLocalStorage(token, user.id);
      setAuthData(resultData);

      onSignUp({email, password})

    } catch (error) {
      console.log('error signup', error);
      setError(error.message)
    }
  }

  const isLoading = guestUserLoading || newUserLoading;

  return (
    <>
      <div className={"Onboarding-title"}>{t("loginFlow.createAnAccount")}</div>
      <div className={"Onboarding-subtitle"}>{t("loginFlow.progress-saved")}</div>
      <div className={"Catbears_Register" }>

        <form onSubmit={_onSignUp} className="">
          <div>
            <label className={"noselect register-label"}>{t("loginFlow.email")}</label>
            <input type="email" className="input input-authMenu inputLogin" value={email}
                   onChange={_onChangeEmail}></input>
          </div>
          <div>
            <label className={"noselect register-label"}>{t("loginFlow.password")}</label>
            <input type="Password" className="input input-authMenu" value={password}
                   onChange={_onChangePassword}></input>
          </div>
          <div className="">
            <button
              className={"noselect buttonCatbears buttonCatbears_CTA register-btn"}
              type="submit">{t("loginFlow.createAnAccount")}
            </button>
            <div className={" " + (error ? "error" : " displayNone ")}>{error}</div>
          </div>
          {/* <div className="">{t("loginFlow.byCreating") + " "}
            <a className={"link_no-button " + (isBright ? "link_BRIGHT" : "")}>{t("loginFlow.privacyPolicy")}
            </a>{" " + t("loginFlow.and")}<a className={"noselect link_no-button " + (isBright ? "link_BRIGHT" : "")}>{t("loginFlow.TermsOfService")}</a>
            {" " + t("loginFlow.endOfSentence")}
          </div> */}
        </form>
        <div className="below-register-form noselect ">
            {shouldAllowSwitchToLogin && 
                <p className="noselect register_already-has-account">
                    {t("loginFlow.alreadyAccount") + " "}<a className={"noselect "} onClick={toggleDisplayLogin}>{t("loginFlow.logInBtn")}</a>
                </p>
            }
            {(isSkippable && onSkip) && <div className={"noselect register-skip-btn "} onClick={onSkip}> {t('general.skip')} </div>}
        </div>
      </div>    
    </>
  )
}

export default Catbears_Register;