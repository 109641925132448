import React, { useContext, useEffect } from 'react';
import UserContext from "pages/Login/UserContext";

const LangToBody = () => {
  const authContext = useContext(UserContext);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (authContext.user?.locale) {
        document.body.setAttribute("lang", authContext.user.locale);
      }
    }, 10);

    return () => clearTimeout(timer); // Clean up the timeout if the component unmounts
  }, [authContext.user?.locale]);

  return null;
};

export default LangToBody;
