import React, {useState,useEffect} from 'react';
import Animal from '../../components/Animal/Animal';
import "./AnimalsThumbnail.scss";
import * as ANIMALS from "../../components/Animal/AnimalAssets";
import { useTranslation } from "react-i18next";


function AnimalsThumbnail({ children, name, partnerName, animalId, partnerAnimalId, thumbnailClassName, showSides, relation='' }) {
  const { t } = useTranslation();
  const animal = ANIMALS.default[animalId];
  const partnerAnimal = ANIMALS.default[partnerAnimalId];
  const[isHeLangState, setIsHeLangState]  = useState(false)
  
  useEffect (()=> {
    if(document.body.getAttribute(`lang`)== "he"){
      setIsHeLangState(true)
      // console.log("HE")
    }else{
      setIsHeLangState(false)
    } 
  })
  
  document.documentElement.style.setProperty('--mySideColor', animal.color);
  document.documentElement.style.setProperty('--partnerColor', partnerAnimal.color);


  const thumbnailStyle = {
    background: `linear-gradient(132deg, ${animal.color } 58%, ${partnerAnimal.color} 50%)`
  }
  const thumbnailStyleRTL = {
    background: `linear-gradient(132deg, ${partnerAnimal.color} 58%, ${animal.color} 50%)`
  }
  return (
    <>
    <div className={"felt-thumbnail noselect " + (thumbnailClassName ? thumbnailClassName : "")} style={isHeLangState ? thumbnailStyleRTL : thumbnailStyle}>
      {children}
      <div className="side mySide">
        <Animal id={animalId} alt={`my animal`} />
        <div className="felt-thumbnail__name felt-thumbnail__name--mySide">{name}</div>
        {showSides ? <div className="felt-thumbnail__name felt-thumbnail__name--mySide felt-thumbnail__name--relation">{t(`create_new_relationship.step1.title`)}</div> : ""}
      </div>
      <div className="side partnerSide">
        <Animal id={partnerAnimalId} alt={`partner animal`} />
        <div className="felt-thumbnail__name felt-thumbnail__name--partnerSide">{partnerName}</div>
        {showSides ? <div className="felt-thumbnail__name felt-thumbnail__name--mySide felt-thumbnail__name--relation">{t(`create_new_relationship.step2.title`)}</div> : ""}
      </div>
    </div>
    </>
  )
}

export default AnimalsThumbnail;