import React, {useState} from 'react';
import './Q_InputFeedback.scss';

const Q_InputFeedback = ({label, labelBelow, onInputChange, initialValue = '', isTextArea = true, onEnterKey}) => {
  const [inputValue, setInputValue] = useState(initialValue);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    onInputChange(value); // Call the callback prop with the input value
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && onEnterKey) {
      onEnterKey();
    }
  }

  const textComponentProps = {
    autoFocus: true,
    type: "text",
    className: "input-feedback__field ",
    value: inputValue,
    onChange: handleInputChange,
    onKeyDown: handleKeyDown
  }

  return (
    <div className="input-feedback ">
      {label ? <p className="input-feedback__label form-label noselect">{label}</p> : ""}
      {isTextArea ?
        <textarea {...textComponentProps} /> :
        <input {...textComponentProps} />}
      {labelBelow ? <p className="input-feedback__label-below form-label noselect">{labelBelow}</p> : ""}
    </div>
  );
};

export default Q_InputFeedback;
